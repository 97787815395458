// @flow
import React, { useState } from 'react';
import { isEqual, startOfDay, format } from 'date-fns';

// COMPONENTS
import { Block, Flex } from 'components/Containers';
import Text from 'components/Text';
import Menu from 'components/Menu';
import { MilestoneStatus } from 'components/Status';

// DIALOGS
import MilestoneViewDialog from 'dialogs/ProjectDialogs/MilestoneViewDialog';
import MilestoneEditDialog from 'dialogs/ProjectDialogs/MilestoneEditDialog';
import MilestoneConfirmationDialog from 'dialogs/ProjectDialogs/MilestoneConfirmationDialog';
import MilestoneCompleteDialog from 'dialogs/ProjectDialogs/MilestoneCompleteDialog';

// STORE
import {
  MilestoneUpdateTypes as UpdateTypes,
  type Milestone,
  type Project,
  type Action,
  type MilestoneUpdatePayload,
} from 'store/project/types';
import type { User } from 'store/user/types';

// UTILS
import { success as successAlert, error as errorAlert } from 'utils/alerts';

// HOOKS
import { useAlert } from 'hooks/useAlert';

// LOCAL
import type { DialogTypes } from '../../types';
import { VIEW, EDIT, ARCHIVE, COMPLETE } from '../../constants';

type Props = {
  project: Project,
  milestone: Milestone,
  action: Action,
  user: User,
  updateMilestone: MilestoneUpdatePayload => void,
};

const success = successAlert('milestone');
const error = errorAlert('milestone');

const formatDate = (date: string) => format(date, 'MM/DD/YY');
const actual = new Date();

const initialState: DialogTypes = {
  view: false,
  edit: false,
  archive: false,
  complete: false,
};

export default function ProjectMilestone(props: Props) {
  const { project, milestone, action, user, updateMilestone } = props;

  const [dialog, setDialog] = useState(initialState);
  const { createAlert } = useAlert();

  // Archived and Loading
  const archived = milestone.archived || project.archive;
  const completed = !!milestone.actual;
  const loading = action.isLoading;

  // Finds the correct Dialog key, sets all other key values to be false and sets one value
  function onDialog(name: string, show: boolean) {
    setDialog({ ...initialState, [name]: show });
  }

  // Opens Dialog
  function openDialog(mType: DialogTypes) {
    onDialog(mType, true);
  }

  // Closes Dialog
  function closeDialog(mType: DialogTypes) {
    onDialog(mType, false);
  }

  // Function Create an alert for each action
  function alert(mType: DialogTypes, type: 'success' | 'error') {
    if (type === 'success') createAlert({ message: success[mType], type });
    if (type === 'error') createAlert({ message: error[mType], type });
  }

  // Function to fire off Complete Milestone
  async function completeMilestone() {
    const payload = {
      projectId: project.id,
      milestoneId: milestone.id,
      userId: user.id,
      type: UpdateTypes.COMPLETE,
      milestone: {
        ...milestone,
        actual,
      },
    };

    try {
      await updateMilestone(payload);
      alert(COMPLETE, 'success');
    } catch (err) {
      alert(COMPLETE, 'error');
    }
  }

  function onCompleteMilestoneClick() {
    const forecast = startOfDay(milestone.forecast);
    const today = startOfDay(actual);

    return isEqual(forecast, today)
      ? completeMilestone()
      : openDialog(COMPLETE);
  }

  // List of Items in Kebab Menu
  const items =
    archived || completed
      ? [{ name: 'View', color: 'black', action: () => openDialog(VIEW) }]
      : [
          { name: 'View', color: 'black', action: () => openDialog(VIEW) },
          {
            name: 'Complete',
            color: 'black',
            action: onCompleteMilestoneClick,
          },
          { name: 'Edit', color: 'black', action: () => openDialog(EDIT) },
          {
            name: 'Archive',
            color: 'error',
            action: () => openDialog(ARCHIVE),
          },
        ];

  return (
    <>
      {loading && (
        <Flex
          fullWidth
          fullHeight
          align="center"
          justify="center"
          style={{ position: 'absolute', zIndex: 2 }}
        />
      )}
      <Flex
        align="center"
        key={milestone.id}
        style={{ opacity: loading ? 0.5 : 1 }}
      >
        <Flex item xs={3} padding="0 2 0 0">
          <Flex gutter={1} fullWidth align="center">
            <Block flex="none">
              <MilestoneStatus
                readOnly
                status={milestone.status}
                isComplete={!!milestone.actual}
                isArchived={milestone.archived}
              />
            </Block>
            <Block>
              <Text size="normal">{milestone.description}</Text>
            </Block>
          </Flex>
        </Flex>
        <Flex item xs={1}>
          <Text size="normal">{formatDate(milestone.displayDate)}</Text>
        </Flex>
        <Flex item xs={7}>
          <Text size="normal">{milestone.notes}</Text>
        </Flex>
        <Flex item xs={1} justify="flex-end">
          <Menu
            items={items}
            iconButtonProps={{ noPadding: true, alt: 'menu' }}
          />
        </Flex>
      </Flex>

      <MilestoneViewDialog
        open={dialog[VIEW]}
        onClose={() => closeDialog(VIEW)}
        milestone={milestone}
        editable={!project.archive}
      />

      <MilestoneEditDialog
        open={dialog[EDIT]}
        project={project}
        milestone={milestone}
        updateType={UpdateTypes.UPDATE}
        onClose={() => closeDialog(EDIT)}
        onSuccess={() => alert(EDIT, 'success')}
        onError={() => alert(EDIT, 'error')}
      />

      <MilestoneConfirmationDialog
        open={dialog[ARCHIVE]}
        milestone={milestone}
        updateType={UpdateTypes.ARCHIVE}
        onClose={() => closeDialog(ARCHIVE)}
        onSuccess={() => alert(ARCHIVE, 'success')}
        onError={() => alert(ARCHIVE, 'error')}
      />

      <MilestoneCompleteDialog
        open={dialog[COMPLETE]}
        milestone={milestone}
        updateType={UpdateTypes.COMPLETE}
        onClose={() => closeDialog(COMPLETE)}
        onSuccess={() => alert(COMPLETE, 'success')}
        onError={() => alert(COMPLETE, 'error')}
      />
    </>
  );
}
