// @flow
import React, { useState } from 'react';
import type { Milestone } from 'store/project/types';
import { MilestoneUpdateTypes } from 'store/project/types';
import Dialog from 'components/Dialog';
import Menu from 'components/Menu';
import Text from 'components/Text';
import { MilestoneStatus } from 'components/Status';
import { Block, Flex } from 'components/Containers';
import { IconButton } from 'components/Icon';
import { spacingDefaults } from 'styles/constants';
import { formatDate } from 'helpers/formatters';
import MilestoneConfirmationDialog from '../MilestoneConfirmationDialog';
import MilestoneEditDialog from '../MilestoneEditDialog';
import MilestoneCompleteDialog from '../MilestoneCompleteDialog';
import { useAlert } from 'hooks/useAlert';
import { success as successAlert, error as errorAlert } from 'utils/alerts';
import MilestoneHistory from './components/MilestoneHistory';

type Props = {
  editable: boolean,
  open: boolean,
  milestone: Milestone,
  onClose: () => void,
};

export default function MilestoneViewDialog(props: Props) {
  const { editable, open, milestone, onClose } = props;
  const { createAlert } = useAlert();

  const [
    showMilestoneConfirmationDialog,
    setShowMilestoneConfirmationDialog,
  ] = useState(false);
  const [
    showMilestoneCompleteDialog,
    setShowMilestoneCompleteDialog,
  ] = useState(false);
  const [showMilestoneEditDialog, setShowMilestoneEditDialog] = useState(false);
  const [milestoneUpdateType, setMilestoneUpdateType] = useState(false);

  const archive = () => {
    setMilestoneUpdateType(MilestoneUpdateTypes.ARCHIVE);
    setShowMilestoneConfirmationDialog(true);
  };

  const complete = () => {
    setMilestoneUpdateType(MilestoneUpdateTypes.COMPLETE);
    setShowMilestoneCompleteDialog(true);
  };

  const update = () => {
    setMilestoneUpdateType(MilestoneUpdateTypes.UPDATE);
    setShowMilestoneEditDialog(true);
  };

  const isMilestoneViewVisible = () =>
    open &&
    !showMilestoneEditDialog &&
    !showMilestoneCompleteDialog &&
    !showMilestoneConfirmationDialog;

  const editMenuOption = {
    name: 'Edit',
    color: 'primary',
    action: update,
  };
  const completeMenuOption = {
    name: 'Complete',
    color: 'black',
    action: complete,
  };
  const deleteMenuOption = { name: 'Archive', color: 'error', action: archive };
  const menuItems = milestone.actual
    ? [editMenuOption, deleteMenuOption]
    : [completeMenuOption, editMenuOption, deleteMenuOption];

  if (!milestone) return '';
  return (
    <>
      <Dialog
        open={isMilestoneViewVisible()}
        showFooter={false}
        title={
          <Flex align="center">
            <MilestoneStatus
              readOnly
              status={milestone.status}
              isArchived={milestone.archived}
              isComplete={!!milestone.actual}
            />
            <Text
              tag="h3"
              weight={600}
              style={{ paddingLeft: spacingDefaults.xsmall }}
            >
              {milestone.description}
            </Text>
          </Flex>
        }
        onClose={onClose}
        rightHeader={
          <Flex align="center" gutter={1}>
            <Block style={{ whiteSpace: 'nowrap' }}>
              <Text tag="h3" weight={600} width="100%">
                {formatDate(milestone.displayDate, 'MMM D')}
              </Text>
            </Block>
            <Block>
              {editable && (
                <Menu data-testid="milestone-menu" items={menuItems} />
              )}
            </Block>
            <Block flex="none">
              <IconButton name="exit" onClick={onClose} />
            </Block>
          </Flex>
        }
      >
        <Text>{milestone.notes}</Text>
        <MilestoneHistory milestone={milestone} />
      </Dialog>
      {showMilestoneConfirmationDialog && (
        <MilestoneConfirmationDialog
          open={showMilestoneConfirmationDialog}
          milestone={milestone}
          updateType={milestoneUpdateType}
          onClose={onClose}
          onSuccess={() =>
            createAlert({
              message: successAlert('milestone').archive,
              type: 'success',
            })
          }
          onError={() =>
            createAlert({
              message: errorAlert('milestone').archive,
              type: 'error',
            })
          }
        />
      )}
      {showMilestoneEditDialog && (
        <MilestoneEditDialog
          open={showMilestoneEditDialog}
          milestone={milestone}
          updateType={milestoneUpdateType}
          onClose={onClose}
          onSuccess={() =>
            createAlert({
              message: successAlert('milestone').edit,
              type: 'success',
            })
          }
          onError={() =>
            createAlert({
              message: errorAlert('milestone').edit,
              type: 'error',
            })
          }
        />
      )}
      {showMilestoneCompleteDialog && (
        <MilestoneCompleteDialog
          open={showMilestoneCompleteDialog}
          milestone={milestone}
          updateType={milestoneUpdateType}
          onClose={onClose}
          onSuccess={() =>
            createAlert({
              message: successAlert('milestone').complete,
              type: 'success',
            })
          }
          onError={() =>
            createAlert({
              message: errorAlert('milestone').complete,
              type: 'error',
            })
          }
        />
      )}
    </>
  );
}
