import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from 'helpers/formatters/';
import './style.scss';

const Meta = ({ start, end, lead }) => {
  return (
    <div className="Meta">
      <div className="Meta-item">
        <div className="Meta-title">Start</div>
        <h2 className="Meta-text">{formatDate(start, 'MMM DD, YYYY')}</h2>
      </div>
      <div className="Meta-item">
        <div className="Meta-title">Due</div>
        <h2 className="Meta-text">{formatDate(end, 'MMM DD, YYYY')}</h2>
      </div>
      {lead && (
        <div className="Meta-item">
          <div className="Meta-title">Project Lead</div>
          <div className="Meta-lead">
            {lead.avatarUrl && <img src={lead.avatarUrl} alt="" />}
            <h2 className="Meta-text">{lead.name}</h2>
          </div>
        </div>
      )}
    </div>
  );
};

Meta.propTypes = {
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  lead: PropTypes.shape({}),
};
export default Meta;
