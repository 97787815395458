// @flow
import React from 'react';
import type { ReportStatus } from 'store/project/types';
import { StatusCircle } from '../styled/StatusCircle';
import { StatusGroup } from '../styled/StatusGroup';
import StatusLetter from './StatusLetter';
import { getStatusColor } from '../utils';

type Props = {
  showLetter: boolean,
  size: number,
  status: ReportStatus,
  vertical: boolean,
  onClick?: () => void,
};

function Status(props: Props) {
  const { onClick, showLetter, size, status, vertical } = props;
  const { project, client, growth } = status;

  const projectStatus = project && project.status;
  const clientStatus = client && client.status;
  const growthStatus = growth && growth.status;

  return (
    <StatusGroup
      data-testid="status-circle-group"
      vertical={vertical}
      onClick={onClick}
    >
      <StatusCircle
        alt={`project: ${getStatusColor(projectStatus)}`}
        size={size}
        status={projectStatus}
      >
        <StatusLetter letter="P" show={showLetter} size={size} />
      </StatusCircle>
      <StatusCircle
        alt={`client: ${getStatusColor(clientStatus)}`}
        size={size}
        status={clientStatus}
      >
        <StatusLetter letter="C" show={showLetter} size={size} />
      </StatusCircle>
      <StatusCircle
        alt={`growth: ${getStatusColor(growthStatus)}`}
        size={size}
        status={growthStatus}
      >
        <StatusLetter letter="G" show={showLetter} size={size} />
      </StatusCircle>
    </StatusGroup>
  );
}

Status.defaultProps = {
  status: {},
  vertical: false,
  showLetter: false,
};

export default Status;
