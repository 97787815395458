export const color = {
  BLACK: '#000000',
  OFF_BLACK: '#202020',
  WHITE: '#FFFFFF',
  FAUX_WHITE: '#F4F4F4',
  LIGHT_LIGHT_GRAY: '#979797',
  LIGHT_GRAY: '#EAEBEB',
  MEDIUM_GRAY: '#9b9b9b',
  TYPOGRAPHY_MEDIUM_GRAY: '#737373',
  PALE_GRAY: '#f6fafe',
  GRAY: '#3D4D52',
  DARK_GRAY: '#21282B',
  RED: '#EF5746',
  DARK_RED: '#BF4638',
  BLUE: '#2374E0',
  ENGAGE_BLUE: '#4E98EA',
  LIGHT_BLUE: '#CBE0F8',
  DARK_BLUE: '#205bb3',
  LIGHT_GREEN: '#3D4C52',
  GREEN: '#86CB6A',
  DARK_GREEN: '#719561',
  ORANGE: '#EF5F4F',
  YELLOW: '#EBB743',
  PURPLE: '#BD7EC0',
  DISABLED: '#d4d4d4',
  BORDER_COLOR: '#d4d4d4',
};

export const spacingDefaults = {
  auto: 'auto',
  xsmall: '0.5rem',
  small: '0.75rem',
  normal: '1rem',
  medium: '1.25rem',
  large: '1.75rem',
  xlarge: '2.25rem',
  xxlarge: '4rem',
};

export const spacing = (top, right = top, btm = top, left = right) => {
  return `
    ${spacingDefaults[top] || 0}
    ${spacingDefaults[right] || 0}
    ${spacingDefaults[btm] || 0}
    ${spacingDefaults[left] || 0}
  `;
};

export const fontFamily = {
  primary: 'SF-Pro-Display, sans-serif',
  secondary: 'Tungsten, sans-serif',
};

export const fontSize = {
  xsmall: '.75rem',
  small: '.875rem',
  normal: '1rem',
  medium: '1.15rem',
  large: '1.5rem',
  header: '2rem',
  xlarge: '2.5rem',
  xxlarge: '3rem',
};

export const iconSizes = {
  small: '1.5rem',
  normal: '2rem',
  large: '2.5rem',
  xlarge: '3rem',
};

export const appearances = {
  PRIMARY: color.BLUE,
  INFORMATION: color.MEDIUM_GRAY,
  SUCCESS: color.GREEN,
  WARNING: color.YELLOW,
  ERROR: color.RED,
};

// Note: get all color and appearances into one object, then use that to generate
//       flow type. Used for defineing props
const combinedColors = { ...appearances, ...color };
export const propsFormattedColorAndAppearances = Object.keys(
  combinedColors
).reduce((all, colorKey) => {
  const lowecaseKey = colorKey.toLowerCase().replace('_', '-');
  all[lowecaseKey] = combinedColors[colorKey];
  return all;
}, {});

export type AppearanceTypes = $Keys<typeof propsFormattedColorAndAppearances>;

// Note: helper function to convert dahs-case to SNAKE_CASE
const stringToConstantKey = (s: string) => s.toUpperCase().replace('-', '_');

export const appearanceToColor = (appearance: string) => {
  const colorKey = stringToConstantKey(appearance);
  return appearances[colorKey] || color[colorKey] || appearance;
};
