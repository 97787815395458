export const FETCH_PROJECTS_START = 'FETCH_PROJECTS_START';
export const FETCH_PROJECTS_ERROR = 'FETCH_PROJECTS_ERROR';
export const FETCH_PROJECTS_FULFILLED = 'FETCH_PROJECTS_FULFILLED';
export const SET_CONFIG = 'SET_CONFIG';

export const PROJECTS = {
  data: [],
  loading: false,
  error: null,
};

export const CONFIG = {
  filter: 'active',
};
