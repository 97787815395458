import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Users from './presenter';
import { getCurrentUser } from 'store/selectors';

function mapStateToProps(state) {
  return {
    currentUser: getCurrentUser(state),
  };
}

function mapActionsToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(Users);
