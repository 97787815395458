// @flow
import React, { useEffect } from 'react';
import { startOfDay } from 'date-fns';
import { withRouter, type ContextRouter } from 'react-router-dom';
import { useProjectTeam } from 'hooks/useProjectTeam';
import Text from 'components/Text';
import Card from 'components/Card';
import List from 'components/List';
import Button from 'components/Button';
import Loader from 'components/Loader';
import { Block, Flex } from '/components/Containers';
import type { Project } from 'store/project/types';
import type { ProjectTeam as ProjectTeamProps } from 'providers/ProjectTeamProvider/types';
type Props = {
  ...ContextRouter,
  project: Project,
  editable: boolean,
  history: any,
};

const day = startOfDay(new Date());

const formatAllocation = (data: AssignedMember) => {
  const mode = data.allocationMode;
  if (mode === 'percent') return `${data.percent}%`;
  if (mode === 'fixed') return `${data.fixed} hrs`;
  if (mode === 'hours_per_day') return `${data.hoursPerDay} hrs`;
  return 'Vacation';
};

function ProjectTeam(props: Props) {
  const { project, editable, history } = props;
  const { assigned, fetchAssigned }: ProjectTeamProps = useProjectTeam();

  useEffect(() => {
    fetchAssigned(project.tenKId, day);
  }, []);

  function cardContent() {
    if (assigned.loading) {
      return (
        <Card.Content>
          <Flex fullHeight align="center" justify="center">
            <Loader size={50} />
          </Flex>
        </Card.Content>
      );
    }
    if (assigned.error) {
      return (
        <Card.Content>
          <Flex fullHeight align="center" justify="center">
            <Text align="center" color="typography-medium-gray">
              There was an error processing the team data.
            </Text>
          </Flex>
        </Card.Content>
      );
    }
    if (!assigned.data.length) {
      return (
        <Card.Content>
          <Flex fullHeight align="center" justify="center">
            <Text align="center" color="typography-medium-gray">
              There are no resources allocated to this project at this time.
            </Text>
          </Flex>
        </Card.Content>
      );
    }

    return (
      <>
        <Card.Content>
          <List
            items={assigned.data.map((t, idx) => (
              <Flex key={idx} align="center" justify="space-between">
                <Block>
                  <Text>{t.name}</Text>
                </Block>
                <Block flex="none">
                  <Text align="right">{formatAllocation(t)}</Text>
                </Block>
              </Flex>
            ))}
          />
        </Card.Content>
        <Card.Content>
          <Block padding="0 1">
            <Button fullWidth onClick={() => history.push('team')}>
              View Team
            </Button>
          </Block>
        </Card.Content>
      </>
    );
  }

  return editable ? (
    <Card>
      <Card.Header
        title={
          <Flex height="40px" align="center">
            <Text tag="h3" weight={600}>
              Team
            </Text>
          </Flex>
        }
      />
      {cardContent()}
    </Card>
  ) : null;
}
export default withRouter(ProjectTeam);
