// @flow
import React from 'react';
import { Formik } from 'formik';
import Card from 'components/Card';
import { Flex } from 'components/Containers';
import { IconButton } from 'components/Icon';
import Button, { ButtonGroup } from 'components/Button';
import { default as MaterialDialog } from '@material-ui/core/Dialog';

type Props = {
  open: boolean,
  fullWidth?: boolean,
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false,
  title?: React$Node | string,
  subHeader?: React$Node | string,
  rightHeader?: React$Node | string,
  children?: React$Node | string,
  confirmText?: string,
  showFooter: boolean,
  onClose: () => void,
  validate?: values => Object,
  onSubmit: values => void,
  initialValues?: Object,
  isInitialValid?: boolean,
  isLoading?: boolean,
};

function Dialog(props: Props) {
  const {
    open,
    fullWidth,
    maxWidth,
    title,
    subHeader,
    rightHeader,
    children,
    confirmText,
    validate,
    showFooter,
    onClose,
    onSubmit,
    initialValues,
    isInitialValid,
    isLoading,
  } = props;

  return (
    <MaterialDialog
      open={open}
      onClose={onClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      PaperProps={{ style: { overflow: 'visible' } }}
    >
      <Card.Header
        title={title}
        subHeader={subHeader}
        rightHeader={
          rightHeader ? (
            rightHeader
          ) : (
            <Flex flex="none" align="center">
              <IconButton alt="exit dialog" name="exit" onClick={onClose} />
            </Flex>
          )
        }
      />
      <Formik
        initialValues={initialValues}
        isInitialValid={isInitialValid}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({ values, handleChange, submitForm, isValid, errors }) => (
          <>
            <Card.Content>
              {!!initialValues && typeof children === 'function'
                ? children(values, handleChange, errors)
                : children}
            </Card.Content>
            {showFooter && (
              <Card.Content>
                <Flex justify="center">
                  <ButtonGroup>
                    <Button appearance="secondary" onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      appearance="primary"
                      onClick={submitForm}
                      disabled={!isValid || isLoading}
                    >
                      {confirmText}
                    </Button>
                  </ButtonGroup>
                </Flex>
              </Card.Content>
            )}
          </>
        )}
      </Formik>
    </MaterialDialog>
  );
}

Dialog.defaultProps = {
  open: false,
  confirmText: 'Confirm',
  maxWidth: 'md',
  fullWidth: true,
  isInitialValid: true,
  showFooter: true,
  onClose: () => {},
  onSubmit: () => {},
};

export default Dialog;
