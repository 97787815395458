// @flow
import React from 'react';
import Icon from 'components/Icon';
import StyledAvatar from '../styled/StyledAvatar';
import { color as colors } from 'styles/constants';

type Props = {
  placeholder: boolean,
  color: string,
  src?: string,
  name?: string,
};

const ALT_PLACEHOLDER = 'avatar placeholder';

const renderInitials = (name: string) =>
  name
    .split(' ')
    .map(n => n.charAt(0))
    .join('');

function Avatar(props: Props) {
  const { color, name, placeholder, src } = props;
  return (
    <StyledAvatar color={color} src={src} alt={name || ALT_PLACEHOLDER}>
      <>
        {!src && name && renderInitials(name)}
        {placeholder && (
          <Icon
            alt={ALT_PLACEHOLDER}
            name="avatar"
            size={44}
            viewBox="0 0 32 32"
          />
        )}
      </>
    </StyledAvatar>
  );
}

Avatar.defaultProps = {
  color: colors.LIGHT_GRAY,
  placeholder: false,
};

export default Avatar;
