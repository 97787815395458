import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getProjectReportStatus,
  getProjectEditable,
  getProjectReportWeekNo,
} from 'store/selectors';

import ProjectStatus from './presenter';

function mapStateToProps(state) {
  return {
    reportStatus: getProjectReportStatus(state),
    editable: getProjectEditable(state),
    weekNo: getProjectReportWeekNo(state),
  };
}

function mapActionsToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(ProjectStatus);
