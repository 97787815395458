// @flow
import React from 'react';

import type {
  Project,
  Milestone,
  MilestoneUpdatePayload,
  MilestoneUpdateType,
  Action,
} from 'store/project/types';
import type { User } from 'store/user/types';
import { Block } from 'components/Containers';
import Dialog from 'components/Dialog';
import Input from 'components/Input';

type Props = {
  open: boolean,
  project: Project,
  milestone: Milestone,
  updateType: MilestoneUpdateType,
  currentUser: User,
  updateMilestone: MilestoneUpdatePayload => void,
  onClose: () => void,
  onError: () => void,
  onSuccess: () => void,
  action: Action,
};

export default function MilestoneConfirmationDialog(props: Props) {
  const {
    currentUser,
    milestone,
    open,
    onClose,
    onError,
    onSuccess,
    project,
    updateMilestone,
    updateType,
    action,
  } = props;

  const getPayload = (why: string) => ({
    projectId: project.id,
    milestoneId: milestone.id,
    userId: currentUser.id,
    type: updateType,
    milestone,
    why,
  });

  const handleValidation = validationValues => {
    const errors = {};

    if (!validationValues.summary.trim()) {
      errors.summary = true;
    }

    return errors;
  };

  const handleSubmit = async (values: Object) => {
    try {
      await updateMilestone(getPayload(values.why));
      if (onSuccess) onSuccess();
      onClose();
    } catch (err) {
      if (onError) onError();
      onClose();
    }
  };

  const initialValues = { why: '' };

  if (!milestone) return '';
  return (
    <Dialog
      isLoading={action.isLoading}
      open={open}
      title="Why are you making this change?"
      onClose={onClose}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      isInitialValid={false}
      validate={handleValidation}
    >
      {(values, handleChange) => (
        <>
          <Block>
            <Input
              label="Why"
              visuallyHidden
              name="why"
              multiline
              placeholder="Enter text here"
              fullWidth
              value={values.why}
              onChange={handleChange}
              maxLength={255}
            />
          </Block>
        </>
      )}
    </Dialog>
  );
}
