// @flow
import React, { useEffect } from 'react';
import { Redirect, Route, Switch, type ContextRouter } from 'react-router-dom';

import ProjectHeader from './ProjectHeader/index';
import { useProjects, type ProjectsProvider } from 'hooks/useProjects';
import { useUsers } from 'hooks/useUsers';
import type { Project as ProjectProps } from 'store/project/types';
import type { User } from 'store/user/types';
import LoaderView from 'views/LoaderView';
import ProjectDetailsView from 'views/ProjectDetailsView';
import Finance from 'views/ProjectFinanceView';
import ProjectMilestoneListView from 'views/ProjectMilestoneListView';
import ProjectTeamView from 'views/ProjectTeamView';
import ProjectTimelineView from 'views/ProjectTimelineView';
import { isAdmin } from 'utils/auth';

type Props = {
  ...ContextRouter,
  currentUser: User,
  project: ProjectProps,
  fetchProject: (id: string) => void,
  isLoading: boolean,
  projectReset: () => void,
};

function Project(props: Props) {
  const {
    fetchProject,
    isLoading,
    project = {},
    currentUser,
    match,
    projectReset,
  } = props;

  const {
    fetchProjects,
    getProjects,
    setConfig,
  }: ProjectsProvider = useProjects();
  const projects = getProjects();

  const { users: usersState, fetchUsers } = useUsers();
  const { resolved: usersHasResolved, loading: usersIsLoading } = usersState;
  const projectId = match.params.projectId;
  const admin = isAdmin(currentUser);

  function inUsers() {
    if (usersHasResolved) return;
    fetchUsers({ page: 1, pageSize: 300 });
  }

  function inProjects() {
    setConfig({ filter: match.params.statusFilter });
    if (projects.data.length) return;
    fetchProjects();
  }

  useEffect(() => {
    inUsers();
    inProjects();
    return () => projectReset();
  }, []);

  useEffect(() => {
    fetchProject(projectId);
  }, [projectId]);

  if (projectId !== project.id || isLoading || usersIsLoading)
    return <LoaderView />;

  return (
    <>
      <ProjectHeader admin={admin} project={project} projects={projects} />
      <Switch>
        <Route
          path="/admin/projects/:statusFilter/:projectId/timeline"
          component={ProjectTimelineView}
        />
        <Route
          path="/admin/projects/:statusFilter/:projectId/team"
          component={ProjectTeamView}
        />
        <Route
          path="/admin/projects/:statusFilter/:projectId/details"
          component={ProjectDetailsView}
        />
        <Route
          path="/admin/projects/:statusFilter/:projectId/milestones"
          component={ProjectMilestoneListView}
        />
        <Route
          path="/admin/projects/:statusFilter/:projectId/finance"
          component={Finance}
        />
        <Redirect from="*" to="/404" />
      </Switch>
    </>
  );
}

export default Project;
