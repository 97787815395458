// @flow
import type { Dispatch } from 'redux';
import {
  getGlobalSettings,
  updateGlobalSettings as putGlobalSettings,
} from 'services/globalSettings';
import { GlobalSettings } from './types';

export const GLOBAL_SETTINGS_FETCH_START = 'global_settings/FETCH_START';
export const GLOBAL_SETTINGS_FETCH_END = 'global_settings/FETCH_END';

export const GLOBAL_SETTINGS_UPDATE_START = 'global_settings/UPDATE_START';
export const GLOBAL_SETTINGS_UPDATE_END = 'global_settings/UPDATE_END';

export const fetchGlobalSettings = () => async (dispatch: Dispatch) => {
  dispatch({ type: GLOBAL_SETTINGS_FETCH_START });
  const data = await getGlobalSettings();
  dispatch({ type: GLOBAL_SETTINGS_FETCH_END, payload: data });
};

export const updateGlobalSettings = (payload: GlobalSettings) => async (
  dispatch: Dispatch
) => {
  dispatch({ type: GLOBAL_SETTINGS_UPDATE_START });
  const data = await putGlobalSettings(payload);
  dispatch({ type: GLOBAL_SETTINGS_UPDATE_END, payload: data });
};

export const handlers = {
  [GLOBAL_SETTINGS_FETCH_START](state) {
    return {
      ...state,
      loading: true,
    };
  },

  [GLOBAL_SETTINGS_FETCH_END](state, action) {
    return {
      ...state,
      loading: false,
      data: action.payload,
    };
  },

  [GLOBAL_SETTINGS_UPDATE_START](state) {
    return {
      ...state,
      loading: true,
    };
  },

  [GLOBAL_SETTINGS_UPDATE_END](state, action) {
    return {
      ...state,
      loading: false,
      data: action.payload,
    };
  },
};
