// @flow
import { createSelector } from 'reselect';

import type { State } from './reducer';
import { STATE_KEY as JIRA_KEY } from 'store/jira';

const getJiraState = state => {
  return state[JIRA_KEY];
};

export const isJiraAuthenticated = createSelector(
  getJiraState,
  (state: State) => state.isAuthenticated
);
