// @flow
import React from 'react';
import { type ContextRouter, withRouter } from 'react-router-dom';

import { ProjectItemsContainer } from '../styled';

type Props = {
  ...ContextRouter,
  children: ChildrenArray<Element<any>>,
};

function ProjectItems(props: Props) {
  const { children, location } = props;
  return (
    <ProjectItemsContainer>
      {React.Children.map(children, child => {
        if (!React.isValidElement(child)) return null;

        return React.cloneElement(child, {
          selected: location.pathname.includes(child.props.to),
        });
      })}
    </ProjectItemsContainer>
  );
}

export default withRouter(ProjectItems);
