import PropTypes from 'prop-types';
import React from 'react';
import { formatDate } from 'helpers/formatters/';
import dayjs from 'dayjs';
import './style.scss';

const currentDay = () => `${formatDate(dayjs(), 'dddd, MMMM Do')}`;

const Top = ({ logo, text }) => (
  <div className="Top">
    <img className="Top-logo" src={logo} alt={text} />
    <div className="Top-chrono">
      <h1 className="Top-day">{currentDay()}</h1>
    </div>
  </div>
);

Top.propTypes = {
  logo: PropTypes.string.isRequired,
  text: PropTypes.string,
};

export default Top;
