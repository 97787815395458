/* eslint-disable react/sort-comp */
import React, { useState, useRef, useEffect } from 'react';
import { MentionsInput, Mention, MentionProps } from 'react-mentions';
import Halo from 'components/Halo';
import { InputWrapper, InputLabel, HaloWrapper } from '../styled';

type Props = {
  className?: string,
  data?: MentionProps.data,
  fullWidth?: boolean,
  id?: string,
  initialValue?: string,
  label?: string,
  maxLength?: number,
  name: string,
  placeholder?: string,
  visuallyHidden?: boolean,
  onChange?: (
    event: SyntheticInputEvent<HTMLInputElement>,
    mentions: string[]
  ) => void,
};

const mentionsInputStyle = {
  height: '130px',
  suggestions: {
    position: 'absolute',
    top: 'unset',
    left: 'unset',
    marginTop: 0,
    width: '100%',
    overflow: 'auto',
    maxHeight: '300px',
  },
  highlighter: {
    padding: 'calc(1px + 1rem)',
  },
};

const mentionStyle = {
  background: 'rgb(229, 229, 229)',
  padding: '1px 0',
};

export const MentionsTextArea = (props: Props) => {
  const [mentionsInputValue, setMentionsInputValue] = useState('');
  const [charCount, setCharCount] = useState(0);

  const suggestionsRef = useRef();

  useEffect(() => setMentionsInputValue(props.initialValue || ''), [
    props.initialValue,
  ]);

  const getUniqueMentions = mentions => {
    const allMentions = mentions.map(mention => mention.display);
    return allMentions.filter(
      (mention, index) => allMentions.indexOf(mention) === index
    );
  };

  const handleChange = (e, newValue, newPlainTextValue, mentions) => {
    const { onChange, name, maxLength } = props;
    const event = { target: { name, value: newValue } };
    const uniqueMentions = getUniqueMentions(mentions);

    if (onChange) onChange(event, uniqueMentions);
    if (maxLength) setCharCount(newPlainTextValue.length);
    setMentionsInputValue(newValue);
  };

  const renderSuggestion = (
    entry,
    search,
    highlightedDisplay,
    index,
    focused
  ) => (
    <div
      style={{
        padding: '0.5rem 0.75rem',
        background: focused ? 'rgb(229, 229, 229)' : '',
      }}
    >
      {highlightedDisplay}
    </div>
  );

  const {
    className,
    fullWidth,
    id,
    label,
    maxLength,
    name,
    placeholder,
    visuallyHidden,
  } = props;

  return (
    <InputWrapper fullWidth={fullWidth}>
      <InputLabel htmlFor={id || name} visible={!visuallyHidden}>
        {label}
      </InputLabel>
      <MentionsInput
        allowSpaceInQuery
        aria-label={label}
        className={className}
        placeholder={placeholder}
        onChange={handleChange}
        style={mentionsInputStyle}
        suggestionsPortalHost={suggestionsRef.current}
        value={mentionsInputValue}
        EXPERIMENTAL_cutCopyPaste // Seems to be no issues...
      >
        <Mention
          trigger="@"
          data={props.data}
          appendSpaceOnAdd
          renderSuggestion={renderSuggestion}
          style={mentionStyle}
        />
      </MentionsInput>
      <div id="suggestionsPortal" ref={suggestionsRef} />
      {maxLength && (
        <HaloWrapper>
          <Halo
            size={25}
            data={[
              {
                value: charCount,
                color: charCount < maxLength ? 'blue' : 'red',
              },
            ]}
            total={maxLength}
          />
        </HaloWrapper>
      )}
    </InputWrapper>
  );
};

export default MentionsTextArea;
