//  @flow
import styled from 'styled-components';
import { default as MaterialListItem } from '@material-ui/core/ListItem';
import { color } from 'styles/constants';

export default styled(MaterialListItem)`
  && {
    border-bottom: 1px solid ${color.BORDER_COLOR};
    padding: 24px 0;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
`;
