// @flow
import styled, { css } from 'styled-components';

export default styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  min-width: 184px;

  ${props =>
    props.fullWidth &&
    css`
      width: 100%;
    `};
`;
