// @flow
import styled from 'styled-components';

export default styled.div`
  position: relative;

  & button {
    padding: 0;
  }
`;
