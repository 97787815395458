import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getProjectReport,
  getProjectReportDate,
  getProjectReportUpdates,
  getProjectReportCurrentWeek,
  getProjectEditable,
} from 'store/selectors';

import { deleteUpdateItem } from 'store/project/actions';

import ProjectUpdates from './presenter';

function mapStateToProps(state) {
  return {
    isCurrentWeek: getProjectReportCurrentWeek(state),
    editable: getProjectEditable(state),
    reportDate: getProjectReportDate(state),
    selectedReport: getProjectReport(state),
    updates: getProjectReportUpdates(state),
  };
}

function mapActionsToProps(dispatch) {
  return bindActionCreators(
    {
      deleteUpdateItem,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapActionsToProps)(ProjectUpdates);
