// @flow
import React from 'react';
import DocumentTitle from 'react-document-title';

import type { Project } from 'store/project/types';
import { Screen, Block } from '/components/Containers';

import ProjectTeamAssigned from './components/ProjectTeamAssigned/index';
import ProjectTeamUnassigned from './components/ProjectTeamUnassigned/index';

type Props = {
  project: Project,
};

export default function ProjectTeamView(props: Props) {
  const { project } = props;

  return (
    <DocumentTitle title="Project Team">
      <Screen>
        <Block margin="5 0">
          <ProjectTeamUnassigned project={project} />
        </Block>
        <Block margin="4 0">
          <ProjectTeamAssigned project={project} />
        </Block>
      </Screen>
    </DocumentTitle>
  );
}
