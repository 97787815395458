// @flow
import React from 'react';
import type { Dialog } from '../types';

type Props = {
  children?: React$Node,
};

type State = {
  dialog: Dialog,
};

type ContextProps = {
  ...State,
  setDialog: (dialog: Dialog) => void,
};

export const DialogContext = React.createContext<ContextProps>({
  dialog: {},
  setDialog: () => {},
});

const initialState = {
  dialog: {},
};

const OPEN_DIALOG = 'OPEN_DIALOG';
const CLOSE_DIALOG = 'CLOSE_DIALOG';

const reducer = (state, action) => {
  switch (action.type) {
    case OPEN_DIALOG:
      return { ...state, dialog: action.payload };
    case CLOSE_DIALOG:
      return { ...state, dialog: { ...state.dialog, ...action.payload } };
    default:
      return initialState;
  }
};

export default function DialogProvider(props: Props) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const actions = {
    openDialog: (dialog: Dialog) => {
      dispatch({ type: OPEN_DIALOG, payload: { ...dialog, open: true } });
    },
    closeDialog: () => {
      dispatch({ type: CLOSE_DIALOG, payload: { open: false } });
    },
  };

  return (
    <DialogContext.Provider value={{ ...state, ...actions }}>
      {props.children}
    </DialogContext.Provider>
  );
}

export const DialogConsumer = DialogContext.Consumer;
