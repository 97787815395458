import React from 'react';
import Card from 'components/Card';
import { Flex, Block } from 'components/Containers';
import Text from 'components/Text';
import { formatDate, getUTCDate } from 'helpers/formatters/';

import { type Project } from 'store/project/types';

function checkCustomField(fieldValue, fieldName) {
  return fieldValue
    ? [fieldValue, false]
    : [`Not specified.  Please set ${fieldName} in 10,000ft`, true];
}

function renderBlock(header, content, isAlert = false) {
  return (
    <>
      <Block padding="3">
        <Text size="small" weight="bold">
          {header}
        </Text>
        <Block margin="1 0">
          <Text size="medium" style={{ color: isAlert ? 'red' : 'black' }}>
            {' '}
            {content || 'n/a'}{' '}
          </Text>
        </Block>
      </Block>
    </>
  );
}
export default function ProjectDetailsCard(props: Project) {
  const {
    name,
    clientName,
    projectType,
    billingMethod,
    category,
    start,
    end,
  } = props;

  return (
    <Block margin="5 0">
      <Card>
        <Card.Content flex>
          <Flex direction="column">
            {renderBlock('Project Name', name)}
            {renderBlock('Client Name', clientName)}
            {renderBlock('Project Type', projectType)}
            {renderBlock(
              'Billing Method',
              ...checkCustomField(billingMethod, 'billing method')
            )}
            {renderBlock('Category', ...checkCustomField(category, 'category'))}
            <Flex direction="row" width="50%">
              {renderBlock(
                'Start Date',
                formatDate(getUTCDate(start), 'MM/DD/YY')
              )}
              {renderBlock('End Date', formatDate(getUTCDate(end), 'MM/DD/YY'))}
            </Flex>
          </Flex>
        </Card.Content>
      </Card>
    </Block>
  );
}
