// @flow
import React, { useState } from 'react';

import { color } from 'styles/constants';

import Text from 'components/Text';
import Dialog from 'components/Dialog';
import Input from 'components/Input';
import Select from 'components/Select';
import { Block, Flex } from 'components/Containers';
import Icon from 'components/Icon';
import DateSelect from 'components/DateSelect';
import type { Milestone, Project } from 'store/project/types';
import { MilestoneUpdateTypes } from 'store/project/types';
import MilestoneConfirmationDialog from '../MilestoneConfirmationDialog';

type Props = {
  open: boolean,
  milestone: Milestone,
  project: Project,
  onSuccess: () => void,
  onError: () => void,
  onClose: () => void,
};

const statusOptions = [
  {
    label: 'Good',
    value: 'good',
    symbol: { color: color.GREEN, name: 'empty_circle' },
  },
  {
    label: 'Client Change',
    value: 'client',
    symbol: { color: color.YELLOW, name: 'error_round' },
  },
  {
    label: 'Dialexa Change',
    value: 'dialexa',
    symbol: { color: color.RED, name: 'error_round' },
  },
];

export function MilestoneEditDialog(props: Props) {
  const { milestone, open, onClose, onSuccess, onError, project } = props;

  const { displayDate, description, notes, status } = milestone;

  const initialValues = {
    forecast: displayDate,
    description,
    notes,
    status,
  };

  const [
    showMilestoneConfirmationDialog,
    setShowMilestoneConfirmationDialog,
  ] = useState(false);

  const [newMilestone, setNewMilestone] = useState(milestone);

  function handleValidation(validationValues) {
    const errors = {};

    if (!validationValues.description.trim()) {
      errors.description = true;
    }

    return errors;
  }

  function handleSubmit(submitValues) {
    const milestoneUpdate = {
      ...milestone,
      forecast: submitValues.forecast,
      description: submitValues.description,
      notes: submitValues.notes,
      status: submitValues.status,
    };
    setNewMilestone(milestoneUpdate);
    setShowMilestoneConfirmationDialog(true);
  }

  return (
    <>
      <Dialog
        open={open && !showMilestoneConfirmationDialog}
        title="Edit Milestone"
        initialValues={initialValues}
        isInitialValid={false}
        validate={handleValidation}
        onClose={onClose}
        onSubmit={handleSubmit}
        confirmText="Save"
      >
        {(values, handleChange) => (
          <>
            <Block>
              <Input
                name="description"
                label="Milestone Name*"
                placeholder="Enter text here"
                fullWidth
                value={values.description}
                onChange={handleChange}
              />
            </Block>
            <Block margin="0 0 3">
              <DateSelect
                id="forecast-select-menu"
                name="forecast"
                label="Proposed Completion Date*"
                value={values.forecast}
                onChange={handleChange}
                dayPickerProps={{
                  disabledDays: {
                    before: new Date(project.start),
                    after: new Date(project.end),
                  },
                }}
              />
            </Block>
            <Block margin="0 0 3">
              <Select
                name="status"
                label="Status*"
                value={values.status}
                onChange={handleChange}
              >
                {statusOptions.map((option, idx) => (
                  <Select.Item key={idx} value={option.value}>
                    <Flex>
                      <Block flex="none" margin="0 1 0 0">
                        <Icon size={24} {...option.symbol} />
                      </Block>
                      {option.label}
                    </Flex>
                  </Select.Item>
                ))}
              </Select>
            </Block>
            <Block>
              <Input
                name="notes"
                label="Summary"
                multiline
                placeholder="Enter text here"
                fullWidth
                value={values.notes}
                onChange={handleChange}
                maxLength={255}
              />
            </Block>
            <Block>
              <Text size="small" color="typography-medium-gray" weight={600}>
                Required fields*
              </Text>
            </Block>
          </>
        )}
      </Dialog>
      {showMilestoneConfirmationDialog && (
        <MilestoneConfirmationDialog
          open={showMilestoneConfirmationDialog}
          milestone={newMilestone}
          updateType={MilestoneUpdateTypes.UPDATE}
          onSuccess={onSuccess}
          onError={onError}
          onClose={onClose}
        />
      )}
    </>
  );
}
