import { connect } from 'react-redux';

import ProjectFinanceView from './presenter';
import { getProjectData } from 'store/selectors';

function mapStateToProps(state) {
  return {
    project: getProjectData(state),
  };
}

export default connect(mapStateToProps, null)(ProjectFinanceView);
