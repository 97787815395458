// @flow
import React from 'react';
import type { ProjectReport, Update, Action } from 'store/project/types';
import Text from 'components/Text';
import Dialog from 'components/Dialog';
import Chip from 'components/Chip';
import Input from 'components/Input';
import Select from 'components/Select';
import { Block, Flex } from 'components/Containers';
import { IconButton } from 'components/Icon';
import * as util from './utils';
import type { User } from 'store/user/types';
import { success, error } from 'utils/alerts';
import { useAlert } from 'hooks/useAlert';

type Props = {
  currentUser: User,
  idx: number,
  open: boolean,
  selectedReport: ProjectReport,
  update: Update,
  updateUpdateItem: (item: Update, report: ProjectReport) => void,
  onConfirm: () => void,
  onClose: () => void,
  action: Action,
};

const typeOptions = ['Accomplishment', 'Issue', 'Risk'];
const initialValues = (update: Update) => ({
  type: update.type,
  summary: update.summary,
});

export function UpdateEditDialog(props: Props) {
  const {
    currentUser,
    idx,
    onClose,
    onConfirm,
    open,
    selectedReport,
    update,
    updateUpdateItem,
    action,
  } = props;

  const { createAlert } = useAlert();

  function handleValidation(validationValues) {
    const errors = {};

    if (!typeOptions.includes(validationValues.type)) {
      errors.type = true;
    }

    if (!validationValues.summary.trim()) {
      errors.summary = true;
    }

    return errors;
  }

  async function handleSubmit(submitValues) {
    const newUpdate = {
      ...update,
      type: submitValues.type,
      summary: submitValues.summary,
      updatedAt: new Date(),
      userId: currentUser.id,
    };

    try {
      await updateUpdateItem(newUpdate, selectedReport, idx);
      createAlert({
        message: success('update').edit,
        type: 'success',
      });
      onConfirm();
    } catch {
      createAlert({
        message: error('update').edit,
        type: 'error',
      });
      onConfirm();
    }
  }

  return (
    <Dialog
      isLoading={action.isLoading}
      open={open}
      title="Edit Update"
      initialValues={initialValues(update)}
      isInitialValid={false}
      validate={handleValidation}
      onClose={onClose}
      onSubmit={handleSubmit}
      confirmText="Save"
      rightHeader={
        <Flex align="center" gutter={1}>
          <Chip
            appearance={util.getUpdateColor(update.description)}
            label={util.getUpdateType(update.description)}
          />
          <Block flex="none">
            <IconButton name="exit" onClick={onClose} />
          </Block>
        </Flex>
      }
    >
      {(values, handleChange) => (
        <>
          <Block margin="0 0 1">
            <Select
              name="type"
              label="Type*"
              value={values.type}
              onChange={handleChange}
            >
              <Select.Item value="Not Set">Not Set</Select.Item>
              {typeOptions.map((option, i) => (
                <Select.Item key={i} value={option}>
                  {option}
                </Select.Item>
              ))}
            </Select>
          </Block>
          <Block>
            <Input
              name="summary"
              label="Summary*"
              multiline
              placeholder="Begin typing here"
              fullWidth
              value={values.summary}
              onChange={handleChange}
              maxLength={255}
            />
          </Block>
          <Block>
            <Text size="small" color="typography-medium-gray" weight={600}>
              Required input fields*
            </Text>
          </Block>
        </>
      )}
    </Dialog>
  );
}
