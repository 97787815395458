// @flow
import type { Dispatch } from 'redux';
import { getSources } from 'services/source';

export const SOURCES_FETCH_START = 'sources/FETCH_START';
export const SOURCES_FETCH_END = 'sources/FETCH_END';

export const fetchSources = () => async (dispatch: Dispatch) => {
  dispatch({ type: SOURCES_FETCH_START });
  const data = await getSources();
  dispatch({ type: SOURCES_FETCH_END, payload: data });
};

export const handlers = {
  [SOURCES_FETCH_START](state) {
    return {
      ...state,
      loading: true,
    };
  },

  [SOURCES_FETCH_END](state, action) {
    return {
      ...state,
      loading: false,
      data: action.payload,
    };
  },
};
