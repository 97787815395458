// @flow
import React from 'react';
import { type ContextRouter, withRouter } from 'react-router-dom';

import { Flex } from 'components/Containers';

type Props = {
  ...ContextRouter,
  children: ChildrenArray<Element<any>>,
};

function DrawerItems(props: Props) {
  const { children, location } = props;
  return (
    <Flex direction="column" gutter={2} margin="0 1">
      {React.Children.map(children, child => {
        if (!React.isValidElement(child)) return null;

        return React.cloneElement(child, {
          selected: location.pathname.includes(child.props.to),
        });
      })}
    </Flex>
  );
}

export default withRouter(DrawerItems);
