// @flow
import styled, { css } from 'styled-components';
import { color } from 'styles/constants';
import { lighten } from 'polished';

const borderColor = props => {
  if (props.selected) {
    return css`
      border-color: ${color.BLUE};
    `;
  }

  return css`
    &:hover,
    &:focus {
      border-color: ${lighten(0.1, color.TYPOGRAPHY_MEDIUM_GRAY)};
    }
  `;
};

const fontColor = props => {
  if (props.selected) {
    return css`
      color: ${color.BLUE};
    `;
  }

  return css`
    color: ${lighten(0.1, color.TYPOGRAPHY_MEDIUM_GRAY)};
  `;
};

export const ProjectItemContainer = styled.div`
  position: relative;
  height: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: none;
  border-bottom: 4px solid transparent;
  background: transparent;
  margin: 0;
  padding: 0;
  outline: none;
  white-space: nowrap
  transition: all 200ms ease-in-out;

  &:not(:disabled) {
    cursor: pointer;
  }

  & p {
    ${fontColor};
  }

  ${borderColor};
`;

export const ProjectItemsContainer = styled.div`
  display: flex;
  position: relative;
  margin: 0 -5px;

  ${ProjectItemContainer} {
    margin: 0 5px;
  }
`;
