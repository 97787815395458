import headers from './headers';
import { getData, checkStatus } from './normalizer';
import { URL } from './base';

export function getFinancialsByProjectId(tenKId: string) {
  return fetch(`${URL}/projects/${tenKId}/financials`, {
    method: 'GET',
    headers: headers(),
  })
    .then(checkStatus)
    .then(getData);
}
