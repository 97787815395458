// @flow
import type { UsersState, Actions } from '../types';
import * as U from './constants';

export const initialState = {
  users: U.USERS,
};

export default (state: UsersState, action: Actions) => {
  switch (action.type) {
    case U.FETCH_USERS_START:
      return {
        ...state,
        users: {
          data: {
            map: {},
            roles: [],
          },
          error: null,
          loading: true,
          resolved: false,
        },
      };
    case U.FETCH_USERS_ERROR:
      return {
        ...state,
        users: {
          data: {
            map: {},
            roles: [],
          },
          error: action.payload,
          loading: false,
          resolved: true,
        },
      };
    case U.UPDATE_USERS_FULFILLED:
      const { roles } = state.users.data;
      return {
        ...state,
        users: {
          data: { map: action.payload, roles },
          error: null,
          loading: false,
          resolved: true,
        },
      };
    case U.FETCH_USERS_FULFILLED:
      return {
        ...state,
        users: {
          data: action.payload,
          error: null,
          loading: false,
          resolved: true,
        },
      };
    default:
      return initialState;
  }
};
