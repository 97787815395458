// @flow
import styled from 'styled-components';
import { spacing } from 'styles/constants';

export default styled.li`
  display: inline-block;
  padding: ${spacing('small')};

  ${props =>
    props.clickable &&
    `
    cursor: pointer;
  `}

  ${props =>
    props.selected &&
    `
    font-weight: 600;
  `}
`;
