// @flow
import React, { useEffect, useLayoutEffect } from 'react';
import type { ContextRouter } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import { StringParam, useQueryParams } from 'use-query-params';

import Toggle from 'components/Toggle';
import { IconButton } from 'components/Icon';
import { Screen, Block, Flex } from 'components/Containers';
import Header from 'components/Header';
import { TOKEN_KEY } from 'services/keys';
import { URL } from 'services/base';
import type { User } from 'store/user/types';
import { isAdmin } from 'utils/auth';
import LoaderView from 'views/LoaderView';

import ProjectList from './components/ProjectList';
import ProjectCards from './components/ProjectCards';
import { useProjects, type ProjectsProvider } from 'hooks/useProjects';
import { JiraLinkDialog } from 'dialogs/JiraLinkDialog';

import { getAllReportsForProject } from 'services/reports';
type Props = {
  ...ContextRouter,
  user: User,
  isCardView: Boolean,
};

export default function ProjectsView(props: Props) {
  const { history, user, isCardView, toggleDisplayPreference } = props;
  const {
    fetchProjects,
    getProjects,
    config,
    setConfig,
  }: ProjectsProvider = useProjects();
  const projects = getProjects();

  const [{ statusFilter = config.filter }, setQuery] = useQueryParams({
    statusFilter: StringParam,
  });

  const isInitial = React.useRef(true);
  const initialToggle = statusFilter === 'archived';
  const [toggle, setToggle] = React.useState(initialToggle);
  const [showJiraLinkDialog, setShowJiraLinkDialog] = React.useState(false);
  const [projectData, setProjectData] = React.useState([]);

  useEffect(() => {
    setConfig({ filter: statusFilter });
    fetchProjects();
  }, []);

  // if updated at of most current report updated_at is null display previous report's updated_at data
  let unmounted = false;
  useEffect(() => {
    async function handleNullUpdatedAtForReports(myProjectData) {
      if (unmounted) {
        return;
      }
      const updatedProjects = await myProjectData.map(async project => {
        const allReportsForProject = await getAllReportsForProject(project.id);
        const updatedProjectReports = allReportsForProject.map(
          (report, idx) => {
            if (report.updatedAt === null) {
              if (idx === allReportsForProject.length - 1) {
                return { ...report, updatedAt: null };
              }
              return {
                ...report,
                latestValidReportDate: allReportsForProject[idx + 1].updatedAt,
              };
            }
            return report;
          }
        );
        return { ...project, reports: [updatedProjectReports[0] || []] };
      });
      const response = await Promise.all(updatedProjects);
      setProjectData(response);
    }
    if (projects.data.length > 0) {
      handleNullUpdatedAtForReports(projects.data);
    }
    return () => {
      unmounted = true;
    };
  }, [projects]);

  function onProjectClick(projectId) {
    history.push(`/admin/projects/${statusFilter}/${projectId}/timeline`);
  }

  function init() {
    if (!user.authenticated) {
      setShowJiraLinkDialog(true);
    } else {
      setShowJiraLinkDialog(false);
    }
  }

  useLayoutEffect(() => {
    init();
  }, []);

  function toggleProjects(value: boolean) {
    if (toggle === value) return;
    const filter = !value ? 'active' : 'archived';
    setQuery({ statusFilter: filter });
    setConfig({ filter });
    setToggle(!toggle);
  }

  function renderContent() {
    const projectProps = { projects: projectData, onProjectClick };

    if (projects.loading || projectData.length === 0) return <LoaderView />;
    if (isCardView) return <ProjectCards {...projectProps} />;
    if (!isCardView) return <ProjectList {...projectProps} />;
    return null;
  }

  function renderPage() {
    if (isInitial.current && projects.loading) return <LoaderView />;
    isInitial.current = false;
    if (showJiraLinkDialog)
      return (
        <JiraLinkDialog
          open={showJiraLinkDialog}
          onConfirm={() =>
            window.location.assign(
              `${URL}/auth3p/jira/authenticate?token=${localStorage.getItem(
                TOKEN_KEY
              )}`
            )
          }
        />
      );
    return (
      <>
        <Header admin={isAdmin(user)}>
          <Flex item align="center">
            <Header.Title>Projects</Header.Title>
          </Flex>
          <Flex item align="center" justify="flex-end">
            <Flex width="auto" margin="0 4 0 0">
              <IconButton
                name="grid"
                alt="projects grid"
                selected={isCardView}
                onClick={toggleDisplayPreference}
              />
              <IconButton
                name="list"
                alt="projects list"
                selected={!isCardView}
                onClick={toggleDisplayPreference}
              />
            </Flex>
            <Toggle
              defaultChecked={toggle}
              onChange={toggleProjects}
              off="Active"
              on="Archive"
            />
          </Flex>
        </Header>
        <Screen>
          <Block margin="5 0">{renderContent()}</Block>
        </Screen>
      </>
    );
  }
  return (
    <DocumentTitle title="Projects">
      <Block>{renderPage()}</Block>
    </DocumentTitle>
  );
}
