// @flow
import 'whatwg-fetch';
// import qs from 'querystring';
import { checkStatus, getData, getOne } from './normalizer';
import headers from './headers';
import { URL } from './base';
import type { Attachment, Employee } from '../providers/SlackProvider/types';

export function getSlackEmployees() {
  const url = `${URL}/slack/users`;

  return fetch(url, {
    method: 'GET',
    headers: headers(),
  })
    .then(checkStatus)
    .then(getData);
}

export function sendSlackMessage(
  slackUser: Employee,
  message: string,
  attachments: Attachment[]
) {
  const employee = { id: slackUser.id };
  const payload = { employee, message, attachments };
  return fetch(`${URL}/slack/message`, {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify(payload),
  })
    .then(checkStatus)
    .then(getOne);
}
