// @flow
import React from 'react';
import { LoaderWrapper } from '../styled';
import { color } from 'styles/constants';
import { darken, lighten } from 'polished';

type Props = {
  size: number,
};

const faintBlue = lighten(0.05, color.LIGHT_BLUE);
const lightBlue = lighten(0.025, color.LIGHT_BLUE);
const blue = color.LIGHT_BLUE;
const darkBlue = darken(0.025, color.LIGHT_BLUE);

export default function Loader(props: Props) {
  const { size } = props;
  return (
    <LoaderWrapper size={size}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <title id="loader">loader</title>
        <circle cx="84" cy="50" r="0" fill={darkBlue}>
          <animate
            attributeName="r"
            values="10;0;0;0;0"
            keyTimes="0;0.25;0.5;0.75;1"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            calcMode="spline"
            dur="1.5s"
            repeatCount="indefinite"
            begin="0s"
          />
          <animate
            attributeName="cx"
            values="84;84;84;84;84"
            keyTimes="0;0.25;0.5;0.75;1"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            calcMode="spline"
            dur="1.5s"
            repeatCount="indefinite"
            begin="0s"
          />
        </circle>
        <circle cx="84" cy="50" r="0.432307" fill={blue}>
          <animate
            attributeName="r"
            values="0;10;10;10;0"
            keyTimes="0;0.25;0.5;0.75;1"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            calcMode="spline"
            dur="1.5s"
            repeatCount="indefinite"
            begin="-0.75s"
          />
          <animate
            attributeName="cx"
            values="16;16;50;84;84"
            keyTimes="0;0.25;0.5;0.75;1"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            calcMode="spline"
            dur="1.5s"
            repeatCount="indefinite"
            begin="-0.75s"
          />
        </circle>
        <circle cx="82.5302" cy="50" r="10" fill={lightBlue}>
          <animate
            attributeName="r"
            values="0;10;10;10;0"
            keyTimes="0;0.25;0.5;0.75;1"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            calcMode="spline"
            dur="1.5s"
            repeatCount="indefinite"
            begin="-0.375s"
          />
          <animate
            attributeName="cx"
            values="16;16;50;84;84"
            keyTimes="0;0.25;0.5;0.75;1"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            calcMode="spline"
            dur="1.5s"
            repeatCount="indefinite"
            begin="-0.375s"
          />
        </circle>
        <circle cx="48.5302" cy="50" r="10" fill={faintBlue}>
          <animate
            attributeName="r"
            values="0;10;10;10;0"
            keyTimes="0;0.25;0.5;0.75;1"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            calcMode="spline"
            dur="1.5s"
            repeatCount="indefinite"
            begin="0s"
          />
          <animate
            attributeName="cx"
            values="16;16;50;84;84"
            keyTimes="0;0.25;0.5;0.75;1"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            calcMode="spline"
            dur="1.5s"
            repeatCount="indefinite"
            begin="0s"
          />
        </circle>
        <circle cx="16" cy="50" r="9.56769" fill={darkBlue}>
          <animate
            attributeName="r"
            values="0;0;10;10;10"
            keyTimes="0;0.25;0.5;0.75;1"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            calcMode="spline"
            dur="1.5s"
            repeatCount="indefinite"
            begin="0s"
          />
          <animate
            attributeName="cx"
            values="16;16;16;50;84"
            keyTimes="0;0.25;0.5;0.75;1"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            calcMode="spline"
            dur="1.5s"
            repeatCount="indefinite"
            begin="0s"
          />
        </circle>
      </svg>
    </LoaderWrapper>
  );
}

Loader.defaultProps = {
  size: 24,
};
