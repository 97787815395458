// @flow

export type ContentType = 'statuses' | 'updates' | 'comments';

export type Comment = {
  id: string,
  createdAt: Date,
  summary: string,
  userId: string,
};

export type MilestoneEdits = {
  key?: string,
  before: string,
  after: string,
};

export type MilestoneHistoryEntry = {
  id: string,
  milestoneId: string,
  userId?: string,
  why?: string,
  type: string,
  edits?: MilestoneEdits,
  createdAt: string,
};

export const MilestoneUpdateTypes = {
  ADD: 'ADD',
  ARCHIVE: 'ARCHIVE',
  COMPLETE: 'COMPLETE',
  UPDATE: 'UPDATE',
};
export const MilestoneStatusTypes = {
  CLIENT: 'client',
  GOOD: 'good',
  DIALEXA: 'dialexa',
};
export type MilestoneUpdateType = $Keys<typeof MilestoneUpdateTypes>;
export type MilestoneStatusType = $Keys<typeof MilestoneStatusTypes>;

export type Milestone = {
  id: string,
  archived: boolean,
  planned: Date,
  projectId: string,
  forecast?: Date,
  actual?: Date,
  description: string,
  notes: string,
  status: MilestoneStatusType,
  weekNo?: number,
  createdAt?: string,
  updatedAt?: string,
};

export type MilestonesFilters = 'all' | 'active' | 'archived';
export type MilestonesDateSort = 'descending' | 'ascending';

export type MilestoneProps = {
  milestonesFilter: MilestonesFilters,
  milestonesDateSort: MilestonesDateSort,
};

export type MilestoneAddPayload = {
  status: MilestoneStatusType,
  description: string,
  planned: Date,
  notes: string,
  projectId: string,
  forecast?: Date,
};

export type MilestoneUpdatePayload = {
  projectId: string,
  userId: string,
  type: MilestoneUpdateType,
  milestone?: Milestone,
  milestoneId: string,
  why?: string,
};

export type Update = {
  description: string,
  notes: string,
  updatedAt: Date,
  userId: string,
};

export type ReportStatus = {
  project: {
    status: string,
    comment: string,
  },
  client: {
    status: string,
    comment: string,
  },
  growth: {
    status: string,
    comment: string,
  },
};

type ProjectIssueTypes = {
  task: boolean,
  story: boolean,
  screen: boolean,
  bug: boolean,
  technical: boolean,
  display: boolean,
};

type ProjectSections = {
  bugs: boolean,
  version: boolean,
  upcomingSprints: boolean,
  sprint: boolean,
  budget: boolean,
  concerns: boolean,
};

export type ProjectReportContent = {
  comments: Array<Comment>,
  updates: Array<Update>,
  statuses: ReportStatus,
};

export type ProjectReport = {
  id: string,
  projectId: string,
  hasProjectData: boolean, // note: not sure what this is
  comments: Array<Comment>,
  updates: Array<Update>,
  statuses: ReportStatus,
  reportDate: string,
  isSelected: boolean,
  isCurrentWeek: boolean,
  weekNo: number,
};

export type History = {
  id: string,
  projectId: string,
  userId: string,
  editedAt: string,
  createdAt: string,
  updatedAt: ?string,
};

export type Project = {
  id: string,
  accountId: string,
  sourceId: string,
  name: string,
  start: string, // date string
  end: string, // date string
  totalWeeks: number,
  issueTypes: ProjectIssueTypes,
  sectionsDiaplayed: ProjectSections,
  measurement: 'Points' | 'Issues',
  type: 'kanban' | 'scrum',
  key: string,
  visible: boolean,
  delivered: boolean,
  totalBudget: number,
  spentBudget: number,
  label:
    | 'Design'
    | 'Inernal'
    | 'Software'
    | 'Development'
    | 'Hardware'
    | 'Research'
    | 'Support',
  qualityKpi: 'good' | 'ok' | 'bad',
  archive: boolean,
  reports: Array<ProjectReport>,
  milestones: Array<Milestone>,
  history: History,
  clientName: string,
  projectType: string,
  tenKId: number,
};

export type Action = {
  isLoading: boolean,
  isError: Error,
  hasResolved: boolean,
  type: string,
};

export type ProjectState = {
  isLoading: boolean,
  isError: Error,
  hasResolved: boolean,
  data: Project,
  action: Action,
};

export type GetState = () => ProjectState;
