// @flow
import React from 'react';
import type { Alert } from '../types';

type Props = {
  children?: React$Node,
};

type State = {
  alerts: Alert[],
};

export type ContextProps = {
  ...State,
  createAlert: (alert: Alert) => void,
  closeAlert: (id: string) => void,
};

export const AlertContext = React.createContext<ContextProps>({
  alerts: [],
  createAlert: () => {},
  closeAlert: () => {},
});

let id = 0;

const initialState = {
  alerts: [],
};

const CREATE_ALERT = 'CREATE_ALERT';
const CLOSE_ALERT = 'CLOSE_ALERT';

const reducer = (state, action) => {
  switch (action.type) {
    case CREATE_ALERT:
      return {
        ...state,
        alerts: [action.payload, ...state.alerts],
      };
    case CLOSE_ALERT:
      return {
        ...state,
        alerts: [...state.alerts.filter(alert => alert.id !== action.payload)],
      };
    default:
      return initialState;
  }
};

export default function AlertProvider(props: Props) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const actions = {
    createAlert: (alert: Alert) => {
      const newAlertId = id;
      dispatch({ type: CREATE_ALERT, payload: { id: newAlertId, ...alert } });
      setTimeout(() => {
        dispatch({ type: CLOSE_ALERT, payload: newAlertId });
      }, 5000);
      id++;
    },
    closeAlert: (alertId: string) => {
      dispatch({ type: CLOSE_ALERT, payload: alertId });
    },
  };

  return (
    <AlertContext.Provider value={{ ...state, ...actions }}>
      {props.children}
    </AlertContext.Provider>
  );
}

export const AlertConsumer = AlertContext.Consumer;
