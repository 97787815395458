import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  projectIsLoading,
  getProjectData,
  getAllReports,
  getCurrentUser,
} from 'store/selectors';

import { fetchProject } from 'store/project/actions';

import ProjectDetailsView from './presenter';

function mapStateToProps(state) {
  return {
    project: getProjectData(state),
    reports: getAllReports(state),
    isLoading: projectIsLoading(state),
    user: getCurrentUser(state),
  };
}

function mapActionsToProps(dispatch) {
  return bindActionCreators(
    {
      fetchProject,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapActionsToProps)(ProjectDetailsView);
