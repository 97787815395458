//  @flow
import styled from 'styled-components';
import { default as MaterialList } from '@material-ui/core/List';
import { color } from 'styles/constants';

export default styled(MaterialList)`
  && {
    color: ${color.BLACK}
    padding: 0;
  }
`;
