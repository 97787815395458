// @flow
import React from 'react';
import { default as MaterialDrawer } from '@material-ui/core/Drawer';
import { Block, Flex } from 'components/Containers';
import { signOut } from 'services/auth';

import DrawerHeader from './DrawerHeader';
import DrawerItems from './DrawerItems';
import DrawerItem from './DrawerItem';
import DrawerFooter from './DrawerFooter';

type Props = {
  ...ContextRouter,
  open: boolean,
  onClose: () => void,
  admin: boolean,
};

function Drawer({ open, onClose, admin }: Props) {
  function onLogOutClick() {
    window.auth2.signOut().then(() => signOut());
  }
  return (
    <MaterialDrawer open={open} onClose={onClose}>
      <Flex
        padding="5"
        bg="off-black"
        width="320px"
        fullHeight
        direction="column"
        justify="space-between"
      >
        <Block>
          <DrawerHeader onClose={onClose} />

          <DrawerItems>
            <DrawerItem icon="projects" title="Projects" to="/admin/projects" />
            <DrawerItem icon="book" title="Sources" to="/admin/sources" />
            {admin && (
              <DrawerItem icon="people" title="Users" to="/admin/users" />
            )}
            <DrawerItem icon="tv" title="Dashboard" to="/dashboard" />
            <DrawerItem icon="link" title="Links" to="/links" />
            {admin && (
              <DrawerItem
                icon="settings"
                title="Global Settings"
                to="/admin/global_settings"
              />
            )}
          </DrawerItems>
        </Block>

        <DrawerFooter onLogOutClick={onLogOutClick} />
      </Flex>
    </MaterialDrawer>
  );
}

export default Drawer;
