// @flow
import React, { useState } from 'react';
import Card from 'components/Card';
import { Flex } from 'components/Containers';
import Button from 'components/Button';
import Text from 'components/Text';
import Select from 'components/Select';
import MilestoneAddDialog from 'dialogs/ProjectDialogs/MilestoneAddDialog';
import type {
  Project,
  Milestone,
  MilestonesFilters,
} from 'store/project/types';
import type { DateSort } from '../../types';
type Props = {
  project: Project,
  milestones: Array<Milestone>,
  milestonesFilter: MilestonesFilters,
  dateSort: DateSort,
  onFilterChange: (e: SyntheticEvent<HTMLElement>) => void,
  onSortChange: (e: SyntheticEvent<HTMLElement>) => void,
};

export default function ProjectMilestoneListHeader(props: Props) {
  const {
    project,
    milestones,
    milestonesFilter,
    dateSort,
    onFilterChange,
    onSortChange,
  } = props;

  const [addDialog, setAddDialog] = useState(false);
  const disabled = !milestones.length && milestonesFilter === 'all';

  return (
    <>
      <Card.Header
        title={
          <Flex align="center" height="40px">
            <Flex item xs={3}>
              <Button
                onClick={() => setAddDialog(true)}
                disabled={project.archive}
              >
                Add Milestone
              </Button>
            </Flex>
            <Flex item xs={1}>
              <Text color="typography-medium-gray">Date</Text>
            </Flex>
            <Flex item xs={4}>
              <Text color="typography-medium-gray">Summary</Text>
            </Flex>
            <Flex gutter={1} item xs={4} justify="flex-end">
              <Select
                id="sort-milestones"
                label="sort menu"
                visuallyHidden
                value={dateSort}
                onChange={onSortChange}
                disabled={disabled}
              >
                <Select.Item value="descending">Descending</Select.Item>
                <Select.Item value="ascending">Ascending</Select.Item>
              </Select>
              <Select
                id="filter-milestones"
                label="filter menu"
                visuallyHidden
                value={milestonesFilter}
                onChange={onFilterChange}
                disabled={disabled}
              >
                <Select.Item value="all">All</Select.Item>
                <Select.Item value="active">Active</Select.Item>
                <Select.Item value="archived">Archived</Select.Item>
              </Select>
            </Flex>
          </Flex>
        }
      />

      <MilestoneAddDialog
        open={addDialog}
        onConfirm={() => setAddDialog(false)}
        onClose={() => setAddDialog(false)}
      />
    </>
  );
}
