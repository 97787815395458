import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Users from 'views/UsersView';
import ProjectsView from 'views/ProjectsView';
import Project from 'routes/Project';
import Sources from 'views/SourcesView';
import GlobalSettingsView from 'views/GlobalSettingsView';

export default function Admin() {
  return (
    <Switch>
      <Route
        path="/admin/projects/:statusFilter/:projectId"
        component={Project}
      />
      <Route exact path="/admin/projects" component={ProjectsView} />
      <Route exact path="/admin/sources" component={Sources} />
      <Route exact path="/admin/users" component={Users} />
      <Route
        exact
        path="/admin/global_settings"
        component={GlobalSettingsView}
      />
      <Redirect from="*" to="/404" />
    </Switch>
  );
}
