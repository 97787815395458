// @flow
import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import Input from 'components/Input';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { DayPickerStyles } from '../styled';
type Props = {
  id?: number | string,
  placeholder?: String,
  visuallyHidden: boolean,
  onChange: (event: { name: string, value: string }) => void,
  label?: string,
  name?: string,
  value: number | string | undefined,
  dayPickerProps: object,
};

export default function DateSelectDropdown(props: Props) {
  const {
    id,
    placeholder,
    name,
    label,
    onChange,
    value,
    visuallyHidden,
    dayPickerProps,
  } = props;

  function handleDayChange(date) {
    const v = formatDate(date, 'YYYY-MM-DDTHH:mm:ss.sss[Z]');
    const e = { target: { name, value: v } };
    if (onChange) onChange(e);
  }

  return (
    <>
      <DayPickerStyles />
      <DayPickerInput
        placeholder={placeholder}
        value={formatDate(value)}
        parseDate={parseDate}
        onDayChange={handleDayChange}
        component={({ ref, ...other }) => (
          <Input
            inputRef={ref}
            id={id}
            label={label}
            visuallyHidden={visuallyHidden}
            style={{ cursor: 'pointer', margin: 0 }}
            readOnly
            {...other}
          />
        )}
        dayPickerProps={dayPickerProps}
      />
    </>
  );
}

DateSelectDropdown.defaultProps = {
  placeholder: 'MM/DD/YYYY',
};
