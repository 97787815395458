// @flow
import React, { useState } from 'react';

import { Flex } from 'components/Containers';
import Menu from 'components/Menu';
import Text from 'components/Text';
import type { User } from 'store/user/types';
import { titleCase } from 'helpers/formatters';
import { UserRoleDialog } from 'dialogs/UserRoleDialog';
import { useUsers } from 'hooks/useUsers';
import { success } from 'utils/alerts';
import { useAlert } from 'hooks/useAlert';

type Props = {
  user: User,
  isCurrentUser: boolean,
};

export default function UserRow(props: Props) {
  const { isCurrentUser, user } = props;
  if (!user) return '';

  const { createAlert } = useAlert();
  const { archiveUser, unarchiveUser } = useUsers();
  const [showRoleDialog, setShowRoleDialog] = useState(false);

  // Menu Items
  const archivedMenuItems = [
    { name: 'Unarchive', color: 'error', action: () => unarchiveUser(user.id) },
  ];
  const activeMenuItems = [
    {
      name: 'Change Role',
      color: 'black',
      action: () => setShowRoleDialog(true),
    },
    { name: 'Archive', color: 'error', action: () => archiveUser(user.id) },
  ];
  const menuItems = user.archived ? archivedMenuItems : activeMenuItems;

  // Role Change Actions
  const onRoleChangeSuccess = () => {
    createAlert({
      message: success(`${titleCase(user.firstName)}'s Role`).update,
      type: 'success',
    });
    setShowRoleDialog(false);
  };

  // User Attributes
  const name = `${titleCase(user.firstName)} ${titleCase(user.lastName)}`;
  const status = user.archived ? 'Archived' : 'Active';
  const role = titleCase(user.role);

  return (
    <>
      <Flex align="center">
        <Flex align="center" item xs={3}>
          <Text size="normal" data-testid="user-name">
            {name}
          </Text>
        </Flex>
        <Flex item xs={2}>
          <Text size="normal" data-testid="user-status">
            {status}
          </Text>
        </Flex>
        <Flex item xs={4}>
          <Text size="normal">{user.email}</Text>
        </Flex>
        <Flex item xs={2}>
          <Text size="normal" data-testid="user-role">
            {role}
          </Text>
        </Flex>
        <Flex item xs={1} justify="flex-end">
          {!isCurrentUser && (
            <Menu
              items={menuItems}
              iconButtonProps={{ alt: 'menu', noPadding: true }}
            />
          )}
        </Flex>
      </Flex>
      <UserRoleDialog
        open={showRoleDialog}
        user={user}
        onConfirm={onRoleChangeSuccess}
        onClose={() => setShowRoleDialog(false)}
      />
    </>
  );
}
