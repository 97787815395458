// @flow
import type { Dispatch } from 'react';
import * as PT from './constants';
import {
  getAssignedByProjectId,
  getUnassignedByProjectId,
} from 'services/team';

export const fetchAssigned = (dispatch: Dispatch) => async (
  tenKId: string,
  day: string
) => {
  dispatch({ type: PT.FETCH_ASSIGNED_START });

  try {
    const assigned = await getAssignedByProjectId(tenKId, day);
    dispatch({ type: PT.FETCH_ASSIGNED_FULFILLED, payload: assigned });
  } catch (err) {
    dispatch({ type: PT.FETCH_ASSIGNED_ERROR, payload: err });
  }
};

export const fetchUnassigned = (dispatch: Dispatch) => async (
  tenKId: string
) => {
  dispatch({ type: PT.FETCH_UNASSIGNED_START });

  try {
    const unassigned = await getUnassignedByProjectId(tenKId);
    dispatch({ type: PT.FETCH_UNASSIGNED_FULFILLED, payload: unassigned });
  } catch (err) {
    dispatch({ type: PT.FETCH_UNASSIGNED_ERROR, payload: err });
  }
};

export const resetProjectTeam = (dispatch: Dispatch) => () => {
  dispatch({ type: PT.RESET_PROJECT_TEAM });
};
