// @flow
import type { ProjectsState, Actions } from '../types';
import * as P from './constants';

export const initialState = {
  projects: P.PROJECTS,
  config: P.CONFIG,
};

export default (state: ProjectsState, action: Actions) => {
  switch (action.type) {
    case P.FETCH_PROJECTS_START:
      return {
        ...state,
        projects: {
          data: [],
          error: null,
          loading: true,
        },
      };
    case P.FETCH_PROJECTS_ERROR:
      return {
        ...state,
        projects: {
          data: [],
          error: action.payload,
          loading: false,
        },
      };
    case P.FETCH_PROJECTS_FULFILLED:
      return {
        ...state,
        projects: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    case P.SET_CONFIG:
      return {
        ...state,
        config: { ...state.config, ...action.payload },
      };
    default:
      return initialState;
  }
};
