import { getCurrentUser, getRoles, checkJiraAuth } from 'services/auth';
import { deleteCredentialsByUserId } from 'services/credentials';

export const USER_FETCH_START = 'user/FETCH_START';
export const USER_FETCH_END = 'user/FETCH_END';

export const USER_FETCH_ALL_START = 'user/FETCH_ALL_START';
export const USER_FETCH_ALL_END = 'user/FETCH_ALL_END';

export const REMOVE_AUTH_START = 'user/REMOVE_AUTH_START';
export const REMOVE_AUTH_ERROR = 'user/REMOVE_AUTH_ERROR';
export const REMOVE_AUTH_END = 'user/REMOVE_AUTH_END';

export const TOGGLE_DISPLAY_PREFERENCE = 'user/TOGGLE_DISPLAY_PREFERENCE';

export const currentUser = () => async dispatch => {
  dispatch({ type: USER_FETCH_START });
  const [tempUser, roles, jiraAuth] = await Promise.all([
    getCurrentUser(),
    getRoles(),
    checkJiraAuth(),
  ]);
  const role = roles.data.find(r => r.id === tempUser.roleId);

  const user = {
    ...tempUser,
    role: role.name,
    authenticated: !!jiraAuth,
  };

  dispatch({ type: USER_FETCH_END, payload: user });
};

export const removeUserAuthentication = () => async (dispatch, getState) => {
  dispatch({ type: REMOVE_AUTH_START });
  let { user } = getState().user;
  try {
    await deleteCredentialsByUserId(user.id);
    user = {
      ...user,
      authenticated: false,
    };
    dispatch({ type: REMOVE_AUTH_END, payload: user });
  } catch (err) {
    throw new Error(`Error removing authentication of user - ${err.message}`);
  }
};

export const toggleDisplayPreference = () => dispatch => {
  dispatch({ type: TOGGLE_DISPLAY_PREFERENCE });
};

export const handlers = {
  [USER_FETCH_START](state) {
    return {
      ...state,
      loading: true,
    };
  },

  [USER_FETCH_END](state, action) {
    return {
      ...state,
      loading: false,
      user: action.payload,
    };
  },

  [REMOVE_AUTH_START](state) {
    return {
      ...state,
      loading: true,
    };
  },

  [REMOVE_AUTH_END](state, action) {
    return {
      ...state,
      loading: false,
      user: action.payload,
    };
  },

  [USER_FETCH_ALL_START](state) {
    return {
      ...state,
      loading: true,
    };
  },

  [USER_FETCH_ALL_END](state, action) {
    return {
      ...state,
      loading: false,
      users: action.payload,
    };
  },

  [TOGGLE_DISPLAY_PREFERENCE](state) {
    const { isCardView } = state;
    return {
      ...state,
      isCardView: !isCardView,
    };
  },
};

export default {
  currentUser,
  removeUserAuthentication,
};
