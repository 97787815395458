import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Sources from './presenter';
import { getCurrentUser, getSourcesLoading, getSources } from 'store/selectors';
import { fetchSources } from 'store/source/actions';

function mapStateToProps(state) {
  return {
    currentUser: getCurrentUser(state),
    sources: getSources(state),
    isLoading: getSourcesLoading(state),
  };
}

function mapActionsToProps(dispatch) {
  return bindActionCreators(
    {
      fetchSources,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapActionsToProps)(Sources);
