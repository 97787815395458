// @flow
import React, { useState } from 'react';
import get from 'lodash.get';

import Avatar from 'components/Avatar';
import Button from 'components/Button';
import Card from 'components/Card';
import { Block, Flex } from 'components/Containers';
import List from 'components/List';
import Text from 'components/Text';
import { color } from 'styles/constants';
import CommentDialog from 'dialogs/ProjectDialogs/CommentDialog/index';
import type { Comment, ProjectReport } from 'store/project/types';
import type { User } from 'store/user/types';
import ProjectComment from './components/ProjectComment';
type Props = {
  comments: Array<Comment>,
  currentUser: User,
  isCurrentWeek: boolean,
  selectedReport: ProjectReport,
  editable: boolean,
  deleteCommentItem: (type: Comment, report: ProjectReport) => void,
};

export default function ProjectComments(props: Props) {
  let { comments } = props;
  const {
    currentUser,
    deleteCommentItem,
    isCurrentWeek,
    selectedReport,
    editable,
  } = props;

  // if no comments found in comment table, look in report content column
  if (comments && comments.length === 0) {
    comments = get(selectedReport, 'contents.comments', []);
    comments = comments.map(comment => {
      return {
        userId: comment.who,
        summary: comment.what,
        reportId: selectedReport.id,
      };
    });
  }

  const [showCommentDialog, setShowCommentDialog] = useState(false);

  function renderCommentList() {
    return (
      <List
        items={
          comments &&
          comments.map((comment, idx) => (
            <ProjectComment
              currentUser={currentUser}
              key={idx}
              idx={idx}
              comment={comment}
              deleteCommentItem={deleteCommentItem}
              editable={editable}
              selectedReport={selectedReport}
            />
          ))
        }
      />
    );
  }

  function renderEmptyContent() {
    const emptyState = {
      prev: 'No Comments to display',
      current: 'Add Comments to fill this section',
    };

    return (
      <Text align={'center'} color="typography-medium-gray" weight={600}>
        {isCurrentWeek ? emptyState.current : emptyState.prev}
      </Text>
    );
  }

  function renderCardContent() {
    return comments && comments.length
      ? renderCommentList()
      : renderEmptyContent();
  }

  function renderCardHeader() {
    return (
      <Flex align="center">
        <Flex align="center" item xs={8}>
          <Flex gutter={2} align="center">
            <Block flex="none">
              <Text tag="h3" weight={600}>
                Comments
              </Text>
            </Block>
            {editable && (
              <Block flex="none">
                <Button onClick={() => setShowCommentDialog(true)}>
                  Add Comments
                </Button>
              </Block>
            )}
          </Flex>
        </Flex>
        <Flex item xs={2}>
          <Text color="typography-medium-gray">Date</Text>
        </Flex>
        <Flex item xs={1}>
          <Avatar color={color.LIGHT_GRAY} placeholder />
        </Flex>
        <Flex item xs={1} />
      </Flex>
    );
  }

  return (
    <>
      <Card>
        <Card.Header title={renderCardHeader()} />
        <Card.Content>{renderCardContent()}</Card.Content>
      </Card>

      <CommentDialog
        method="add"
        open={showCommentDialog}
        onConfirm={() => setShowCommentDialog(false)}
        onClose={() => setShowCommentDialog(false)}
      />
    </>
  );
}
