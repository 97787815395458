import styled from 'styled-components';

export default styled.input`
  height: 0;
  width: 0;
  visibility: hidden;

  &:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }
`;
