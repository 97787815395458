import React from 'react';
import PropTypes from 'prop-types';
import Risk from 'components/Risk';
import './style.scss';

const Concerns = props => {
  return (
    <div className="Concerns">
      <div className="Concerns-num">{`Risks (${props.total || ''})`}</div>
      <hr />
      {props.issues.map((issue, idx) => (
        <Risk key={idx} risk={issue} />
      ))}
    </div>
  );
};

Concerns.propTypes = {
  issues: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
};

export default Concerns;
