// base normalizer functions
import { TOKEN_KEY } from 'services/keys';

const throwError = (res, msg) => {
  return res.json().then(err => {
    if (msg) throw new Error(msg);
    throw err;
  });
};

export function checkStatus(response) {
  const status = response.status;
  switch (true) {
    case status === 403:
      return throwError(response);
    case status === 401:
      localStorage.removeItem(TOKEN_KEY);
      return throwError(
        response,
        'User is not authorized to view this resource'
      );
    case status >= 200 && status < 300:
      return response;
    default:
      return throwError(response);
  }
}

export function checkJIRAStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else if (response.status === 403) {
    return false;
  }
  return response.json().then(err => {
    throw new Error(err.message);
  });
}

export function checkSlackStatus(response) {
  if (response.ok) return response;

  throw new Error(response.error);
}

export const getData = resp => resp.json().then(res => res.data);

export const getOne = resp => resp.json().then(res => res.data[0]);

export const getMeta = resp => resp.json().then(res => res.meta);

export const getAll = resp => resp.json();
