import styled from 'styled-components';
import { spacing } from 'styles/constants';

export const MasonryItem = styled.div`
  break-inside: avoid;
  padding: ${spacing('normal')};
`;

export const Masonry = styled.section`
  column-count: 1;
  column-gap: 0;

  @media (min-width: 425px) {
    column-count: 1;
  }

  @media (min-width: 1024px) {
    column-count: 2;
  }

  @media (min-width: 1400px) {
    column-count: 3;
  }

  @media (min-width: 1920px) {
    column-count: 4;
  }

  @media (min-width: 2560px) {
    column-count: 5;
  }
`;
