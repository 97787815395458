// @flow
import React from 'react';
import StyledBlock from '../styled/Block';

type Props = {
  children?: Node,
  bg?: 'blue' | 'white' | 'faux-white' | 'off-black',
  border?:
    | string
    | { top: string, bottom: string, left: string, right: string },
  hasBorderRadius?: boolean,
  raised?: boolean,
  width?: number,
  padding?: 'auto' | '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7',
  margin?: 'auto' | '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7',
  fullWidth?: boolean,
  fullHeight?: boolean,
  height?: string,
  maxWidth?: string,
  maxHeight?: string,
  flex?: string,
  position?: 'relative' | 'absolute',
};

export default function Block(props: Props) {
  return <StyledBlock {...props} />;
}
