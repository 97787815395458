// @flow
import React, { useEffect, useState } from 'react';

import { getMilestoneHistoryById } from 'services/milestones';
import { Flex } from 'components/Containers';
import Loader from 'components/Loader';
import Text from 'components/Text';
import type {
  Milestone,
  MilestoneHistoryEntry,
  Project,
} from 'store/project/types';
import MilestoneHistoryRow from './components/MilestoneHistoryRow';
import { ShadeFlex } from './components/ShadeFlex';
import { color } from 'styles/constants';

type Props = {
  milestone: Milestone,
  project: Project,
};

function MilestoneHistory(props: Props) {
  const { milestone, project } = props;

  const [isHistoyLoading, setIsHistoryLoading] = useState(false);
  const [history, setHistory] = useState(undefined);

  async function getMilestoneHistory() {
    setIsHistoryLoading(true);
    const data: Array<MilestoneHistoryEntry> = await getMilestoneHistoryById(
      project.id,
      milestone.id
    );
    setHistory(data);
    setIsHistoryLoading(false);
  }

  useEffect(() => {
    getMilestoneHistory();
  }, []);

  function renderView() {
    if (isHistoyLoading) return <Loader />;
    return (
      <Flex
        direction="column"
        height="300px"
        margin="3 0 0 0"
        style={{ overflowY: 'scroll' }}
      >
        {history &&
          history.map((h, idx) => (
            <MilestoneHistoryRow
              key={idx}
              entry={h}
              showDivider={idx !== history.length - 1}
              milestone={milestone}
            />
          ))}
      </Flex>
    );
  }

  return (
    <ShadeFlex margin="5 0" direction="column">
      <Flex
        padding="0 0 2 0"
        border={{ bottom: `1px solid ${color.BORDER_COLOR}` }}
      >
        <Text tag="h2" margin="0 0 1 0" weight={600}>
          Version History
        </Text>
      </Flex>
      {renderView()}
    </ShadeFlex>
  );
}

export default MilestoneHistory;
