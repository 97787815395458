// @flow
import React from 'react';

import reducer, { initialState } from './reducer';
import { fetchProjects, setConfig } from './actions';
import { getProjects } from './selectors';
import type { ProjectsState, ProjectsAction } from '../types';

type Props = {
  children?: React$Node,
};

export type ContextProps = {
  ...ProjectsState,
  ...ProjectsAction,
};

export const ProjectsContext = React.createContext<ContextProps>({
  initialState,
  fetchProjects: () => {},
  setConfig: () => {},
});

export default function ProjectsProvider(props: Props) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const selectors = {
    getProjects: sProps => getProjects(state, sProps),
  };

  const actions = {
    fetchProjects: fetchProjects(dispatch, state),
    setConfig: setConfig(dispatch, state),
  };

  return (
    <ProjectsContext.Provider value={{ ...state, ...actions, ...selectors }}>
      {props.children}
    </ProjectsContext.Provider>
  );
}

export const ProjectsConsumer = ProjectsContext.Consumer;
