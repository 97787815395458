import { handlers } from './actions';
import { createReducerFromHandlers } from '../helpers';

export const initialState = {
  loading: true,
  user: null,
  isCardView: true,
};

export default createReducerFromHandlers(handlers, initialState);
