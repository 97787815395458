// @flow
import styled, { css } from 'styled-components';
import { color } from 'styles/constants';
import { lighten } from 'polished';

export const WeekContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  flex: none;
  outline: none;

  & .week-status-group {
    border-color: ${({ active }) => (active ? color.BLUE : 'transparent')};
  }
  &:focus .week-status-group {
    background: ${({ active }) =>
      active ? 'transparent' : lighten(0.05, color.LIGHT_BLUE)};
  }
`;

export const WeekMilestoneContainer = styled.div`
  display: flex;
  position: relative;
  z-index: 1;
  height: 26px;

  button:first-child {
    width: 25px;
  }

  button:nth-child(2) {
    width: 25px;
    margin: 0 3px 0 6px;
  }

  button:nth-child(n + 3) {
    width: 25px;
    margin: 0 3px;
  }
`;

export const WeekNumberContainer = styled.div`
  margin: 10px 0 5px 0;
  width: 25px;
`;

export const WeekStatusGroup = styled.div`
  display: flex;
  justify-content: center;
  min-width: 20px;
  padding: 0 3px;
  border-width: 2px;
  border-style: solid;
  border-radius: 1rem;
  cursor: pointer;
  transition: all 200ms ease-in-out;

  &[disabled],
  &:disabled {
    cursor: not-allowed;
  }

  & > div > div:nth-child(odd) {
    margin: 5px 0;
  }
`;

export const MilestoneIndicators = styled.div`
  height: 0;
  display: flex;
  align-items: center;

  & > div:first-child {
    margin: 0 3px;
  }

  & > div:nth-child(2) {
    margin-left: 3px;
  }

  & > div:nth-child(n + 3) {
    margin-left: 6px;
  }
`;

export const MilestoneIndicator = styled.div`
  position: relative;
  z-index: 0;
  display: inline-block;
  height: 140px;
  border-right: 2px solid ${color.LIGHT_GRAY};

  ${props =>
    props.multiple &&
    css`
      min-width: 25px;
      width: 25px;
    `}
`;
