// @flow
import React from 'react';

import Text from 'components/Text';
import Card from 'components/Card';
import DateSelect from 'components/DateSelect';
import { Flex } from '/components/Containers';
import type { Project } from 'store/project/types';

type Props = {
  project: Project,
  selectedDay: string,
  selectedWeek: Array<string>,
  onChange: (e: SyntheticMouseEvent<any>) => void,
};

export default function ProjectTeamAssignedHeader(props: Props) {
  const { project, selectedDay, selectedWeek, onChange } = props;

  const dayPickerProps = {
    selectedDays: selectedWeek,
    disabledDays: [
      ...selectedWeek,
      {
        before: new Date(project.start),
        after: new Date(project.end),
      },
    ],
  };

  return (
    <Card.Header
      title={
        <Flex align="center" height="40px">
          <Flex item xs={3}>
            <Text color="typography-medium-gray">Name</Text>
          </Flex>
          <Flex item xs={2}>
            <Text color="typography-medium-gray">Role</Text>
          </Flex>
          <Flex item xs={2}>
            <Text color="typography-medium-gray">Allocation</Text>
          </Flex>
          <Flex item xs={2} padding="0 2">
            <Text color="typography-medium-gray">Roll-Off</Text>
          </Flex>
          <Flex item xs={3} justify="flex-end">
            <DateSelect
              id="team-date-select-menu"
              label="team date select menu"
              visuallyHidden
              value={selectedDay}
              onChange={onChange}
              dayPickerProps={dayPickerProps}
            />
          </Flex>
        </Flex>
      }
    />
  );
}
