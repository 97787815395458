import { keyframes } from 'styled-components';

export const slideInRight = keyframes`
  0% {
    transform: translate3d(30%,0,0);
  }
  100% {
    transform: translate3d(0,0,0);
  }
`;

export const slideOutRight = keyframes`
  from {
    transform: translate3d(100%,0,0);
  }
  to {
    transform: translate3d(100%,0,0);
  }
`;
