import { checkJiraAuth } from 'services/auth';

export const JIRA_AUTH_SUCCESS = 'jira/AUTH_SUCCESS';
export const JIRA_AUTH_FAIL = 'jira/AUTH_FAIL';

export const checkAuth = () => dispatch => {
  return checkJiraAuth().then(res => {
    if (!res) {
      dispatch({ type: JIRA_AUTH_FAIL });
      return false;
    }

    dispatch({ type: JIRA_AUTH_SUCCESS });
    return true;
  });
};

export const handlers = {
  [JIRA_AUTH_FAIL](state) {
    return {
      ...state,
      isAuthenticated: false,
    };
  },

  [JIRA_AUTH_SUCCESS](state) {
    return {
      ...state,
      isAuthenticated: true,
    };
  },
};

export default {
  checkAuth,
};
