import styled, { css } from 'styled-components';
import { color, spacing } from 'styles/constants';

const border = props => {
  switch (typeof props.border) {
    case 'string':
      return css`
        border: ${props.border};
      `;
    case 'object':
      return css`
        border-top: ${props.border.top};
        border-right: ${props.border.right};
        border-bottom: ${props.border.bottom};
        border-left: ${props.border.left};
      `;
    default:
      return null;
  }
};

const bg = props => {
  switch (props.bg) {
    case 'blue':
      return css`
        background: ${color.BLUE};
        color: ${color.WHITE};
      `;
    case 'off-black':
      return css`
        background: ${color.OFF_BLACK};
        color: ${color.WHITE};
      `;
    case 'faux-white':
      return css`
        background: ${color.FAUX_WHITE};
      `;
    case 'white':
      return css`
        background: ${color.WHITE};
      `;
    default:
      return css`
        background: transparent;
      `;
  }
};

const spacingValues = values => {
  return values
    .trim()
    .split(' ')
    .map(value => {
      switch (value.toString()) {
        case 'auto':
          return 'auto';
        case '0':
          return 0;
        case '1':
          return 'xsmall';
        case '2':
          return 'small';
        case '3':
          return 'normal';
        case '4':
          return 'medium';
        case '5':
          return 'large';
        case '6':
          return 'xlarge';
        case '7':
          return 'xxlarge';
        default:
          return null;
      }
    });
};

const padding = props =>
  props.padding &&
  css`
    padding: ${spacing(...spacingValues(props.padding))};
  `;

const margin = props =>
  props.margin &&
  css`
    margin: ${spacing(...spacingValues(props.margin))};
  `;

const width = props =>
  props.width &&
  css`
    flex: none;
    width: ${props.width};
  `;

const height = props =>
  props.height &&
  css`
    height: ${props.height};
  `;

const maxWidth = props =>
  props.maxWidth &&
  css`
    max-width: ${props.maxWidth};
  `;

const maxHeight = props =>
  props.maxHeight &&
  css`
    max-height: ${props.maxHeight};
  `;

const shadow = props =>
  props.raised &&
  css`
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  `;

const borderRadius = props =>
  props.hasBorderRadius &&
  css`
    border-radius: 4px;
  `;

const fullWidth = props =>
  props.fullWidth &&
  css`
    min-width: 100%;
    width: 100%;
  `;

const fullHeight = props =>
  props.fullHeight &&
  css`
    min-height: 100%;
    height: 100%;
  `;

const flex = props =>
  props.flex &&
  css`
    flex: ${props.flex};
  `;

const position = props =>
  props.position &&
  css`
    position: ${props.position};
  `;

export default styled.div`
  flex: 1 1 0%;

  ${flex};
  ${width};
  ${maxWidth};
  ${height};
  ${maxHeight};
  ${bg};
  ${shadow};
  ${borderRadius};
  ${padding};
  ${margin};
  ${border};
  ${fullWidth};
  ${fullHeight};
  ${position};
`;
