// @flow
import { createSelector } from 'reselect';
import { isAfter, startOfDay } from 'date-fns';
import type { ProjectsState, Projects, Config } from '../types';

const projectsData = (state: ProjectsState) => state.projects;
const configData = (state: ProjectsState) => state.config;

const projectsByConfig = (projects: Projects, config: Config) => {
  const filter = config.filter;
  let data = projects.data;

  switch (filter) {
    case 'active':
      const isFutureProject = project =>
        isAfter(startOfDay(project.start), startOfDay(new Date()));

      data = data.filter(
        project => !project.archive && !isFutureProject(project)
      );
      break;
    case 'archived':
      data = data.filter(project => project.archive);
      break;
    case 'all':
      break;
    default:
      break;
  }

  data = data.filter(project => project.visible);

  return { ...projects, data };
};

export const getProjects = createSelector(
  projectsData,
  configData,
  projectsByConfig
);
