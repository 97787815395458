import 'whatwg-fetch';
import { TOKEN_KEY } from './keys';
import headers from './headers';
import { URL } from './base';
import history from '../history';
import { checkJIRAStatus, checkStatus, getOne, getAll } from './normalizer';

export function signIn(token) {
  return fetch(`${URL}/auth3p/google/auth`, {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify({ token }),
  })
    .then(response => response.json())
    .then(response => {
      if (!response.jwt) throw new Error(response.message);
      localStorage.setItem(TOKEN_KEY, response.jwt);
    });
}

export function signOut() {
  return fetch(`${URL}/auth`, {
    method: 'DELETE',
    headers: headers(),
  }).then(() => {
    localStorage.removeItem(TOKEN_KEY);
    history.push('/login');
  });
}

export function getCurrentUser() {
  return fetch(`${URL}/auth/current`, {
    method: 'GET',
    headers: headers(),
  })
    .then(checkStatus)
    .then(getOne);
}

export function getAuthStatus() {
  return getCurrentUser()
    .then(() => {
      return {
        authenticated: localStorage.getItem(TOKEN_KEY) !== null,
        message: '',
      };
    })
    .catch(e => {
      return {
        authenticated: false,
        message: e.message,
      };
    });
}

export function getToken() {
  return localStorage.getItem(TOKEN_KEY);
}

export function checkJiraAuth() {
  return fetch(`${URL}/auth3p/jira/isAuthenticated`, {
    method: 'GET',
    headers: headers(),
  }).then(checkJIRAStatus);
}

export function getRoles() {
  return fetch(`${URL}/roles`, {
    method: 'GET',
    headers: headers(),
  })
    .then(checkStatus)
    .then(getAll);
}
