// @flow
import React, { useRef, useState } from 'react';
import { IconButton } from 'components/Icon';
import Text from 'components/Text';
import StyledMenuItem from '../styled/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MaterialGrow from '@material-ui/core/Grow';
import MaterialPaper from '@material-ui/core/Paper';
import MaterialPopper from '@material-ui/core/Popper';
import MaterialMenuList from '@material-ui/core/MenuList';
import type { IconButtonProps } from 'components/Icon';

type MenuItemType = {
  name: string,
  color: string,
  action: () => void,
};

type Props = {
  items: Array<MenuItemType>,
  iconButtonProps: IconButtonProps,
};

function Menu({ items, iconButtonProps }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const anchorEl = useRef(null);

  // Menu Funtions
  const handleItemClick = (item: MenuItemType) => {
    if (item.action) item.action();
    setIsOpen(false);
  };

  const clickAway = e => {
    e.stopImmediatePropagation();
    setIsOpen(false);
  };

  // Render Functions
  const renderMenuItems = () => (
    <MaterialMenuList>
      {items.map((item, idx) => (
        <StyledMenuItem key={idx} onClick={() => handleItemClick(item)}>
          <Text color={item.color}>{item.name}</Text>
        </StyledMenuItem>
      ))}
    </MaterialMenuList>
  );
  return (
    <>
      <IconButton
        buttonRef={anchorEl}
        name="more"
        selected={isOpen}
        appearance="text"
        color="information"
        onClick={() => setIsOpen(!isOpen)}
        {...iconButtonProps}
      />
      <MaterialPopper
        anchorEl={anchorEl.current}
        style={{ zIndex: 2 }}
        open={isOpen}
        transition
        disablePortal
        placement="bottom-end"
        popperOptions={{
          positionFixed: true,
          modifiers: { offset: { offset: '0, 0' } },
        }}
      >
        {({ TransitionProps }) => (
          <MaterialGrow
            {...TransitionProps}
            style={{ transformOrigin: 'top right' }}
          >
            <MaterialPaper>
              <ClickAwayListener mouseEvent="onClick" onClickAway={clickAway}>
                {renderMenuItems()}
              </ClickAwayListener>
            </MaterialPaper>
          </MaterialGrow>
        )}
      </MaterialPopper>
    </>
  );
}

Menu.defaultProps = {
  items: [],
};

export default Menu;
