// @flow
import React, { useEffect } from 'react';
import { withRouter, type ContextRouter } from 'react-router-dom';
import { isPast, isToday } from 'date-fns';
import { useProjectTeam } from 'hooks/useProjectTeam';
import Text from 'components/Text';
import Card from 'components/Card';
import Icon from 'components/Icon';
import Button from 'components/Button';
import { Block, Flex } from '/components/Containers';
import type { Project } from 'store/project/types';
import type { ProjectTeam } from 'providers/ProjectTeamProvider/types';

type Props = {
  ...ContextRouter,
  project: Project,
};

const isPastDay = (endDate: string) => isPast(endDate) && !isToday(endDate);

function ProjectUnusedBudget(props: Props) {
  const { project, history } = props;
  const { unassigned, fetchUnassigned }: ProjectTeam = useProjectTeam();
  const loading = unassigned.loading;
  const noData = unassigned.data && !unassigned.data.length;

  useEffect(() => {
    fetchUnassigned(project.tenKId);
  }, []);

  if (loading || noData) return null;

  if (unassigned.error) {
    return (
      <Card.Content>
        <Flex fullHeight align="center" justify="center">
          <Text align="center" color="typography-medium-gray">
            There was an error processing the unused budget data.
          </Text>
        </Flex>
      </Card.Content>
    );
  }

  let missedDays = 0;
  let unassignedDays = 0;
  const data = unassigned.data;
  data.forEach(u =>
    isPastDay(u.end) ? (missedDays += u.days) : (unassignedDays += u.days)
  );

  return (
    <Card>
      <Card.Content>
        <Flex fullHeight justify="space-between" align="center">
          <Flex align="center">
            <Flex align="center" width="auto" flex="none">
              <Icon
                alt="alert"
                name="warning"
                color="red"
                viewBox="0 -5 20 25"
                size={30}
              />
              <Block flex="none">
                <Text tag="h3" weight={600}>
                  Unused Budget
                </Text>
              </Block>
            </Flex>
            <Block margin="0 4">
              <Text>
                There are{' '}
                <Text tag="span" weight={600}>
                  {missedDays} days of missed time
                </Text>{' '}
                and{' '}
                <Text tag="span" weight={600}>
                  {unassignedDays} days of unassigned time
                </Text>{' '}
                on this project.
              </Text>
            </Block>
            <Block flex="none">
              <Button onClick={() => history.push('team')}>View Team</Button>
            </Block>
          </Flex>
        </Flex>
      </Card.Content>
    </Card>
  );
}
export default withRouter(ProjectUnusedBudget);
