// @flow
import type { Dispatch } from 'react';
import { getProjects } from 'services/project';
import type { Config } from '../types';
import * as P from './constants';

export const fetchProjects = (dispatch: Dispatch) => async () => {
  dispatch({ type: P.FETCH_PROJECTS_START });

  try {
    const result = await getProjects();
    const sortedProjects = result.sort((a, b) =>
      (a.clientName || '').localeCompare(b.clientName || '')
    );
    dispatch({ type: P.FETCH_PROJECTS_FULFILLED, payload: sortedProjects });
  } catch (err) {
    dispatch({ type: P.FETCH_PROJECTS_ERROR, payload: err });
  }
};

export const setConfig = (dispatch: Dispatch) => (config: Config) => {
  dispatch({ type: P.SET_CONFIG, payload: config });
};
