import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getAction, getCurrentUser, getProjectReport } from 'store/selectors';

import { updateUpdateItem } from 'store/project/actions';

import { UpdateEditDialog } from './presenter';

function mapStateToProps(state) {
  return {
    action: getAction(state),
    currentUser: getCurrentUser(state),
    selectedReport: getProjectReport(state),
  };
}

function mapActionsToProps(dispatch) {
  return bindActionCreators(
    {
      updateUpdateItem,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapActionsToProps)(UpdateEditDialog);
