// @flow
import React, { useEffect, useMemo, useState } from 'react';
import { type ContextRouter, withRouter } from 'react-router-dom';

import DocumentTitle from 'react-document-title';
import { isAdmin } from 'utils/auth';
import Card from 'components/Card';
import { Screen, Block, Flex } from 'components/Containers';
import List from 'components/List';
import Text from 'components/Text';
import LoaderView from 'views/LoaderView';
import type { User } from 'store/user/types';
import UsersHeader from './components/UsersHeader';
import UserRow from './components/UserRow';
import { type UserFilters, useUsers } from 'hooks/useUsers';

type Props = {
  ...ContextRouter,
  currentUser: User,
};

function renderTableHeader() {
  return (
    <Flex align="center">
      <Flex item xs={3}>
        <Text color="typography-medium-gray">Name</Text>
      </Flex>
      <Flex item xs={2}>
        <Text color="typography-medium-gray">Status</Text>
      </Flex>
      <Flex item xs={4}>
        <Text color="typography-medium-gray">Email</Text>
      </Flex>
      <Flex item xs={3}>
        <Text color="typography-medium-gray">Role</Text>
      </Flex>
    </Flex>
  );
}

function getUsersByFilter(users: Array<User>, filter: UserFilters) {
  switch (filter) {
    case 'all':
      return users;
    case 'active':
      return users.filter(user => !user.archived);
    case 'archived':
      return users.filter(user => user.archived);
    default:
      return users;
  }
}

function Users(props: Props) {
  const { currentUser, history } = props;
  const { users: usersState, fetchUsers } = useUsers();
  const { data, loading: isLoading, resolved } = usersState;
  const { map: users } = data;

  const admin = isAdmin(currentUser);
  const [filter, setFilter] = useState('active');
  const isAllFilter = filter === 'all';

  useEffect(() => {
    fetchUsers({ page: 1, pageSize: 300 });
  }, []);

  const filteredUsers = useMemo(() => {
    if (!resolved) return [];
    const usersList = Object.keys(users).map(uKey => ({
      id: uKey,
      ...users[uKey],
    }));
    return getUsersByFilter(usersList, filter);
  }, [filter, users]);

  // Note: Only see users if you are an admin
  if (!admin) history.push('/');

  function renderTableContent() {
    if (isLoading || !resolved) return <LoaderView />;
    return (
      <Card>
        <Card.Header title={renderTableHeader(isAllFilter)} />
        <Card.Content>
          <List
            items={
              filteredUsers &&
              filteredUsers.map((user, idx) => (
                <UserRow
                  key={idx}
                  user={user}
                  isCurrentUser={user.id === currentUser.id}
                />
              ))
            }
          />
          {!filteredUsers.length && (
            <Flex fullHeight padding="5 0" align="center" justify="center">
              <Text align="center" color="typography-medium-gray">
                There are no {isAllFilter ? '' : filter} users.
              </Text>
            </Flex>
          )}
        </Card.Content>
      </Card>
    );
  }

  return (
    <DocumentTitle title="Users">
      <Block>
        <UsersHeader
          admin={admin}
          filter={filter}
          isLoading={isLoading}
          onFilterChange={e => setFilter(e.target.value)}
        />
        <Screen>
          <Flex margin="3 0">{renderTableContent()}</Flex>
        </Screen>
      </Block>
    </DocumentTitle>
  );
}

export default withRouter(Users);
