// @flow
import React from 'react';

import Header from 'components/Header';
import { Flex } from 'components/Containers';
import Select from 'components/Select';

type Props = {
  admin: boolean,
  filter: 'active' | 'archived' | 'all',
  isLoading: boolean,
  onFilterChange: (e: SyntheticEvent<HTMLElement>) => void,
};

export default function UsersHeader(props: Props) {
  const { admin, filter, isLoading, onFilterChange } = props;

  return (
    <Header admin={admin}>
      <Flex item align="center">
        <Header.Title>Users</Header.Title>
      </Flex>
      <Flex item align="center" justify="flex-end">
        <Select
          id="filter-users"
          label="filter menu"
          visuallyHidden
          value={filter}
          onChange={onFilterChange}
          disabled={isLoading}
        >
          <Select.Item value="all">All</Select.Item>
          <Select.Item value="active">Active</Select.Item>
          <Select.Item value="archived">Archived</Select.Item>
        </Select>
      </Flex>
    </Header>
  );
}
