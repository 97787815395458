import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import GlobalSettingsView from './presenter';
import {
  getGlobalSettings,
  getGlobalSettingsLoading,
  getCurrentUser,
} from 'store/selectors';
import { updateGlobalSettings } from 'store/globalSettings/actions';

function mapStateToProps(state) {
  return {
    user: getCurrentUser(state),
    globalSettings: getGlobalSettings(state),
    isLoading: getGlobalSettingsLoading(state),
  };
}

function mapActionsToProps(dispatch) {
  return bindActionCreators(
    {
      updateGlobalSettings,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapActionsToProps)(GlobalSettingsView);
