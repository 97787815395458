// @flow
import React from 'react';
import get from 'lodash.get';

import Avatar from 'components/Avatar';
import Button from 'components/Button';
import Card from 'components/Card';
import { Block, Flex } from '/components/Containers';
import List from 'components/List';
import Text from 'components/Text';
import UpdateAddDialog from 'dialogs/ProjectDialogs/UpdateAddDialog';
import type { ProjectReport, Update } from 'store/project/types';
import ProjectUpdate from './components/ProjectUpdate';

type Props = {
  isCurrentWeek: boolean,
  reportDate: string,
  selectedReport: ProjectReport,
  updates: Array<Update>,
  deleteUpdateItem: (update: Update, report: ProjectReport) => void,
  users: any,
  editable: boolean,
};

export default function ProjectUpdates(props: Props) {
  let { updates } = props;
  const {
    isCurrentWeek,
    deleteUpdateItem,
    selectedReport,
    reportDate,
    users,
    editable,
  } = props;

  // if no updates found in update table, look in report content column
  if (updates && updates.length === 0) {
    updates = get(selectedReport, 'content.updates', []);
    updates = updates.map(update => {
      return {
        userId: update.userId,
        summary: update.notes,
        reportId: selectedReport.id,
        type: update.description,
      };
    });
  }

  const [showAddUpdateDialog, setShowAddUpdateDialog] = React.useState(false);

  function renderCardHeader() {
    return (
      <Flex align="center">
        <Flex item xs={5}>
          <Flex gutter={2} align="center">
            <Block flex="none">
              <Text tag="h3" weight={600}>
                Updates
              </Text>
            </Block>
            {editable && (
              <Block flex="none">
                <Button onClick={() => setShowAddUpdateDialog(true)}>
                  Add Updates
                </Button>
              </Block>
            )}
          </Flex>
        </Flex>
        <Flex item xs={3}>
          <Text color="typography-medium-gray">Type</Text>
        </Flex>
        <Flex item xs={2}>
          <Text color="typography-medium-gray">Date</Text>
        </Flex>
        <Flex item xs={1}>
          <Avatar placeholder />
        </Flex>
        <Flex item xs={1} />
      </Flex>
    );
  }

  function renderUpdatesList() {
    return (
      <List
        items={
          updates &&
          updates.map((update, idx) => (
            <ProjectUpdate
              key={idx}
              idx={idx}
              update={update}
              deleteUpdateItem={deleteUpdateItem}
              editable={editable}
              selectedReport={selectedReport}
              reportDate={reportDate}
              users={users}
            />
          ))
        }
      />
    );
  }

  function renderEmptyContent() {
    const emptyMessages = {
      prev: 'No Updates to display',
      current: 'Add Updates to fill this section',
    };
    return (
      <Text align="center" color="typography-medium-gray" weight={600}>
        {isCurrentWeek ? emptyMessages.current : emptyMessages.prev}
      </Text>
    );
  }

  function renderCardContent() {
    return updates && updates.length
      ? renderUpdatesList()
      : renderEmptyContent();
  }

  return (
    <>
      <Card>
        <Card.Header title={renderCardHeader()} />
        <Card.Content>{renderCardContent()}</Card.Content>
      </Card>

      <UpdateAddDialog
        open={showAddUpdateDialog}
        onConfirm={() => setShowAddUpdateDialog(false)}
        onClose={() => setShowAddUpdateDialog(false)}
      />
    </>
  );
}
