// @flow
import React from 'react';

import { Flex } from 'components/Containers';
import Text from 'components/Text';
import type { Source } from 'store/source/types';

type Props = {
  source: Source,
};

export default function UserRow(props: Props) {
  const { source } = props;

  if (!source) return '';

  return (
    <Flex align="center">
      <Flex align="center" item xs={4}>
        <Text size="normal">{source.name}</Text>
      </Flex>
      <Flex item xs={4}>
        <Text size="normal">{source.uri}</Text>
      </Flex>
      <Flex item xs={4}>
        <Text size="normal">{source.type || 'JIRA'}</Text>
      </Flex>
    </Flex>
  );
}
