import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Authentication from './presenter';
import { currentUser } from 'store/user/actions';
import { fetchGlobalSettings } from 'store/globalSettings/actions';
import { getCurrentUser, getGlobalSettings } from 'store/selectors';

function mapStateToProps(state) {
  return {
    user: getCurrentUser(state),
    globalSettings: getGlobalSettings(state),
  };
}

function mapActionCreators(dispatch) {
  return bindActionCreators(
    {
      getCurrentUser: currentUser,
      getGlobalSettings: fetchGlobalSettings,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapActionCreators)(Authentication);
