// @flow
import styled, { css } from 'styled-components';

export const LoaderWrapper = styled.div`
  display: inline-flex;
  position: relative;

  ${props =>
    props.size &&
    css`
      height: ${props.size}px;
      width: ${props.size}px;
    `};
`;
