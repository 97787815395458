import headers from './headers';
import { checkStatus } from './normalizer';
import { URL } from './base';

// Serializing Functions
export const serializeStatuses = data => {
  const payload = Object.assign({}, data);
  return payload;
};

export function editStatuses(statuses, projectId, reportId) {
  const payload = serializeStatuses(statuses);
  return fetch(`${URL}/projects/${projectId}/reports/${reportId}/status`, {
    method: 'PUT',
    headers: headers(),
    body: JSON.stringify(payload),
  }).then(checkStatus);
}
