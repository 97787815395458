import 'whatwg-fetch';
import headers from './headers';
import qs from 'querystring';
import { getAll, getOne, checkStatus } from './normalizer';
import { URL } from './base';

const serializeUser = user => {
  if (!user) return undefined;

  const data = { ...user };
  delete data.id;
  return data;
};

export function getUsers(params) {
  const stringParams = qs.stringify(params);

  return fetch(`${URL}/users${stringParams ? `?${stringParams}` : ''}`, {
    method: 'GET',
    headers: headers(),
  })
    .then(checkStatus)
    .then(getAll);
}

export function updateUser(id, payload) {
  return fetch(`${URL}/users/${id}`, {
    method: 'PUT',
    headers: headers(),
    body: JSON.stringify(serializeUser(payload)),
  })
    .then(checkStatus)
    .then(getOne);
}
