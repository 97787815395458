// @flow
import type { Dispatch } from 'redux';
import { getProjectById } from 'services/project';
import { isDateInCurrentWeek, getMilestoneWeekNo } from 'helpers/formatters/';
import {
  PROJECT_FETCH_START,
  PROJECT_FETCH_ERROR,
  PROJECT_FETCH_END,
  PROJECT_RESET,
} from '../constants';
import { sortReportByTypeAndDate } from '../utils';

export const fetchProject = (projectId: string) => async (
  dispatch: Dispatch
) => {
  dispatch({ type: PROJECT_FETCH_START });

  // Fetch & Prep Project Data
  try {
    const project = await getProjectById(projectId);
    // Prep Report Data
    const reports = project.reports.map((report, idx, arr) => {
      return {
        ...report,
        isCurrentWeek: idx === 0 && isDateInCurrentWeek(report.reportDate),
        isSelected: idx === 0,
        weekNo: arr.length - idx,
        updates: sortReportByTypeAndDate(report.updates, report.reportDate),
      };
    });
    // Prep Milestone Data
    const milestones = project.milestones.map(milestone => {
      return {
        ...milestone,
        displayDate: milestone.actual || milestone.forecast,
        weekNo: getMilestoneWeekNo(milestone, reports, project.totalWeeks),
      };
    });
    // Dispatch Updates
    dispatch({
      type: PROJECT_FETCH_END,
      payload: { ...project, reports, milestones },
    });
  } catch (err) {
    dispatch({
      type: PROJECT_FETCH_ERROR,
      payload: err,
    });
  }
};

export const projectReset = () => (dispatch: Dispatch) => {
  dispatch({ type: PROJECT_RESET });
};
