// @flow
import styled, { css } from 'styled-components';
import { color, spacing, spacingDefaults } from 'styles/constants';

const border = props => {
  switch (typeof props.border) {
    case 'string':
      return css`
        border: ${props.border};
      `;
    case 'object':
      return css`
        border-top: ${props.border.top};
        border-right: ${props.border.right};
        border-bottom: ${props.border.bottom};
        border-left: ${props.border.left};
      `;
    default:
      return null;
  }
};

const bg = props => {
  switch (props.bg) {
    case 'blue':
      return css`
        background: ${color.BLUE};
        color: ${color.WHITE};
      `;
    case 'off-black':
      return css`
        background: ${color.OFF_BLACK};
        color: ${color.WHITE};
      `;
    case 'faux-white':
      return css`
        background: ${color.FAUX_WHITE};
      `;
    case 'white':
      return css`
        background: ${color.WHITE};
      `;
    default:
      return css`
        background: transparent;
      `;
  }
};

const circle = props =>
  props.circle &&
  css`
    border-radius: 100px;
  `;

const shadow = props =>
  props.raised &&
  css`
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  `;

const spacingValues = value => {
  switch (value) {
    case 'auto':
      return 'auto';
    case '0':
      return 0;
    case '1':
      return 'xsmall';
    case '2':
      return 'small';
    case '3':
      return 'normal';
    case '4':
      return 'medium';
    case '5':
      return 'large';
    case '6':
      return 'xlarge';
    case '7':
      return 'xxlarge';
    default:
      return null;
  }
};

const padding = props => {
  if (props.padding) {
    const paddingSpacing = props.padding
      .trim()
      .split(' ')
      .map(value => spacingValues(value.toString()));

    return css`
      padding: ${spacing(...paddingSpacing)};
    `;
  }
  return null;
};

const margin = props => {
  if (props.margin) {
    const marginSpacing = props.margin
      .trim()
      .split(' ')
      .map(value => spacingValues(value.toString()));

    return css`
      margin: ${spacing(...marginSpacing)};
    `;
  }
  return null;
};

const gutterSize = props => {
  return (
    props.gutter && spacingDefaults[spacingValues(props.gutter.toString())]
  );
};

const gutter = props => {
  switch (props.direction) {
    case 'column':
      return css`
        margin-top: -${gutterSize};
        margin-bottom: -${gutterSize};

        > div {
          margin-bottom: ${gutterSize};
          margin-top: ${gutterSize};
        }
      `;
    case 'row':
      return css`
        margin-right: -${gutterSize};
        margin-left: -${gutterSize};

        > div {
          margin-left: ${gutterSize};
          margin-right: ${gutterSize};
        }
      `;
    default:
      return null;
  }
};

const textAlign = props =>
  props.textAlign &&
  css`
    text-align: ${props.textAlign};
  `;

const flex = props =>
  props.flex &&
  css`
    flex: ${props.flex};
  `;

const width = props =>
  props.width &&
  css`
    flex: none;
    width: ${props.width};
  `;

const height = props =>
  props.height &&
  css`
    height: ${props.height};
  `;

const fullWidth = props =>
  props.fullWidth &&
  css`
    min-width: 100%;
  `;

const fullHeight = props =>
  props.fullHeight &&
  css`
    min-height: 100%;
  `;

const borderRadius = props =>
  props.hasBorderRadius &&
  css`
    border-radius: 4px;
  `;

const position = props =>
  props.position &&
  css`
    position: ${props.position};
  `;

const Flex = styled.div`
  && {
    flex: 0 1 auto;
    position: relative;

    ${flex};
    ${gutter};
    ${shadow};
    ${margin};
    ${padding};
    ${bg};
    ${border};
    ${circle};
    ${textAlign};
    ${width};
    ${height};
    ${fullWidth};
    ${fullHeight};
    ${borderRadius};
    ${position};
  }
`;

export default Flex;
