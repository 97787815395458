import React from 'react';
import PropTypes from 'prop-types';
import Activity from '../Activity';
import './style.scss';

const Bottom = props => {
  const width = props.activities.length * 420;
  const animation = `bottomScroll ${width / 50}s linear infinite`;
  const style = {
    width: `${width}px`,
    MozAnimation: animation,
    WebkitAnimation: animation,
    animation,
  };
  return (
    <div className="Bottom" style={style}>
      {props.activities.map((activity, idx) => (
        <Activity {...activity} key={idx} />
      ))}
    </div>
  );
};

Bottom.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.object),
};

export default Bottom;
