// @flow
import React from 'react';
import Text from 'components/Text';
import { Flex } from 'components/Containers';

type Props = {
  letter: string,
  show: boolean,
  size: number,
};

function StatusLetter(props: Props) {
  const { letter, show, size } = props;

  if (!show) return '';

  return (
    <Flex justify="center" align="center" height={`${size}px`}>
      <Text
        size="normal"
        weight={600}
        color="white"
        align="center"
        justify="center"
      >
        {letter}
      </Text>
    </Flex>
  );
}

StatusLetter.defaultProps = {
  show: false,
  size: 26,
};

export default StatusLetter;
