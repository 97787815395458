import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import LinkView from './presenter';
import { getCurrentUser } from 'store/selectors';
import { removeUserAuthentication } from 'store/user/actions';

function mapStateToProps(state) {
  return {
    currentUser: getCurrentUser(state),
  };
}

function mapActionCreators(dispatch) {
  return bindActionCreators(
    {
      removeUserAuthentication,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapActionCreators)(LinkView);
