// @flow
import React from 'react';
import Text from 'components/Text';
import Dialog from 'components/Dialog';
import Input from 'components/Input';
import Select from 'components/Select';
import { Flex, Block } from 'components/Containers';
import { SingleStatus } from 'components/Status/index';
import type { Update, ProjectReport, Action } from 'store/project/types';
import type { User } from 'store/user/types';
import { success, error } from 'utils/alerts';
import { useAlert } from 'hooks/useAlert';
import { NOT_SET, ACCOMPLISHMENT, RISK, ISSUE } from './constants';
type Props = {
  open: boolean,
  currentUser: User,
  selectedReport: ProjectReport,
  addUpdateItem: (item: Update, report: ProjectReport) => void,
  onConfirm: () => void,
  onClose: () => void,
  action: Action,
};

const typeOptions = [
  { value: NOT_SET, status: 'empty' },
  { value: ACCOMPLISHMENT, status: 'good' },
  { value: RISK, status: 'ok' },
  { value: ISSUE, status: 'bad' },
];
const initialValues = { type: NOT_SET, summary: '' };

export function UpdateAddDialog(props: Props) {
  const {
    addUpdateItem,
    currentUser,
    onClose,
    onConfirm,
    open,
    selectedReport,
    action,
  } = props;

  const { createAlert } = useAlert();

  function handleValidation(validationValues) {
    const errors = {};

    if (validationValues.type === NOT_SET) {
      errors.type = true;
    }

    if (!validationValues.summary.trim()) {
      errors.summary = true;
    }

    return errors;
  }

  async function handleSubmit(submitValues) {
    const update = {
      type: submitValues.type,
      summary: submitValues.summary,
      updatedAt: new Date(),
      userId: currentUser.id,
    };

    try {
      await addUpdateItem(update, selectedReport);
      createAlert({
        message: success('update').add,
        type: 'success',
      });
      onConfirm();
    } catch {
      createAlert({
        message: error('update').add,
        type: 'error',
      });
      onConfirm();
    }
  }

  return (
    <Dialog
      isLoading={action.isLoading}
      open={open}
      title="Add Update"
      initialValues={initialValues}
      isInitialValid={false}
      validate={handleValidation}
      onClose={onClose}
      onSubmit={handleSubmit}
      confirmText="Save"
    >
      {(values, handleChange) => (
        <>
          <Block margin="0 0 1">
            <Select
              name="type"
              label="Type*"
              value={values.type}
              onChange={handleChange}
            >
              {typeOptions.map((option, idx) => (
                <Select.Item key={idx} value={option.value}>
                  <Flex align="center">
                    <Block flex="none" margin="0 1 0 0">
                      <SingleStatus status={option.status} size={10} />
                    </Block>
                    {option.value}
                  </Flex>
                </Select.Item>
              ))}
            </Select>
          </Block>
          <Block>
            <Input
              name="summary"
              label="Summary*"
              multiline
              placeholder="Begin typing here"
              fullWidth
              value={values.summary}
              onChange={handleChange}
              maxLength={255}
            />
          </Block>
          <Block>
            <Text size="small" color="typography-medium-gray" weight={600}>
              Required input fields*
            </Text>
          </Block>
        </>
      )}
    </Dialog>
  );
}
