import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { color, spacing, fontSize } from 'styles/constants';
import MenuItem from '@material-ui/core/MenuItem';

const disabledStyles = props =>
  props.disabled &&
  css`
    color: ${color.DISABLED};
    background: transparent;
    cursor: not-allowed;
    pointer-events: none;

    &:hover {
      background: transparent;
    }

    &:active {
      background: transparent;
    }
  `;

const selectedStyles = props =>
  props.selected &&
  css`
    background: ${color.LIGHT_GRAY} !important;
    color: ${color.BLUE};
    cursor: default;
    pointer-events: none;
  `;

export default styled(MenuItem)`
  && {
    display: flex;
    align-items: center;
    height: 40px;
    background: transparent;
    border: none;
    outline: none;
    color: ${color.BLACK};
    font-size: ${fontSize.normal};
    font-weight: 500;
    padding: ${spacing(0, 'large', 0, 'small')};
    text-decoration: none;
    cursor: pointer;
    user-select: none;

    &:hover,
    &:focus {
      background: ${color.LIGHT_GRAY};
    }

    &:active {
      background: ${darken(0.025, color.LIGHT_GRAY)};
    }

    ${disabledStyles};
    ${selectedStyles};
  }
`;
