// @flow
import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import type { Milestone, ProjectReport } from 'store/project/types';
import Status, { MilestoneStatus } from 'components/Status';
import { Flex } from 'components/Containers';
import Text from 'components/Text';
import { color } from 'styles/constants';
import MilestoneViewDialog from 'dialogs/ProjectDialogs/MilestoneViewDialog';
import {
  WeekContainer,
  WeekNumberContainer,
  WeekStatusGroup,
  WeekMilestoneContainer,
  MilestoneIndicators,
  MilestoneIndicator,
} from '../styled';

type Props = {
  report: ProjectReport,
  milestones: Array<Milestone>,
  editable: boolean,
  reportRef: React$Ref,
  tabIndex: number,
  onReportClick: () => void,
  onReportKeyDown: () => void,
  currentWeekNo: number,
};

function TimelineWeek(props: Props) {
  const {
    milestones,
    report,
    editable,
    reportRef,
    tabIndex,
    onReportClick,
    onReportKeyDown,
    currentWeekNo,
  } = props;

  const active = report.isSelected;
  const disabled = currentWeekNo < report.weekNo;
  const [showMilestoneViewDialog, setShowMilestoneViewDialog] = useState(false);
  const [selectedMilestoneIndex, setSelectedMilestoneIndex] = useState(0);

  const viewMilestone = (e, index) => {
    e.stopPropagation();
    setShowMilestoneViewDialog(true);
    setSelectedMilestoneIndex(index);
  };

  const closeViewMilestone = () => setShowMilestoneViewDialog(false);

  const handleReportClick = () => {
    if (disabled) return;
    onReportClick(report);
  };

  const handleReportKeyDown = () => {
    if (disabled) return;
    onReportKeyDown(report);
  };

  return (
    <>
      <WeekContainer
        id={`week-${report.weekNo}`}
        ref={active ? reportRef : undefined}
        tabIndex={!disabled ? tabIndex : 'false'}
        onKeyDown={handleReportKeyDown}
        onClick={handleReportClick}
        onFocus={e => e.preventDefault()}
        active={active}
      >
        <WeekMilestoneContainer>
          {milestones.map(
            (milestone, index) =>
              milestone && (
                <MilestoneStatus
                  key={index}
                  status={milestone.status}
                  isComplete={!!milestone.actual}
                  onClick={e => viewMilestone(e, index)}
                />
              )
          )}
        </WeekMilestoneContainer>
        <WeekNumberContainer onClick={handleReportClick}>
          <Text
            size="xsmall"
            weight={currentWeekNo === report.weekNo || active ? 700 : 300}
            color={active ? color.BLUE : color.TYPOGRAPHY_MEDIUM_GRAY}
            align="center"
          >
            {report.weekNo}
          </Text>
        </WeekNumberContainer>
        <Flex flex="none" width="auto">
          <WeekStatusGroup
            disabled={disabled}
            className="week-status-group"
            id={`week${report.weekNo}`}
          >
            <Status
              vertical
              status={
                !isEmpty(report.statuses)
                  ? report.statuses
                  : report && report.content.statuses
              }
            />
          </WeekStatusGroup>
          {milestones &&
            milestones.map((milestone, i) => (
              <MilestoneIndicators key={i}>
                {milestone && <MilestoneIndicator multiple={!!i} />}
              </MilestoneIndicators>
            ))}
        </Flex>
      </WeekContainer>
      {showMilestoneViewDialog && (
        <MilestoneViewDialog
          open={showMilestoneViewDialog}
          onClose={closeViewMilestone}
          milestone={milestones[selectedMilestoneIndex]}
          editable={editable}
        />
      )}
    </>
  );
}

export default TimelineWeek;
