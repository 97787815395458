import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toggleDisplayPreference } from 'store/user/actions';

import ProjectsView from './presenter';
import { getCurrentUser, getDisplayMode } from 'store/selectors';

function mapStateToProps(state) {
  return {
    user: getCurrentUser(state),
    isCardView: getDisplayMode(state),
  };
}

function mapActionsToProps(dispatch) {
  return bindActionCreators(
    {
      toggleDisplayPreference,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapActionsToProps)(ProjectsView);
