import { connect } from 'react-redux';

import { getProjectData, getProjectEditable } from 'store/selectors';

import ProjectTeam from './presenter';

function mapStateToProps(state) {
  return {
    project: getProjectData(state),
    editable: getProjectEditable(state),
  };
}

export default connect(mapStateToProps, null)(ProjectTeam);
