// @flow
import React from 'react';
import { StatusCircle } from '../styled/StatusCircle';
import { getStatusColor } from '../utils';
type Props = {
  status: string,
  size?: number,
  type: 'project' | 'client' | 'growth',
};

export default function SingleStatus(props: Props) {
  const { status, size, type } = props;

  return (
    <StatusCircle
      alt={`${type}: ${getStatusColor(status)}`}
      status={status}
      data-testid={status}
      size={size}
    />
  );
}
