import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import relativeTime from 'dayjs/plugin/relativeTime';
import {
  addDays,
  eachDay,
  endOfWeek,
  isPast,
  isThursday as deadlineDayOfTheWeek,
  lastDayOfWeek,
  startOfWeek,
} from 'date-fns';

dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(relativeTime);

export const DATE_DISPLAY_FORMAT = 'MMM D, YYYY';

export const getUTCDate = dateString => {
  const date = new Date(dateString);

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};

export const isInRange = (date, start, end) => {
  if (!start || !end || !date) return false;
  return dayjs(date).isBetween(start, end);
};

export const isInProjectRange = (date, start, end) => {
  if (!date || !start || !end) return false;

  const projectWeekStart = startOfWeek(start, { weekStartsOn: 1 });
  const projectWeekEnd = lastDayOfWeek(end, { weekStartsOn: 1 });
  return isInRange(date, projectWeekStart, projectWeekEnd);
};

export const isDateInCurrentWeek = date => {
  if (!date) return false;
  const today = new Date();
  const weekStart = startOfWeek(getUTCDate(date), { weekStartsOn: 1 });
  const weekEnd = lastDayOfWeek(getUTCDate(date), { weekStartsOn: 1 });
  return isInRange(today, weekStart, weekEnd);
};

export const getMilestoneWeekNo = (milestone, totalReports, totalWeeks) => {
  if (!milestone) return null;
  if (!totalReports.length) return null;

  const date = milestone.actual
    ? milestone.actual
    : milestone.forecast || milestone.planned;

  const getFutureReport = (report, weekNo) => {
    const sow = startOfWeek(getUTCDate(report.reportDate), { weekStartsOn: 1 });
    const reportDate = addDays(sow, 7 * (weekNo - report.weekNo));
    return { reportDate, weekNo };
  };

  const milestoneReport = [...Array(totalWeeks)]
    .map(
      (w, idx) =>
        totalReports[totalReports.length - idx - 1] ||
        getFutureReport(totalReports[0], idx + 1)
    )
    .find(report => {
      const weekStart = startOfWeek(getUTCDate(report.reportDate), {
        weekStartsOn: 1,
      });
      const weekEnd = lastDayOfWeek(getUTCDate(report.reportDate), {
        weekStartsOn: 1,
      });
      return isInRange(date, weekStart, weekEnd);
    });
  if (!milestoneReport) {
    return null;
  }
  return milestoneReport.weekNo;
};

export const computeInterval = (start, end, interval = 'days') => {
  const a = dayjs(start).startOf('day');
  const b = dayjs(end).endOf('day');
  const diff = b.diff(a, interval);
  return diff > 0 ? diff : 0;
};

export const formatDateDuration = (value, interval = 'Week') => {
  if (value === 1) {
    return interval;
  }
  return `${interval}s`;
};

export const fromNow = date => dayjs(date).fromNow();

export const formatDate = (date, format) => {
  if (!dayjs(date).isValid()) return '--';
  if (format) return dayjs(date).format(format);
  return dayjs(date).format(DATE_DISPLAY_FORMAT);
};

export const isEowInPast = date =>
  isPast(lastDayOfWeek(date, { weekStartsOn: 1 }));

export const getCurrentDeadline = () => {
  return eachDay(startOfWeek(new Date()), endOfWeek(new Date())).find(day =>
    deadlineDayOfTheWeek(day)
  );
};

export const isPastDeadline = project => {
  const deadline = getCurrentDeadline();
  if (!isPast(deadline)) {
    return false;
  }
  const { reports } = project;
  return (
    reports &&
    reports.length === 1 &&
    reports[0].updatedAt === null &&
    !(isEowInPast(project.end) || project.archive)
  );
};
