// @flow
import React from 'react';
import { Screen, Flex } from 'components/Containers';
import Loader from 'components/Loader';

export default function LoaderView() {
  return (
    <Screen>
      <Flex justify="center" align="center" height="75vh" fullWidth>
        <Loader size={130} />
      </Flex>
    </Screen>
  );
}
