// @flow
import React, { useState, useEffect } from 'react';
import DocumentTitle from 'react-document-title';
import type { ContextRouter } from 'react-router-dom';

import { signIn, signOut } from 'services/auth.js';
import { useAlert } from 'hooks/useAlert';

import { color } from 'styles/constants';
import Button from 'components/Button';
import { Block, Flex } from 'components/Containers';
import Text from 'components/Text';
import Icon from 'components/Icon';
import Card from 'components/Card';

type Props = ContextRouter;

const authAttr = {
  width: 300,
  height: 50,
  longtitle: true,
  theme: 'dark',
};

const dialexa = {
  domain: 'dialexa.com',
  message: 'Must log in with valid Dialexa email',
};

export default function Login(props: Props) {
  const { history } = props;
  const [timeoutId, setTimeoutId] = useState(null);
  const { createAlert } = useAlert();

  function onLogin(googleUser: Object) {
    const hosted = googleUser.getHostedDomain();
    const { domain, message } = dialexa;
    if (hosted !== domain) {
      createAlert({ message, type: 'error' });
      signOut();
    }
    signIn(googleUser.getAuthResponse().id_token)
      .then(() => history.push('/'))
      .catch(err => createAlert({ message: err.message, type: 'error' }));
  }

  function onLoginFail({ error }: Error) {
    const message = `Login failed: ${error}`;
    createAlert({ message, type: 'error' });
  }

  function attachLogin() {
    const auth2 = window.auth2;
    if (auth2) {
      auth2.attachClickHandler('g-signin2', authAttr, onLogin, onLoginFail);
    } else {
      setTimeoutId(setTimeout(attachLogin, 200));
    }
  }

  useEffect(() => {
    attachLogin();
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <DocumentTitle title="Login">
      <Flex justify="center" align="center" height="100vh" maxWidth="1200px">
        <Block flex="none">
          <Card raised>
            <Card.Content>
              <Flex align="center" padding="6 7 6 6">
                <Block flex="none">
                  <Icon
                    name="engage"
                    alt="engage logo"
                    color={color.ENGAGE_BLUE}
                    size={100}
                    viewBox="0 0 35 31"
                  />
                </Block>
                <Block
                  flex="none"
                  height="200px"
                  border={{ right: `2px solid ${color.BORDER_COLOR}` }}
                  margin="0 6 0 7"
                />
                <Flex direction="column" width="auto">
                  <Block flex="none">
                    <Text weight={600} size="xxlarge">
                      ENGAGE
                    </Text>
                  </Block>
                  <Block flex="none" margin="0 0 4">
                    <Text size="medium" color={color.MEDIUM_GRAY}>
                      Project Health Monitoring
                    </Text>
                  </Block>
                  <Block flex="none">
                    <Button size="large" id="g-signin2">
                      Login with Google
                    </Button>
                  </Block>
                </Flex>
              </Flex>
            </Card.Content>
          </Card>
        </Block>
      </Flex>
    </DocumentTitle>
  );
}
