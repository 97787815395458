import styled, { css } from 'styled-components';
import { spacingDefaults } from 'styles/constants';

const gutter = spacingDefaults.xsmall;

const verticalStyles = props =>
  props.vertical &&
  css`
    margin: 0;
    min-width: unset;
    flex-direction: column;

    & > div {
      margin: 0;
    }
  `;

export const StatusGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -${gutter};
  min-width: 60px;
  width: auto;
  flex-direction: row;

  & > div {
    margin: 0 ${gutter};
  }

  ${verticalStyles};
`;
