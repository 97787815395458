import React from 'react';
import PropTypes from 'prop-types';
import Progress from 'components/Progress';
import {
  computeInterval,
  percentage,
  formatDateDuration,
  pluralize,
} from 'helpers/formatters/';
import './style.scss';

export const formatSprintDetails = (start, end, sprintTotal, measurement) => {
  let duration = computeInterval(start, end, 'weeks');
  let durationText = formatDateDuration(duration);

  if (duration === 0) {
    duration = computeInterval(start, end, 'days');
    durationText = formatDateDuration(duration, 'day');
  }

  const measurementText = pluralize(sprintTotal, measurement);

  return `${duration} ${durationText} | ${sprintTotal} ${measurementText}`;
};

const Sprint = props => (
  <div className="Sprint">
    <div className="Sprint-num">{`${props.sprint.name || ''}`}</div>
    <hr />
    <div className="Sprint-details">
      {formatSprintDetails(
        props.sprint.start,
        props.sprint.end,
        props.sprint.total,
        props.measurement
      )}
    </div>
    <Progress
      percentage={percentage(props.sprint.total, props.sprint.completed)}
    />
  </div>
);

Sprint.propTypes = {
  sprint: PropTypes.shape({
    name: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
    total: PropTypes.number,
    completed: PropTypes.number,
  }).isRequired,
  measurement: PropTypes.string.isRequired,
};

export default Sprint;
