import React from 'react';
import PropTypes from 'prop-types';
import { Pill, OpenPill, InProgressPill } from 'components/Pill';
import { fromNow } from 'helpers/formatters/';
import './style.scss';

const pillFill = status => {
  if (status === 'closed') return <Pill light>{status}</Pill>;
  if (status === 'in progress')
    return <InProgressPill>{status}</InProgressPill>;
  // default to open status
  return <OpenPill>{status}</OpenPill>;
};

const Activity = ({ issue, timestamp, status, description, actor }) => {
  return (
    <div className="Activity">
      <div className="Activity-details">
        <div className="Activity-status">
          {pillFill(status)}
          <p className="Activity-issue">{issue}</p>
        </div>
        <div className="Activity-description">{description}</div>
        <div className="Activity-actor">{actor}</div>
      </div>
      <div className="Activity-timestamp">{fromNow(timestamp)}</div>
    </div>
  );
};

Activity.propTypes = {
  issue: PropTypes.string,
  description: PropTypes.string,
  status: PropTypes.string.isRequired,
  actor: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
};

export default Activity;
