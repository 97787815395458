// @flow
import React from 'react';
import StyledScreen from '../styled/Screen';

type Props = {
  width?: string | number,
};

export default function Screen(props: Props) {
  return <StyledScreen {...props} />;
}
