// @flow
import React from 'react';
import {
  SlackContext,
  type SlackProvider as Props,
} from 'providers/SlackProvider';

export type SlackProvider = Props;

export function useSlack() {
  return React.useContext(SlackContext);
}
