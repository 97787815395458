// @flow
import React from 'react';
import Dialog from 'components/Dialog';
import Text from 'components/Text';
import { Block, Flex } from 'components/Containers';
import Button from 'components/Button';

type Props = {
  open: boolean,
  onConfirm: () => void,
};

export function JiraUnlinkDialog(props: Props) {
  const { open, onConfirm } = props;

  return (
    <Dialog
      open={open}
      showFooter={false}
      rightHeader={<></>}
      title="Unlink Jira"
      maxWidth="xs"
    >
      <Block margin="0 0 3 0">
        <Text>Are you sure you would like to unlink Jira?</Text>
      </Block>
      <Flex justify="center">
        <Button appearance="primary" onClick={onConfirm}>
          Unlink Jira
        </Button>
      </Flex>
    </Dialog>
  );
}
