export const STATE_KEY = 'project';
export const PROJECT_FETCH_START = `${STATE_KEY}/FETCH_START`;
export const PROJECT_FETCH_ERROR = `${STATE_KEY}/FETCH_ERROR`;
export const PROJECT_FETCH_END = `${STATE_KEY}/FETCH_END`;
export const PROJECT_SELECT_REPORT = `${STATE_KEY}/SELECT_REPORT`;
export const PROJECT_UPDATE_REPORT = `${STATE_KEY}/UPDATE_REPORT`;
export const PROJECT_UPDATE_MILESTONE = `${STATE_KEY}/UPDATE_MILESTONE`;
export const PROJECT_ADD_MILESTONE = `${STATE_KEY}/ADD_MILESTONE`;

// Mutation Action Types
export const ACTION_START = `${STATE_KEY}/ACTION_START`;
export const ACTION_ERROR = `${STATE_KEY}/ACTION_ERROR`;
export const ACTION_END = `${STATE_KEY}/ACTION_END`;

export const PROJECT_RESET = `${STATE_KEY}/PROJECT_RESET`;

export const PROJECT_INITIAL = {
  project: {
    loading: true,
    error: null,
    data: [],
  },
  action: {
    loading: false,
    error: null,
    resolved: false,
  },
};

export const PROJECT_STATE = (state: State) => state[STATE_KEY];
