// @flow
import type { Dispatch } from 'redux';
import { createUpdate, deleteUpdate, editUpdate } from 'services/updates';
import { createComment, deleteComment, editComment } from 'services/comments';
import { editStatuses } from 'services/statuses';
import type {
  ProjectReport,
  ProjectState,
  Update,
  Comment,
  ReportStatus,
} from 'store/project/types';

import {
  PROJECT_SELECT_REPORT,
  PROJECT_UPDATE_REPORT,
  ACTION_START,
  ACTION_ERROR,
  ACTION_END,
} from '../constants';

import { sortReportByTypeAndDate } from '../utils';

export const selectProjectReport = (index: number) => (
  dispatch: Dispatch,
  getState: ProjectState
) => {
  const { project } = getState().project;

  const totalReports =
    (project.data.reports && project.data.reports.length) || 1;

  const weekIdx = index === -1 ? totalReports - 1 : index;

  const reports = project.data.reports.map((report, idx) => ({
    ...report,
    isSelected: idx === weekIdx,
  }));

  dispatch({
    type: PROJECT_SELECT_REPORT,
    payload: { ...project.data, reports },
  });
};

export const addUpdateItem = (item: Update, report: ProjectReport) => async (
  dispatch: Dispatch,
  getState: ProjectState
) => {
  dispatch({ type: ACTION_START });

  try {
    const result = await createUpdate(item, report.projectId, report.id);
    const { project } = getState().project;
    const oldUpdates = report.updates;
    const newUpdates = sortReportByTypeAndDate([...oldUpdates, ...result]);

    const updatedReport = {
      ...report,
      updates: [...newUpdates],
    };

    const reportIdx = project.data.reports.findIndex(
      r => r.id === updatedReport.id
    );

    const reports = [
      ...project.data.reports.slice(0, reportIdx),
      updatedReport,
      ...project.data.reports.slice(reportIdx + 1),
    ];

    dispatch({
      type: PROJECT_UPDATE_REPORT,
      payload: { ...project.data, reports },
    });
    dispatch({ type: ACTION_END });
  } catch (err) {
    dispatch({ type: ACTION_ERROR, payload: err });
    throw new Error(`Error adding update item - ${err.message}`);
  }
};

export const deleteUpdateItem = (item: Update, report: ProjectReport) => async (
  dispatch: Dispatch,
  getState: ProjectState
) => {
  const { project } = getState().project;
  const oldUpdates = report.updates;
  const deleteIndex = oldUpdates.findIndex(update => {
    return update.id === item.id;
  });

  if (deleteIndex === -1) {
    // Throw an error
    return;
  }

  const updatedReport = {
    ...report,
    updates: [
      ...oldUpdates.slice(0, deleteIndex),
      ...oldUpdates.slice(deleteIndex + 1),
    ],
  };

  const reportIdx = project.data.reports.findIndex(
    r => r.id === updatedReport.id
  );

  const reports = [
    ...project.data.reports.slice(0, reportIdx),
    updatedReport,
    ...project.data.reports.slice(reportIdx + 1),
  ];

  dispatch({ type: ACTION_START });

  try {
    await deleteUpdate(item, report.projectId, report.id);
    dispatch({
      type: PROJECT_UPDATE_REPORT,
      payload: { ...project.data, reports },
    });
    dispatch({ type: ACTION_END });
  } catch (err) {
    dispatch({ type: ACTION_ERROR, payload: err });
    throw new Error(`Error deleting update item - ${err.message}`);
  }
};

export const updateUpdateItem = (item: Update, report: ProjectReport) => async (
  dispatch: Dispatch,
  getState: ProjectState
) => {
  const { project } = getState().project;
  const oldUpdates = report.updates;

  const updateIndex = oldUpdates.findIndex(update => {
    return update.id === item.id;
  });
  if (updateIndex === -1) {
    return;
  }

  const update = await editUpdate(item, report.projectId, report.id);
  const newUpdates = sortReportByTypeAndDate([
    ...oldUpdates.slice(0, updateIndex),
    update,
    ...oldUpdates.slice(updateIndex + 1),
  ]);
  const updatedReport = {
    ...report,
    updates: [...newUpdates],
  };

  const reportIdx = project.data.reports.findIndex(
    r => r.id === updatedReport.id
  );

  const reports = [
    ...project.data.reports.slice(0, reportIdx),
    updatedReport,
    ...project.data.reports.slice(reportIdx + 1),
  ];

  dispatch({ type: ACTION_START });

  try {
    dispatch({
      type: PROJECT_UPDATE_REPORT,
      payload: { ...project.data, reports },
    });
    dispatch({ type: ACTION_END });
  } catch (err) {
    dispatch({ type: ACTION_ERROR, payload: err });
    throw new Error(`Error editing update item - ${err.message}`);
  }
};

export const addCommentItem = (item: Comment, report: ProjectReport) => async (
  dispatch: Dispatch,
  getState: ProjectState
) => {
  dispatch({ type: ACTION_START });

  try {
    const result = await createComment(item, report.projectId, report.id);

    const { project } = getState().project;
    const oldComments = report.comments;
    const newComments = [...oldComments, ...result];

    const updatedReport = {
      ...report,
      comments: [...newComments],
    };

    const reportIdx = project.data.reports.findIndex(
      r => r.id === updatedReport.id
    );

    const reports = [
      ...project.data.reports.slice(0, reportIdx),
      updatedReport,
      ...project.data.reports.slice(reportIdx + 1),
    ];

    dispatch({
      type: PROJECT_UPDATE_REPORT,
      payload: { ...project.data, reports },
    });
    dispatch({ type: ACTION_END });
  } catch (err) {
    dispatch({ type: ACTION_ERROR, payload: err });
    throw new Error(`Error adding comment item - ${err.message}`);
  }
};

export const deleteCommentItem = (
  item: Comment,
  report: ProjectReport
) => async (dispatch: Dispatch, getState: ProjectState) => {
  const { project } = getState().project;
  const oldComments = report.comments;
  const deleteIndex = oldComments.findIndex(comment => {
    return comment.id === item.id;
  });

  if (deleteIndex === -1) {
    // Throw an error
    return;
  }

  const updatedReport = {
    ...report,
    comments: [
      ...oldComments.slice(0, deleteIndex),
      ...oldComments.slice(deleteIndex + 1),
    ],
  };

  const reportIdx = project.data.reports.findIndex(
    r => r.id === updatedReport.id
  );

  const reports = [
    ...project.data.reports.slice(0, reportIdx),
    updatedReport,
    ...project.data.reports.slice(reportIdx + 1),
  ];

  dispatch({ type: ACTION_START });

  try {
    await deleteComment(item, report.projectId, report.id);
    dispatch({
      type: PROJECT_UPDATE_REPORT,
      payload: { ...project.data, reports },
    });
    dispatch({ type: ACTION_END });
  } catch (err) {
    dispatch({ type: ACTION_ERROR, payload: err });
    throw new Error(`Error deleting comment item - ${err.message}`);
  }
};

export const updateCommentItem = (
  item: Comment,
  report: ProjectReport
) => async (dispatch: Dispatch, getState: ProjectState) => {
  const { project } = getState().project;
  const oldComments = report.comments;
  const commentIndex = oldComments.findIndex(comment => {
    return comment.id === item.id;
  });
  if (commentIndex === -1) {
    return;
  }

  const comment = await editComment(item, report.projectId, report.id);
  const newComments = [
    ...oldComments.slice(0, commentIndex),
    comment,
    ...oldComments.slice(commentIndex + 1),
  ];
  const updatedReport = {
    ...report,
    comments: [...newComments],
  };

  const reportIdx = project.data.reports.findIndex(
    r => r.id === updatedReport.id
  );

  const reports = [
    ...project.data.reports.slice(0, reportIdx),
    updatedReport,
    ...project.data.reports.slice(reportIdx + 1),
  ];

  dispatch({ type: ACTION_START });

  try {
    dispatch({
      type: PROJECT_UPDATE_REPORT,
      payload: { ...project.data, reports },
    });
    dispatch({ type: ACTION_END });
  } catch (err) {
    dispatch({ type: ACTION_ERROR, payload: err });
    throw new Error(`Error editing update item - ${err.message}`);
  }
};

export const updateStatuses = (
  item: ReportStatus,
  report: ProjectReport
) => async (dispatch: Dispatch, getState: ProjectState) => {
  const { project } = getState().project;
  const updatedReport = {
    ...report,
    statuses: item,
  };

  const reportIdx = project.data.reports.findIndex(
    r => r.id === updatedReport.id
  );

  const reports = [
    ...project.data.reports.slice(0, reportIdx),
    updatedReport,
    ...project.data.reports.slice(reportIdx + 1),
  ];

  dispatch({ type: ACTION_START });

  try {
    await editStatuses(item, report.projectId, report.id);
    dispatch({
      type: PROJECT_UPDATE_REPORT,
      payload: { ...project.data, reports },
    });
    dispatch({ type: ACTION_END });
  } catch (err) {
    dispatch({ type: ACTION_ERROR, payload: err });
    throw new Error(`Error editing status item - ${err.message}`);
  }
};
