export function truncate(name) {
  if (name.length < 35) {
    return name;
  }
  return `${name.substring(0, 35)}...`;
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export function toCurrencyString(string) {
  return formatter.format(string);
}
