import { combineReducers } from 'redux';

import { reducer as jiraReducer, STATE_KEY as JIRA } from './jira';
import { reducer as sourcesReducer, STATE_KEY as SOURCES } from './source';
import { reducer as userReducer, STATE_KEY as USER } from './user';
import { reducer as projectReducer, STATE_KEY as PROJECT } from './project';
import {
  reducer as globalSettingsReducer,
  STATE_KEY as GLOBAL_SETTINGS,
} from './globalSettings';

export function createRootReducer() {
  return combineReducers({
    [JIRA]: jiraReducer,
    [PROJECT]: projectReducer,
    [SOURCES]: sourcesReducer,
    [USER]: userReducer,
    [GLOBAL_SETTINGS]: globalSettingsReducer,
  });
}
