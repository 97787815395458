// @flow
import styled from 'styled-components';
import { color, fontSize } from 'styles/constants';

export const LogoutButton = styled.button`
  border: none;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: ${fontSize.normal};
  color: ${color.RED};
  line-height: 1.25;
  text-align: center;
  text-transform: none;
  box-shadow: none;
  width: auto;
  max-width: 100%;
  vertical-align: middle;
  white-space: nowrap;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  background: transparent;
  padding: 0;
`;

export const HamburgerButton = styled.button.attrs({
  'aria-label': 'close main manu',
})`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  outline: 0;
  cursor: pointer;
`;
