import styled from 'styled-components';
import { color } from 'styles/constants';

export const StatusCircle = styled.div.attrs(props => ({
  role: 'img',
  'aria-label': props.alt,
}))`
  height: ${props => (props.size ? `${props.size}px` : '15px')};
  width: ${props => (props.size ? `${props.size}px` : '15px')};
  border-radius: 50%;

  background: ${props => {
    if (props.status === 'good') {
      return `${color.GREEN}`;
    }
    if (props.status === 'ok') {
      return `${color.YELLOW}`;
    }
    if (props.status === 'bad') {
      return `${color.RED}`;
    }
    return `${color.MEDIUM_GRAY}`;
  }};
`;
