// @flow
import React from 'react';
import DocumentTitle from 'react-document-title';

import type { Project } from 'store/project/types';
import { Screen, Block } from '/components/Containers';

import ProjectFinanceCard from './components/ProjectFinanceCard/index';

type Props = {
  project: Project,
};

export default function ProjectFinanceView(props: Props) {
  const { project } = props;

  return (
    <DocumentTitle title="Project Finance">
      <Screen width="auto">
        <Block margin="5 0">
          <ProjectFinanceCard project={project} />
        </Block>
      </Screen>
    </DocumentTitle>
  );
}
