/**
 * Helper function that take action handlers and convert them to react usable reducers.
 * This in combination with action handlers allow devs to avoid typical giant reducer switch statements
 */

export const createReducerFromHandlers = (handlers, initialState) => (
  state = initialState,
  action
) => {
  const handler = handlers[action.type];
  if (handlers[action.type]) {
    return handler(state, action);
  }

  return state;
};
