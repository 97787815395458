//  @flow
import styled from 'styled-components';
import { default as MaterialChip } from '@material-ui/core/Chip';
import { appearanceToColor, color, fontSize } from 'styles/constants';

export const StyledChip = styled(MaterialChip)`
  && {
    background-color: ${props => appearanceToColor(props.appearance)};
    color: ${color.WHITE};
    font-size: ${fontSize.normal};
    font-weight: bold;
    line-height: 1.25;
    text-align: center;
  }
`;
