import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getProjectData,
  getProjectReport,
  getAllReports,
  getMilestones,
  getProjectReportWeekNo,
  getProjectTotalWeeks,
} from 'store/selectors';

import { selectProjectReport } from 'store/project/actions';

import ProjectTimeline from './presenter';

function mapStateToProps(state) {
  return {
    project: getProjectData(state),
    reports: getAllReports(state),
    milestones: getMilestones(state),
    selectedReport: getProjectReport(state),
    totalWeeks: getProjectTotalWeeks(state),
    weekNo: getProjectReportWeekNo(state),
  };
}

function mapActionsToProps(dispatch) {
  return bindActionCreators(
    {
      selectProjectReport,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapActionsToProps)(ProjectTimeline);
