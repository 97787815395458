import { formatDate, getUTCDate } from 'helpers/formatters/';

export const defaultProjectStatus = {
  project: {
    status: '',
    comment: '',
  },
  client: {
    status: '',
    comment: '',
  },
  growth: {
    status: '',
    comment: '',
  },
};

export const latestValidReportDate = report => {
  if (report.updatedAt) {
    return formatDate(getUTCDate(report.updatedAt));
  } else if (report.latestValidReportDate) {
    return formatDate(getUTCDate(report.latestValidReportDate));
  }
  return '--';
};

export { default } from './container';
