// @flow
import React from 'react';
import get from 'lodash.get';
import isEmpty from 'lodash/isEmpty';
import Text from 'components/Text';
import Dialog from 'components/Dialog';
import Input from 'components/Input';
import Select from 'components/Select';
import type { ProjectReport, ReportStatus, Action } from 'store/project/types';
import { SingleStatus } from 'components/Status/index';
import Grid from '@material-ui/core/Grid';
import { success, error } from 'utils/alerts';
import { useAlert } from 'hooks/useAlert';
type Props = {
  open: boolean,
  selectedReport: ProjectReport,
  updateStatuses: (item: ReportStatus, report: ProjectReport) => void,
  onConfirm: () => void,
  onClose: () => void,
  action: Action,
};

export function StatusEditDialog(props: Props) {
  const {
    open,
    selectedReport,
    onClose,
    onConfirm,
    updateStatuses,
    action,
  } = props;

  const { createAlert } = useAlert();

  const { weekNo } = selectedReport || {};
  // const statuses = get(selectedReport, 'statuses', selectedReport && selectedReport.content.statuses);
  const statuses = get(
    selectedReport,
    'statuses',
    get(selectedReport, 'content.statuses', {})
  );
  if (isEmpty(statuses)) return '';

  const statusTypes = ['project', 'client', 'growth']; // Order for displaying statuses

  // Creating unique names to use with formik, stored in initialvalues
  const initialComments = [];
  const initialStatuses = [];
  Object.keys(statuses).forEach(statusType => {
    initialComments.push({
      [`${statusType}comment`]: statuses[statusType].comment
        ? statuses[statusType].comment
        : '',
    });
    initialStatuses.push({
      [statusType]: statuses[statusType].status
        ? statuses[statusType].status
        : 'empty',
    });
  });
  const initialValues = Object.assign(
    {},
    ...initialComments,
    ...initialStatuses
  );

  // Status dropdown options
  const options = [
    {
      id: 1,
      label: 'Good',
      value: 'good',
    },
    {
      id: 2,
      label: 'Ok',
      value: 'ok',
    },
    {
      id: 3,
      label: 'Bad',
      value: 'bad',
    },
    {
      id: 4,
      label: 'Not Set',
      value: 'empty',
    },
  ];

  function handleValidation(validationValues) {
    const errors = {};

    // Error if status value is 'empty'
    statusTypes.forEach(status => {
      if (validationValues[status] === 'empty') {
        errors[status] = true;
      }
    });

    return errors;
  }

  async function handleSubmit(submitValues) {
    // Combine status and comments before calling update
    const updates = {};
    statusTypes.forEach(status => {
      updates[status] = {
        status: submitValues[status],
        comment: submitValues[`${status}comment`],
      };
    });

    try {
      await updateStatuses(updates, selectedReport);
      createAlert({
        message: success('status').edit,
        type: 'success',
      });
      onConfirm();
    } catch {
      createAlert({
        message: error('status').edit,
        type: 'error',
      });
      onConfirm();
    }
  }

  return (
    <Dialog
      isLoading={action.isLoading}
      open={open}
      title={`Week ${weekNo} Project Status`}
      initialValues={initialValues}
      isInitialValid={false}
      validate={handleValidation}
      onClose={onClose}
      onSubmit={handleSubmit}
      confirmText="Save"
    >
      {(values, handleChange) => (
        <Grid container direction="row">
          {statusTypes.map((statusType, index) => {
            return (
              <Grid
                container
                direction="column"
                style={{ marginBottom: '1rem' }}
              >
                <Text
                  style={{ marginBottom: '1rem' }}
                  weight={600}
                  size="medium"
                  transform="capitalize"
                >
                  {statusType}
                </Text>
                <Grid container key={index} wrap="nowrap">
                  <Select
                    id="status"
                    visuallyHidden
                    name={statusType}
                    label={statusType}
                    value={values[statusType]}
                    onChange={handleChange}
                  >
                    {options.map((option, idx) => (
                      <Select.Item key={idx} value={option.value}>
                        <Grid container alignItems="center" spacing={8}>
                          <Grid item>
                            <SingleStatus
                              type={statusTypes[index]}
                              status={option.value}
                              size={24}
                            />
                          </Grid>
                          <Text size="normal" color="black">
                            {option.label}
                          </Text>
                        </Grid>
                      </Select.Item>
                    ))}
                  </Select>
                  <Grid
                    container
                    alignItems="center"
                    style={{ marginLeft: '1rem' }}
                  >
                    <Input
                      name={`${statusType}comment`}
                      placeholder="Add Comment here"
                      fullWidth
                      value={values[`${statusType}comment`]}
                      onChange={handleChange}
                      maxLength={255}
                      visuallyHidden
                    />
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      )}
    </Dialog>
  );
}
