import React from 'react';
import PropTypes from 'prop-types';
import { Pill } from 'components/Pill';
import './style.scss';
import MaterialIcon from 'components/MaterialIcon';

const qualityIcon = status => {
  switch (status) {
    case 'good':
      return 'check_circle';
    case 'ok':
      return 'error';
    case 'bad':
      return 'cancel';
    default:
      return 'check_circle';
  }
};

const Title = ({ text, label, style, quality }) => (
  <div className="Title" style={style}>
    <div className="Title-details">
      <p className="Title-text">{text}</p>
      <div className="Title-label">
        <MaterialIcon className={`quality-icon quality-icon--${quality}`}>
          {qualityIcon(quality)}
        </MaterialIcon>
        <Pill className="Title-label-button">{label}</Pill>
      </div>
    </div>
  </div>
);

Title.propTypes = {
  text: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  quality: PropTypes.string.isRequired,
  style: PropTypes.shape({}).isRequired,
};

export default Title;
