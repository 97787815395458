import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getAction,
  getProjectData,
  getMilestones,
  getCurrentUser,
} from 'store/selectors';

import { updateMilestone } from 'store/project/actions';

import ProjectMilestoneListContent from './presenter';

function mapStateToProps(state, props) {
  return {
    action: getAction(state),
    project: getProjectData(state),
    user: getCurrentUser(state),
    milestones: getMilestones(state, props),
  };
}

function mapActionsToProps(dispatch) {
  return bindActionCreators(
    {
      updateMilestone,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ProjectMilestoneListContent);
