export const FETCH_ASSIGNED_START = 'FETCH_ASSIGNED_START';
export const FETCH_ASSIGNED_ERROR = 'FETCH_ASSIGNED_ERROR';
export const FETCH_ASSIGNED_FULFILLED = 'FETCH_ASSIGNED_FULFILLED';

export const FETCH_UNASSIGNED_START = 'FETCH_UNASSIGNED_START';
export const FETCH_UNASSIGNED_ERROR = 'FETCH_UNASSIGNED_ERROR';
export const FETCH_UNASSIGNED_FULFILLED = 'FETCH_UNASSIGNED_FULFILLED';

export const RESET_PROJECT_TEAM = 'RESET_PROJECT_TEAM';

export const ASSIGNED = {
  data: [],
  loading: false,
  error: null,
};

export const UNASSIGNED = {
  data: [],
  loading: false,
  error: null,
};
