// @flow
import { createSelector } from 'reselect';

import type { State } from './reducer';
import { STATE_KEY as USER_KEY } from 'store/user';

const getUserState = state => {
  return state[USER_KEY];
};

export const getCurrentUser = createSelector(
  getUserState,
  (state: State) => state.user
);

export const getDisplayMode = state => {
  return state.user.isCardView;
};
