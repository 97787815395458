import React from 'react';
import PropTypes from 'prop-types';
import Progress from 'components/Progress';
import { percentage, formatLargeCurrency } from 'helpers/formatters/';
import './style.scss';

const Budget = ({ spent, total }) => (
  <div className="Budget">
    <div className="Budget-details">
      ${formatLargeCurrency(spent)}
      <span> Of </span>${formatLargeCurrency(total)}
      <span> Spent </span>
    </div>
    <Progress percentage={percentage(total, spent)} />
  </div>
);

Budget.propTypes = {
  spent: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export default Budget;
