// @flow
import styled from 'styled-components';
import { spacingDefaults } from 'styles/constants';

const gutter = spacingDefaults.small;

export default styled.div`
  display: inline-flex;
  align-items: center;
  margin: 0 -${gutter};
`;

export const ButtonGroupItem = styled.div`
  margin: 0 ${gutter};
`;
