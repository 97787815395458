import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

const TimeElapsed = ({ value }) => (
  <div
    className={classNames({
      'TimeElapsed-remaining': value >= 0,
      'TimeElapsed-overdue': value < 0,
    })}
  >
    <h6 className="TimeElapsed-text">Days</h6>
    <h2 className="TimeElapsed-value">{Math.abs(value)}</h2>
    <h6 className="TimeElapsed-text">{value >= 0 ? 'Remaining' : 'Overdue'}</h6>
  </div>
);

TimeElapsed.propTypes = {
  value: PropTypes.number.isRequired,
};

export default TimeElapsed;
