// @flow
import React from 'react';
import Status from 'components/Status';
import {
  formatDate,
  isEowInPast,
  getUTCDate,
  isPastDeadline,
} from 'helpers/formatters/';
import Chip from 'components/Chip';
import Card from 'components/Card';
import List from 'components/List';
import { Flex } from 'components/Containers';
import Text from 'components/Text';
import type { Project } from 'store/project/types';
import { truncate } from 'utils/format';
import get from 'lodash/get';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import { color } from 'styles/constants';
import {
  defaultProjectStatus,
  latestValidReportDate,
} from 'views/ProjectsView';

type Props = {
  projects: Array<Project>,
  onProjectClick: (projectId: string) => void,
};

export default function ProjectList(props: Props) {
  const { projects, onProjectClick } = props;

  function renderTableHeader() {
    return (
      <Flex align="center">
        <Flex item xs={4}>
          <Text color="typography-medium-gray">Name</Text>
        </Flex>
        <Flex item xs={4}>
          <Text color="typography-medium-gray">Dates</Text>
        </Flex>
        <Flex item xs={2}>
          <Text color="typography-medium-gray">Status</Text>
        </Flex>
        <Flex item xs={2}>
          <Text color="typography-medium-gray">Report Last Updated</Text>
        </Flex>
      </Flex>
    );
  }

  return (
    <Card>
      <Card.Header title={renderTableHeader()} />
      <Card.Content>
        <List
          items={projects.map((project, idx) => (
            <Flex
              key={idx}
              align="center"
              style={{ cursor: 'pointer', minHeight: 32 }}
              onClick={() => onProjectClick(project.id)}
            >
              <Flex item xs={4}>
                <Text size="normal">
                  {truncate(`${project.clientName} - ${project.name}`)}
                </Text>
              </Flex>
              <Flex item xs={4}>
                <Text size="normal">
                  {formatDate(getUTCDate(project.start))} -{' '}
                  {formatDate(getUTCDate(project.end))}
                </Text>
              </Flex>
              <Flex item xs={2}>
                {isEowInPast(project.end) || project.archive ? (
                  <Chip label="ENDED" />
                ) : (
                  <Status
                    status={
                      !isEmpty(project.statuses)
                        ? project.statuses
                        : get(
                            project,
                            'reports[0].content.statuses',
                            defaultProjectStatus
                          )
                    }
                  />
                )}
              </Flex>
              <Flex item xs={2}>
                {project.reports.length === 1 ? (
                  <Text
                    size="normal"
                    color={isPastDeadline(project) ? color.RED : color.BLACK}
                    weight={isPastDeadline(project) ? 1000 : 0}
                  >
                    {latestValidReportDate(head(project.reports))}
                  </Text>
                ) : (
                  ''
                )}
              </Flex>
            </Flex>
          ))}
        />
      </Card.Content>
    </Card>
  );
}
