// @flow
import React from 'react';
import paths from '../paths';
import { Svg } from '../styled';
import { color as colors } from 'styles/constants';

export type Props = {
  size: number,
  color: string,
  name: string,
  viewBox: string,
  alt?: string,
};

function Icon(props: Props) {
  const { size, color, name, viewBox, alt } = props;
  return (
    <Svg
      color={color}
      width={`${size}px`}
      height={`${size}px`}
      viewBox={viewBox}
    >
      <title>{alt}</title>
      {!!name &&
        paths[name].map((path, idx) => (
          <path d={path} key={`${name}-${idx}`} />
        ))}
    </Svg>
  );
}

Icon.defaultProps = {
  color: colors.MEDIUM_GRAY,
  size: 24,
  viewBox: '0 0 24 24',
};

export default Icon;
