import React from 'react';
import DocumentTitle from 'react-document-title';
import { type ContextRouter } from 'react-router-dom';
import Button from 'components/Button';
import { Block, Flex } from 'components/Containers';
import Card from 'components/Card';

type Props = ContextRouter;

export default function NotFoundView(props: Props) {
  const { history } = props;
  return (
    <DocumentTitle title="Not Found">
      <Flex justify="center" align="center" height="100vh" maxWidth="1200px">
        <Block flex="none">
          <Card raised>
            <Card.Content>
              <Flex direction="column" align="center" padding="6">
                <svg
                  id="Layer_1"
                  data-name="Layer 1"
                  height="400px"
                  width="400px"
                  viewBox="0 0 315.35 305.59"
                >
                  <title>not found</title>
                  <defs>
                    <linearGradient
                      id="linear-gradient"
                      x1="156.72"
                      y1="259"
                      x2="159.63"
                      y2="341.54"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopOpacity="0" />
                      <stop offset="0.95" />
                    </linearGradient>
                    <linearGradient
                      id="linear-gradient-2"
                      x1="67.7"
                      y1="231.63"
                      x2="64.79"
                      y2="116.55"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopOpacity="0" />
                      <stop offset="0.99" />
                    </linearGradient>
                    <linearGradient
                      id="linear-gradient-3"
                      x1="256"
                      y1="215.77"
                      x2="254.06"
                      y2="143.58"
                      xlinkHref="#linear-gradient"
                    />
                    <linearGradient
                      id="linear-gradient-4"
                      x1="154.93"
                      y1="238.45"
                      x2="159.46"
                      y2="81.77"
                      xlinkHref="#linear-gradient-2"
                    />
                  </defs>
                  <title>404 not found 2</title>
                  <path
                    d="M154.3,205.44c20.68,9.09,44.07,8.95,66.66,8.65,11.85-.15,24.11-.39,34.9-5.3,15-6.83,24.74-21.49,37.9-31.41,15.11-11.41,34.45-16.33,49.1-28.32a63.39,63.39,0,0,0,1.63-96.76c-7.75-6.78-17-11.57-26.17-16.28l-46.05-23.7c-10.79-5.55-21.86-11.19-33.9-12.7C228.21-1.65,217.93.11,208,2.38,179.7,8.78,151.62,19.81,130.86,40,113,57.4,101.34,80.77,81.8,96.26c-5,3.95-10.54,7.44-14.18,12.66-13.1,18.81.17,42.84,17.19,52.48C108.38,174.74,129.59,194.59,154.3,205.44Z"
                    transform="translate(-58.19 0.8)"
                    fill="#4e98ea"
                    opacity="0.18"
                    // style="isolation:isolate"
                  />
                  <ellipse
                    cx="157.68"
                    cy="286.11"
                    rx="157.68"
                    ry="19.48"
                    fill="#4e98ea"
                  />
                  <ellipse
                    cx="157.68"
                    cy="286.11"
                    rx="157.68"
                    ry="19.48"
                    fill="url(#linear-gradient)"
                  />
                  <path
                    d="M129.49,95A26.4,26.4,0,0,0,109.31,91a7.65,7.65,0,0,0-3.48,1.49c-2.37,2-2,5.72-2.63,8.78-.77,3.77-3.3,7-4.21,10.72a24.15,24.15,0,0,0-.09,8.48,139.76,139.76,0,0,0,7.91,32.42l-11,144.91s11.25,1.82,34.07,3.64l2.42-138Z"
                    transform="translate(-58.19 0.8)"
                    fill="#eea886"
                  />
                  <polygon
                    points="58.79 180.31 73.36 239.23 73.36 154.25 66.88 134.99 58.79 180.31"
                    fill="url(#linear-gradient-2)"
                  />
                  <path
                    d="M307.51,96.24a26.42,26.42,0,0,1,20.18-3.92,7.8,7.8,0,0,1,3.49,1.49c2.36,2,2,5.72,2.62,8.79.78,3.77,3.31,7,4.22,10.72a23.86,23.86,0,0,1,.08,8.47,139.12,139.12,0,0,1-7.9,32.42L341.15,297.1s-12.25,2.06-34.14,4.13l-2.35-136.48Z"
                    transform="translate(-58.19 0.8)"
                    fill="#eea886"
                  />
                  <polygon
                    points="263.7 176.1 258.53 202.65 247.52 226.6 246.55 174.48 263.7 176.1"
                    fill="url(#linear-gradient-3)"
                  />
                  <rect
                    x="52.64"
                    y="48.4"
                    width="211.71"
                    height="133.37"
                    rx="8.37"
                    fill="#4e98ea"
                  />
                  <rect
                    x="52.64"
                    y="48.4"
                    width="211.71"
                    height="133.37"
                    rx="8.37"
                    fill="url(#linear-gradient-4)"
                  />
                  <rect
                    x="52.64"
                    y="43.05"
                    width="211.71"
                    height="133.37"
                    rx="8.37"
                    fill="#4e98ea"
                  />
                  <path
                    d="M129.58,97.85c3.37-3.74,3.37-3.74,7.18-8.22l16.23-19a75.55,75.55,0,0,0,7-8.9h15.33a109.89,109.89,0,0,0-.59,12.41V98.45h2.17a52,52,0,0,0,7.4-.6v13.62a59.12,59.12,0,0,0-7.33-.45h-2.24v3.74a53.39,53.39,0,0,0,.59,9.2h-15.4a66.1,66.1,0,0,0,.59-9.35V111H139.31c-4.87,0-7,.07-9.73.29Zm31-7.92c0-3.37.15-7.48.45-10.77-1.5,2.09-2.62,3.59-4.64,6.13L145.44,98.6h15.11Z"
                    transform="translate(-58.19 0.8)"
                    fill="#fff"
                  />
                  <path
                    d="M196.67,118.35a23.66,23.66,0,0,1-5.46-8.3,49.74,49.74,0,0,1-2.92-17.13c0-7.86,1.87-16,4.72-20.72,4.56-7.63,12.34-11.82,21.76-11.82,7.26,0,13.62,2.47,18.18,7a23.53,23.53,0,0,1,5.46,8.3,49.62,49.62,0,0,1,2.92,17.2c0,7.86-1.87,16.09-4.71,20.8-4.49,7.48-12.35,11.67-21.85,11.67C207.44,125.31,201.24,122.91,196.67,118.35Zm7-26c0,13.39,3.81,20.27,11.22,20.27s11.07-6.73,11.07-20C225.92,79.75,222,73,214.77,73,207.74,73,203.63,80.13,203.63,92.32Z"
                    transform="translate(-58.19 0.8)"
                    fill="#fff"
                  />
                  <path
                    d="M245.21,97.85c3.37-3.74,3.37-3.74,7.18-8.22l16.24-19a75.28,75.28,0,0,0,6.95-8.9h15.34a107.51,107.51,0,0,0-.6,12.41V98.45h2.17a52,52,0,0,0,7.4-.6v13.62a59.12,59.12,0,0,0-7.33-.45h-2.24v3.74a52.27,52.27,0,0,0,.6,9.2H275.51a65.93,65.93,0,0,0,.6-9.35V111H254.94c-4.86,0-7,.07-9.73.29Zm31-7.92c0-3.37.15-7.48.45-10.77-1.5,2.09-2.62,3.59-4.64,6.13L261.07,98.6h15.11Z"
                    transform="translate(-58.19 0.8)"
                    fill="#fff"
                  />
                  <path
                    d="M130.3,145.87a22.9,22.9,0,0,1,3.81-.3,6.33,6.33,0,0,1,4.32,1.28,4.16,4.16,0,0,1,1.35,3.22,4.51,4.51,0,0,1-1.19,3.29,6.37,6.37,0,0,1-4.71,1.69,6.44,6.44,0,0,1-1.59-.14v6.17h-2Zm2,7.42a6.35,6.35,0,0,0,1.64.16c2.4,0,3.86-1.16,3.86-3.29s-1.44-3-3.63-3a8.36,8.36,0,0,0-1.87.16Z"
                    transform="translate(-58.19 0.8)"
                    fill="#fff"
                  />
                  <path
                    d="M143,156.24l-1.6,4.84h-2l5.22-15.39H147l5.25,15.39h-2.12l-1.64-4.84Zm5-1.55-1.51-4.43c-.34-1-.57-1.92-.8-2.81h0c-.22.91-.48,1.85-.77,2.78l-1.51,4.46Z"
                    transform="translate(-58.19 0.8)"
                    fill="#fff"
                  />
                  <path
                    d="M165.88,160.39a14.31,14.31,0,0,1-4.73.85,7.23,7.23,0,0,1-7.9-7.77c0-4.59,3.17-7.94,8.33-7.94a9.48,9.48,0,0,1,3.84.71l-.48,1.62a7.9,7.9,0,0,0-3.4-.66c-3.75,0-6.19,2.33-6.19,6.18s2.35,6.22,5.94,6.22a6.44,6.44,0,0,0,2.65-.42V154.6h-3.13V153h5.07Z"
                    transform="translate(-58.19 0.8)"
                    fill="#fff"
                  />
                  <path
                    d="M176.88,153.86h-6v5.55h6.67v1.67h-8.66V145.69h8.32v1.67H170.9v4.86h6Z"
                    transform="translate(-58.19 0.8)"
                    fill="#fff"
                  />
                  <path
                    d="M185,161.08V145.69h2.17l4.93,7.78a45.68,45.68,0,0,1,2.77,5l0,0c-.18-2-.23-3.92-.23-6.32v-6.44h1.88v15.39h-2l-4.89-7.81a49.59,49.59,0,0,1-2.88-5.14l-.07,0c.12,1.95.16,3.8.16,6.35v6.58Z"
                    transform="translate(-58.19 0.8)"
                    fill="#fff"
                  />
                  <path
                    d="M213.17,153.22c0,5.3-3.22,8.11-7.15,8.11s-6.92-3.15-6.92-7.81c0-4.89,3-8.08,7.15-8.08S213.17,148.66,213.17,153.22Zm-11.94.25c0,3.29,1.78,6.24,4.91,6.24s4.93-2.9,4.93-6.4c0-3.05-1.6-6.25-4.91-6.25S201.23,150.1,201.23,153.47Z"
                    transform="translate(-58.19 0.8)"
                    fill="#fff"
                  />
                  <path
                    d="M218.1,147.38h-4.68v-1.69h11.4v1.69h-4.71v13.7h-2Z"
                    transform="translate(-58.19 0.8)"
                    fill="#fff"
                  />
                  <path
                    d="M231.37,145.69h8.29v1.67h-6.3v5.11h5.82v1.64h-5.82v7h-2Z"
                    transform="translate(-58.19 0.8)"
                    fill="#fff"
                  />
                  <path
                    d="M255.64,153.22c0,5.3-3.22,8.11-7.14,8.11-4.07,0-6.92-3.15-6.92-7.81,0-4.89,3-8.08,7.14-8.08S255.64,148.66,255.64,153.22Zm-11.94.25c0,3.29,1.78,6.24,4.91,6.24s4.93-2.9,4.93-6.4c0-3.05-1.6-6.25-4.91-6.25S243.7,150.1,243.7,153.47Z"
                    transform="translate(-58.19 0.8)"
                    fill="#fff"
                  />
                  <path
                    d="M260.21,145.69v9.11c0,3.45,1.53,4.91,3.59,4.91,2.28,0,3.74-1.51,3.74-4.91v-9.11h2v9c0,4.73-2.49,6.67-5.82,6.67-3.15,0-5.53-1.8-5.53-6.58v-9.06Z"
                    transform="translate(-58.19 0.8)"
                    fill="#fff"
                  />
                  <path
                    d="M273,161.08V145.69h2.17l4.93,7.78a43.88,43.88,0,0,1,2.76,5l.05,0c-.18-2-.23-3.92-.23-6.32v-6.44h1.87v15.39h-2l-4.88-7.81a47.8,47.8,0,0,1-2.88-5.14l-.07,0c.11,1.95.16,3.8.16,6.35v6.58Z"
                    transform="translate(-58.19 0.8)"
                    fill="#fff"
                  />
                  <path
                    d="M288,145.89a28.85,28.85,0,0,1,4.23-.32c2.85,0,4.89.67,6.23,1.92a7.1,7.1,0,0,1,2.17,5.53,8.12,8.12,0,0,1-2.21,6c-1.44,1.44-3.82,2.22-6.81,2.22A30.75,30.75,0,0,1,288,161Zm2,13.61a11.5,11.5,0,0,0,2,.12c4.25,0,6.55-2.38,6.55-6.53,0-3.63-2-5.94-6.23-5.94a10.79,10.79,0,0,0-2.33.21Z"
                    transform="translate(-58.19 0.8)"
                    fill="#fff"
                  />
                  <path
                    d="M119.8,93.25a5.48,5.48,0,0,1,2.12,1.87,6.69,6.69,0,0,1,.63,3l.3,6.45a6.53,6.53,0,0,1-.37,3.14,2.27,2.27,0,0,1-2.58,1.36,9.69,9.69,0,0,1-.83,7.59,6.06,6.06,0,0,0-.75,1.56,5.79,5.79,0,0,0,.1,2,11.49,11.49,0,0,1-2.67,9.09,4.07,4.07,0,0,1-1.18,1,5,5,0,0,1-1.88.4l-2.94.25a1,1,0,0,1-1.31-.89,65.62,65.62,0,0,1-3-21.91,63,63,0,0,1,1.34-11.12c.47-2.14,1.39-5.4,3.68-6.27C113.42,89.73,117.24,91.78,119.8,93.25Z"
                    transform="translate(-58.19 0.8)"
                    fill="#eea886"
                  />
                  <path
                    d="M312.92,93.25a5.48,5.48,0,0,0-2.12,1.87,6.69,6.69,0,0,0-.63,3l-.3,6.45a6.53,6.53,0,0,0,.37,3.14,2.27,2.27,0,0,0,2.58,1.36,9.73,9.73,0,0,0,.82,7.59,5.78,5.78,0,0,1,.76,1.56,5.57,5.57,0,0,1-.11,2,11.54,11.54,0,0,0,2.68,9.09,4.07,4.07,0,0,0,1.18,1,5,5,0,0,0,1.88.4L323,131a1,1,0,0,0,1.31-.89,65.62,65.62,0,0,0,3-21.91,62.11,62.11,0,0,0-1.35-11.12c-.46-2.14-1-4.82-3.27-5.69C319.7,90.3,315.48,91.78,312.92,93.25Z"
                    transform="translate(-58.19 0.8)"
                    fill="#eea886"
                  />
                </svg>
                <Block margin="7 0 0" flex="none">
                  <Button size="large" onClick={() => history.push('/')}>
                    Go Home
                  </Button>
                </Block>
              </Flex>
            </Card.Content>
          </Card>
        </Block>
      </Flex>
    </DocumentTitle>
  );
}
