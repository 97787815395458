import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getProjectReport, getAction } from 'store/selectors';
import { updateStatuses } from 'store/project/actions';
import { StatusEditDialog } from './presenter';

function mapStateToProps(state) {
  return {
    action: getAction(state),
    selectedReport: getProjectReport(state),
  };
}

function mapActionsToProps(dispatch) {
  return bindActionCreators(
    {
      updateStatuses,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapActionsToProps)(StatusEditDialog);
