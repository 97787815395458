// @flow
/* eslint-disable */
import React, { useEffect, Component } from 'react';
import { type ContextRouter, withRouter } from 'react-router-dom';

import { getSources } from 'services/source';
import Card from 'components/Card';
import DocumentTitle from 'react-document-title';
import { Screen, Block, Flex } from 'components/Containers';
import List from 'components/List';
import Text from 'components/Text';
import Header from 'components/Header';
import { Table, Theader, Col, Row } from 'components/Table';
import LoaderView from 'views/LoaderView';
import { isAdmin } from 'utils/auth';
import type { Source } from 'store/source/types';
import type { User } from 'store/user/types';
import SourceRow from './components/SourceRow';

type Props = {
  ...ContextRouter,
  currentUser: User,
  isLoading: boolean,
  sources: Array<Source>,
  fetchSources: () => void,
};

function Sources(props: Props) {
  const { currentUser, history, isLoading, sources, fetchSources } = props;

  if (!sources) fetchSources();

  function renderTableHeader() {
    return (
      <Flex align="center">
        <Flex align="center" item xs={4}>
          <Text color="typography-medium-gray">Name</Text>
        </Flex>
        <Flex item xs={4}>
          <Text color="typography-medium-gray">Url</Text>
        </Flex>
        <Flex item xs={4}>
          <Text color="typography-medium-gray">Type</Text>
        </Flex>
      </Flex>
    );
  }

  function renderTableContent() {
    return (
      <List
        items={
          sources &&
          sources.map((source, idx) => <SourceRow key={idx} source={source} />)
        }
      />
    );
  }

  function renderView() {
    if (isLoading) return <LoaderView />;

    return (
      <>
        <Header admin={isAdmin(currentUser)}>
          <Header.Title>Sources</Header.Title>
        </Header>
        <Block>
          <Screen>
            <Flex margin="3 0">
              <Card>
                <Card.Header title={renderTableHeader()} />
                <Card.Content>{renderTableContent()}</Card.Content>
              </Card>
            </Flex>
          </Screen>
        </Block>
      </>
    );
  }

  return (
    <DocumentTitle title="Sources">
      <Block>{renderView()}</Block>
    </DocumentTitle>
  );
}

export default withRouter(Sources);
