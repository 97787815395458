import styled, { css } from 'styled-components';
import { darken, lighten } from 'polished';
import { color, fontSize, spacingDefaults } from 'styles/constants';

const spacing = spacingDefaults.small;

const raised = css`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  }
`;

const displaySwitchTexts = props =>
  props.displaySwitchTexts &&
  css`
    & > span {
      z-index: 5;
    }
  `;

export default styled.label`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
  height: 32px;
  min-width: 64px;
  background: ${lighten(0.025, color.LIGHT_BLUE)};
  font-size: ${fontSize.small};
  font-weight: 500;
  color: ${color.BLUE};
  border-radius: 4px;
  transition: all 300ms ease-in-out;

  ${displaySwitchTexts};
  ${raised};

  &:hover {
    color: ${darken(0.1, color.BLUE)};
  }

  &[disabled] {
    cursor: default;
    background: ${lighten(0.025, color.PALE_GRAY)};
    color: color.GRAY;
  }

  &:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: calc(50% - 5px);
    height: 22px;
    background: ${color.WHITE};
    border-radius: 4px;
    transition: all 300ms ease-in-out;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  }

  &:active:after {
    width: 70px;
  }

  & > span {
    position: relative;
    margin-left: ${spacing};
    margin-right: ${spacing};
  }
`;
