import headers from './headers';
import { getData, checkStatus } from './normalizer';
import { URL } from './base';

// Serializing Functions
const getNonEmptyComments = comments => {
  if (!comments) return [];
  return comments.filter(
    comment => comment.userId !== '' && comment.summary !== ''
  );
};

export const serializeReport = data => {
  const payload = Object.assign({}, data);
  payload.comments = getNonEmptyComments(payload.comments);

  delete payload.milestones;
  delete payload.resources;
  delete payload.isCurrentWeek;
  delete payload.isSelected;
  delete payload.weekNo;
  delete payload.createdAt;
  delete payload.updatedAt;
  delete payload.project;
  delete payload.projectId;
  delete payload.id;

  return payload;
};

// API Functions
export function getAllReportsForProject(id) {
  return fetch(`${URL}/projects/${id}/reports`, {
    method: 'GET',
    headers: headers(),
  })
    .then(checkStatus)
    .then(getData);
}

export function updateReportById(report, projId, repId) {
  const payload = serializeReport(report);
  return fetch(`${URL}/projects/${projId}/reports/${repId}`, {
    method: 'PUT',
    headers: headers(),
    body: JSON.stringify(payload),
  }).then(checkStatus);
}
