// @flow
import styled, { css } from 'styled-components';
import { color } from 'styles/constants';

const shadow = props =>
  props.raised
    ? css`
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      `
    : css`
        box-shadow: none;
      `;

export default styled.div`
  border-radius: 4px;
  background: ${color.WHITE};
  color: ${color.BLACK};
  width: 100%;

  ${shadow};
`;
