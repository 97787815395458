import React from 'react';
import StyledList from '../styled/List';
import StyledListItem from '../styled/ListItem';

type Props = {
  items: Array<any>,
};

function List(props: Props) {
  const { items } = props;
  return (
    <StyledList>
      {items.map((item, idx) => (
        <StyledListItem key={idx}>{item}</StyledListItem>
      ))}
    </StyledList>
  );
}

List.defaultProps = {
  items: [],
};

export default List;
