import PropTypes from 'prop-types';
import React from 'react';
import Counter from 'components/Counter';
import { pluralize } from 'helpers/formatters/';
import './style.scss';

const formatSprintName = name => {
  const match = name.match(/(Sprint|sprint) \b(\d+)/);
  return match && match.length ? match[2] : '';
};

const UpcomingSprints = props => {
  return (
    <div className="UpcomingSprints">
      <div className="UpcomingSprints-title">
        <div className="UpcomingSprints-num">Upcoming Sprints</div>
        <hr />
      </div>
      <div className="UpcomingSprints-details">
        {props.sprints.map((sprint, idx) => (
          <div key={idx}>
            <div className="UpcomingSprints-group-title">
              {`Sprint ${formatSprintName(sprint.name)}`}
            </div>
            {sprint.total > 0 ? (
              <Counter
                index={idx}
                type="success"
                count={sprint.total}
                desc={pluralize(sprint.total, props.measurement)}
              />
            ) : (
              <Counter type="noData" desc="Not Planned" />
            )}
          </div>
        ))}
        <div>
          <div className="UpcomingSprints-group-title">Backlog</div>
          <Counter
            type="default"
            desc={pluralize(props.backlog.issues, 'issues')}
            count={props.backlog.issues}
          />
        </div>
      </div>
    </div>
  );
};

UpcomingSprints.propTypes = {
  sprints: PropTypes.arrayOf({
    name: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
  backlog: PropTypes.shape({ issues: PropTypes.number }).isRequired,
  measurement: PropTypes.string,
};

export default UpcomingSprints;
