import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Dashboard from './presenter';

import { actions as jiraActions, STATE_KEY as JIRA_KEY } from 'store/jira';

function mapStateToProps(state) {
  return {
    jira: state[JIRA_KEY],
  };
}

function mapActionCreators(dispatch) {
  return bindActionCreators(
    {
      checkAuth: jiraActions.checkAuth,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapActionCreators)(Dashboard);
