import 'whatwg-fetch';
import headers from './headers';
import { checkStatus } from './normalizer';
import { URL } from './base';

export function deleteCredentialsByUserId(id) {
  return fetch(`${URL}/credentials/userId/${id}`, {
    method: 'DELETE',
    headers: headers(),
  }).then(checkStatus);
}
