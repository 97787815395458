export const percentage = (total, ratio) =>
  total === 0 ? 0 : (ratio / total) * 100;

export const formatLargeCurrency = amount => {
  let scalingFactor = 1.0;
  let units = '';

  if (amount < 1e6) {
    scalingFactor = 1e-3;
    units = 'K';
  } else if (amount < 1e9) {
    scalingFactor = 1e-6;
    units = 'M';
  } else {
    scalingFactor = 1e-9;
    units = 'B';
  }

  const scaledValue = amount * scalingFactor;
  return `${parseFloat(scaledValue.toFixed(1))}${units}`;
};
