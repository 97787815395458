import { connect } from 'react-redux';

import { getProjectData } from 'store/selectors';
import MilestoneHistory from './presenter';

function mapStateToProps(state) {
  return {
    project: getProjectData(state),
  };
}

export default connect(mapStateToProps, null)(MilestoneHistory);
