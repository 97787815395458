// @flow
import { createSelector } from 'reselect';

import type { State } from './reducer';
import { STATE_KEY as SOURCES_KEY } from 'store/source';

const getSourcesState = state => state[SOURCES_KEY];

/* Selectors */
const isLoading = (state: State) => state.isLoading;
const data = (state: State) => state.data;

export const getSourcesLoading = createSelector(getSourcesState, isLoading);

export const getSources = createSelector(getSourcesState, data);
