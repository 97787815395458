// @flow
import React, { useEffect } from 'react';

import type { Project } from 'store/project/types';
import { useProjectTeam } from 'hooks/useProjectTeam';
import Card from 'components/Card';

import ProjectTeamUnassignedHeader from './ProjectTeamUnassignedHeader';
import ProjectTeamUnassignedContent from './ProjectTeamUnassignedContent';

type Props = {
  project: Project,
};

export default function ProjectTeamUnassigned(props: Props) {
  const { project } = props;
  const { unassigned, fetchUnassigned } = useProjectTeam();
  const loading = unassigned.loading;
  const noData = unassigned.data && !unassigned.data.length;

  useEffect(() => {
    fetchUnassigned(project.tenKId);
  }, []);

  if (loading || noData) return null;

  return (
    <Card>
      <ProjectTeamUnassignedHeader tenKId={project.tenKId} />
      <ProjectTeamUnassignedContent unassigned={unassigned} />
    </Card>
  );
}
