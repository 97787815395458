import headers from './headers';
import { checkStatus, getData, getOne } from './normalizer';
import { URL } from './base';

// Serializing Functions
export const serializeUpdate = data => {
  const payload = Object.assign({}, data);
  delete payload.id;
  delete payload.updatedAt;
  delete payload.userId;
  delete payload.reportId;
  delete payload.createdAt;
  return payload;
};

// API Functions
export function createUpdate(update, projectId, reportId) {
  const payload = serializeUpdate(update);
  return fetch(`${URL}/projects/${projectId}/reports/${reportId}/updates`, {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify(payload),
  })
    .then(checkStatus)
    .then(getData);
}

export function deleteUpdate(update, projectId, reportId) {
  return fetch(
    `${URL}/projects/${projectId}/reports/${reportId}/updates/${update.id}`,
    {
      method: 'DELETE',
      headers: headers(),
    }
  ).then(checkStatus);
}

export function editUpdate(update, projectId, reportId) {
  const payload = serializeUpdate(update);
  return fetch(
    `${URL}/projects/${projectId}/reports/${reportId}/updates/${update.id}`,
    {
      method: 'PUT',
      headers: headers(),
      body: JSON.stringify(payload),
    }
  )
    .then(checkStatus)
    .then(getOne);
}
