// @flow
import React from 'react';
import { format } from 'date-fns';

import Text from 'components/Text';
import Avatar from 'components/Avatar';
import Card from 'components/Card';
import Chip from 'components/Chip';
import List from 'components/List';
import Loader from 'components/Loader';
import { Block, Flex } from '/components/Containers';
import type {
  Assigned,
  AssignedMember,
} from 'providers/ProjectTeamProvider/types';

type Props = {
  assigned: Assigned,
};

const formatDate = (date: string) => format(date, 'MMMM DD, YYYY');
const formatAllocation = (data: AssignedMember) => {
  const mode = data.allocationMode;
  if (mode === 'percent') return `${data.percent}%`;
  if (mode === 'fixed') return `${data.fixed} hrs`;
  if (mode === 'hours_per_day') return `${data.hoursPerDay} hrs`;
  return 'Vacation';
};

export default function ProjectTeamAssignedContent(props: Props) {
  const { loading, error, data } = props.assigned;

  function renderRollOff(endDate: string, weeks: number) {
    const date = formatDate(endDate);

    if (weeks === 2) return <Chip appearance="warning" label={date} />;
    if (weeks < 2) return <Chip appearance="error" label={date} />;
    return (
      <Block padding="0 2">
        <Text size="normal">{date}</Text>
      </Block>
    );
  }

  if (loading) {
    return (
      <Card.Content>
        <Flex fullHeight padding="5 0" align="center" justify="center">
          <Loader size={75} />
        </Flex>
      </Card.Content>
    );
  }

  if (error) {
    return (
      <Card.Content>
        <Flex fullHeight padding="5 0" align="center" justify="center">
          <Text align="center" color="typography-medium-gray">
            There was an error processing the team data.
          </Text>
        </Flex>
      </Card.Content>
    );
  }

  if (!data.length) {
    return (
      <Card.Content>
        <Flex fullHeight padding="5 0" align="center" justify="center">
          <Text align="center" color="typography-medium-gray">
            There are no resources allocated to this project at this time.
          </Text>
        </Flex>
      </Card.Content>
    );
  }

  return (
    <Card.Content>
      <List
        items={data.map((t, idx) => (
          <Flex align="center" key={idx}>
            <Flex align="center" item xs={3}>
              <Avatar src={t.thumbnail} placeholder />
              <Block margin="0 0 0 2">
                <Text size="normal">{t.name}</Text>
              </Block>
            </Flex>
            <Flex item xs={2}>
              <Text size="normal">{t.role}</Text>
            </Flex>
            <Flex item xs={2}>
              <Text size="normal">{formatAllocation(t)}</Text>
            </Flex>
            <Flex item xs={5}>
              {renderRollOff(t.end, t.weeks)}
            </Flex>
          </Flex>
        ))}
      />
    </Card.Content>
  );
}
