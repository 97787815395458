import React, { Component } from 'react';
import Progress from '../Progress';
import './style.scss';

class Loading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      position: 0,
    };
  }

  componentDidMount() {
    this.timer = setInterval(() => {
      this.tick();
    }, 150);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  tick() {
    if (this.state.position < 100) {
      const newPosition = ++this.state.position;
      this.setState({ position: newPosition });
    }
  }

  render() {
    return (
      <div className="Loading">
        <h1 className="Loading-text">{`Loading ${this.state.position}%`}</h1>
        <Progress percentage={this.state.position} />
      </div>
    );
  }
}

export default Loading;
