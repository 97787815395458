// @flow
import React from 'react';

import { Block, Flex } from 'components/Containers';
import Icon from 'components/Icon';
import { LogoutButton } from '../styled';
import { color } from 'styles/constants';

type Props = {
  onLogOutClick: () => void,
};

export default function DrawerFooter(props: Props) {
  return (
    <Flex align="center" gutter={1} height="20px" margin="0 1">
      <Block flex="none">
        <Icon name="logout" color={color.RED} size={15} viewBox="0 0 15 17" />
      </Block>
      <Block flex="none">
        <LogoutButton onClick={props.onLogOutClick}>Logout</LogoutButton>
      </Block>
    </Flex>
  );
}
