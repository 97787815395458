import { useEffect, useState } from 'react';

import { getFinancialsByProjectId } from 'services/financials';

export const useProjectFinancials = tenkId => {
  const [state, setState] = useState({ data: null, loading: true });
  let isMounted = true; // this flag denote mount status

  useEffect(() => {
    setState({ data: null, loading: true });
    try {
      getFinancialsByProjectId(tenkId)
        .then(data => {
          if (isMounted) {
            setState({ data, loading: false });
          }
        })
        .catch(() => setState({ data: false, loading: false }));
    } catch {
      if (isMounted) {
        setState({ data: false, loading: false });
      }
    }
    return () => {
      isMounted = false; // use effect cleanup to set flag false, if unmounted
    };
  }, [tenkId]);

  return state;
};
