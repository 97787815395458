// @flow
import React from 'react';
import Input, { type Props as InputProps } from 'components/Input';
import StyledSelect from '../styled/StyledSelect';
import Item from './Item';
type Props = {
  name?: string,
  label?: string,
  visuallyHidden?: boolean,
  value: string | number,
  open?: boolean,
  onChange?: (
    e: SyntheticEvent<HTMLElement>,
    child: React$Node | string
  ) => void,
  onOpen?: (e: SyntheticEvent<HTMLElement>) => void,
  onClose?: (e: SyntheticEvent<HTMLElement>) => void,
  inputProps?: InputProps,
  children: React$Node,
  disabled?: boolean,
};

const menuSettings = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
};

function Select(props: Props) {
  const {
    name,
    label,
    visuallyHidden,
    value,
    open,
    onChange,
    onOpen,
    onClose,
    inputProps,
    children,
    disabled,
  } = props;

  function handleChange(e, child) {
    if (!onChange || disabled) return;
    onChange(e, child);
  }

  return (
    <StyledSelect
      disabled={disabled}
      name={name}
      value={value}
      open={open}
      onChange={handleChange}
      onOpen={onOpen}
      onClose={onClose}
      input={
        <Input
          label={label}
          visuallyHidden={visuallyHidden}
          style={{ margin: 0 }}
          {...inputProps}
        />
      }
      MenuProps={menuSettings}
    >
      {children}
    </StyledSelect>
  );
}

Select.Item = Item;

Select.defaultProps = {
  value: '',
};

export default Select;
