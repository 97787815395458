// @flow
import { PROJECT_INITIAL } from './constants';
import { createReducerFromHandlers } from '../helpers';
import { type ProjectState } from './types';
import {
  PROJECT_FETCH_START,
  PROJECT_FETCH_ERROR,
  PROJECT_FETCH_END,
  ACTION_START,
  ACTION_ERROR,
  ACTION_END,
  PROJECT_UPDATE_MILESTONE,
  PROJECT_ADD_MILESTONE,
  PROJECT_SELECT_REPORT,
  PROJECT_UPDATE_REPORT,
} from './constants';

export const initialState: ProjectState = PROJECT_INITIAL;

const handlers = {
  [PROJECT_FETCH_START](state) {
    return {
      ...state,
      project: {
        loading: true,
        error: null,
        data: [],
      },
    };
  },
  [PROJECT_FETCH_ERROR](state, action) {
    return {
      ...state,
      project: {
        loading: false,
        error: action.payload,
      },
    };
  },
  [PROJECT_FETCH_END](state, action) {
    return {
      ...state,
      project: {
        loading: false,
        error: null,
        data: action.payload,
      },
    };
  },
  [ACTION_START](state) {
    return {
      ...state,
      action: {
        error: null,
        loading: true,
        resolved: false,
      },
    };
  },
  [ACTION_ERROR](state, action) {
    return {
      ...state,
      action: {
        error: action.payload,
        loading: false,
        resolved: false,
      },
    };
  },
  [ACTION_END](state) {
    return {
      ...state,
      action: {
        error: null,
        loading: false,
        resolved: true,
      },
    };
  },
  [PROJECT_UPDATE_MILESTONE](state, action) {
    return {
      ...state,
      project: {
        loading: false,
        error: null,
        data: action.payload,
      },
    };
  },
  [PROJECT_ADD_MILESTONE](state, action) {
    return {
      ...state,
      project: {
        loading: false,
        error: null,
        data: action.payload,
      },
    };
  },
  [PROJECT_SELECT_REPORT](state, action) {
    return {
      ...state,
      project: {
        loading: false,
        error: null,
        data: action.payload,
      },
    };
  },
  [PROJECT_UPDATE_REPORT](state, action) {
    return {
      ...state,
      project: {
        loading: false,
        error: null,
        data: action.payload,
      },
    };
  },
};

export default createReducerFromHandlers(handlers, initialState);
