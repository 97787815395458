import styled from 'styled-components';
import { color } from 'styles/constants';

export default styled.select`
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, ${color.LIGHT_GRAY}, ${color.LIGHT_GRAY});
  background-position: calc(100% - 1.35em) 1.5em, calc(100% - 1.1em) 1.5em,
    calc(100% - 2.5em) 0.5em;
  background-size: 0.357em 0.357em, 0.357em 0.357em, 0.1em 2em;
  background-repeat: no-repeat;
  cursor: pointer;
`;
