// @flow
import React from 'react';
import { color } from 'styles/constants';
import DateSelect from 'components/DateSelect';
import { formatDate } from 'react-day-picker/moment';

import Text from 'components/Text';
import Dialog from 'components/Dialog';
import Input from 'components/Input';
import Select from 'components/Select';
import { Block, Flex } from 'components/Containers';
import Icon from 'components/Icon';
import type { Action, Project, MilestoneAddPayload } from 'store/project/types';
import type { User } from 'store/user/types';
import { success, error } from 'utils/alerts';
import { useAlert } from 'hooks/useAlert';
type Props = {
  open: boolean,
  currentUser: User,
  project: Project,
  addMilestone: (milestone: MilestoneAddPayload, userId: string) => void,
  onConfirm: () => void,
  onClose: () => void,
  action: Action,
};

const statusOptions = [
  {
    label: 'Good',
    value: 'good',
    symbol: { color: color.GREEN, name: 'empty_circle' },
  },
  {
    label: 'Client Change',
    value: 'client',
    symbol: { color: color.YELLOW, name: 'error_round' },
  },
  {
    label: 'Dialexa Change',
    value: 'dialexa',
    symbol: { color: color.RED, name: 'error_round' },
  },
];

const initialValues = {
  status: 'good',
  summary: '',
  planned: formatDate(new Date(), 'YYYY-MM-DDTHH:mm:ss.sss[Z]'),
};

export function MilestoneAddDialog(props: Props) {
  const {
    currentUser,
    open,
    project,
    addMilestone,
    onClose,
    onConfirm,
    action,
  } = props;

  const { createAlert } = useAlert();

  function handleValidation(validationValues) {
    const errors = {};

    if (!validationValues.name.trim()) {
      errors.name = true;
    }

    return errors;
  }

  async function handleSubmit(submitValues, { setErrors }) {
    const milestone = {
      status: submitValues.status,
      description: submitValues.name,
      planned: submitValues.planned,
      notes: submitValues.summary,
      projectId: project.id,
      forecast: submitValues.planned,
    };

    try {
      await addMilestone(milestone, currentUser.id);
      createAlert({
        message: success('milestone').add,
        type: 'success',
      });
      onConfirm();
    } catch (e) {
      if (e.errorCode === 'MILESTONE_EXISTS') {
        setErrors({ planned: e.message });
      } else {
        createAlert({
          message: error('milestone').add,
          type: 'error',
        });
        onClose();
      }
    }
  }

  return (
    <Dialog
      isLoading={action.isLoading}
      open={open}
      title="Create Milestone"
      initialValues={initialValues}
      isInitialValid={false}
      validate={handleValidation}
      onClose={onClose}
      onSubmit={handleSubmit}
      confirmText="Save"
    >
      {(values, handleChange, errors) => (
        <>
          <Block>
            <Input
              autoFocus
              fullWidth
              label="Milestone Name*"
              name="name"
              onChange={handleChange}
              placeholder="Enter text here"
              value={values.name}
            />
          </Block>
          <Block margin="0 0 3">
            <DateSelect
              id="completion-date-select-menu"
              label="Proposed Completion Date*"
              name="planned"
              value={values.planned}
              onChange={handleChange}
              dayPickerProps={{
                disabledDays: {
                  before: new Date(project.start),
                  after: new Date(project.end),
                },
              }}
            />
          </Block>
          {errors.planned && (
            <Text size="small" color="error">
              {errors.planned}
            </Text>
          )}
          <Block margin="0 0 3">
            <Select
              name="status"
              label="Status*"
              value={values.status}
              onChange={handleChange}
            >
              {statusOptions.map((option, idx) => (
                <Select.Item key={idx} value={option.value}>
                  <Flex>
                    <Block flex="none" margin="0 1 0 0">
                      <Icon size={24} {...option.symbol} />
                    </Block>
                    {option.label}
                  </Flex>
                </Select.Item>
              ))}
            </Select>
          </Block>
          <Block>
            <Input
              name="summary"
              label="Summary"
              multiline
              placeholder="Enter text here"
              fullWidth
              value={values.summary}
              onChange={handleChange}
              maxLength={255}
            />
          </Block>
          <Block>
            <Text size="small" color="typography-medium-gray" weight={600}>
              Required fields*
            </Text>
          </Block>
        </>
      )}
    </Dialog>
  );
}
