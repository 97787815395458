import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getCurrentUser,
  getProjectReport,
  getProjectReportComments,
  getProjectReportCurrentWeek,
  getProjectEditable,
} from 'store/selectors';

import { deleteCommentItem } from 'store/project/actions';

import ProjectComments from './presenter';

function mapStateToProps(state) {
  return {
    currentUser: getCurrentUser(state),
    comments: getProjectReportComments(state),
    isCurrentWeek: getProjectReportCurrentWeek(state),
    editable: getProjectEditable(state),
    selectedReport: getProjectReport(state),
  };
}

function mapActionsToProps(dispatch) {
  return bindActionCreators(
    {
      deleteCommentItem,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapActionsToProps)(ProjectComments);
