// @flow
import React from 'react';
import type { ProjectBudget } from '../../types';
import { PieChart, Pie } from 'recharts';
import onScheduleLbl from './OnSchedule.svg';
import blankLbl from './Blank.svg';
import behindLbl from './Behind.svg';
import forApprovalLbl from './ForApproval.svg';
import overdueLbl from './Overdue.svg';
import atRiskLbl from './AtRisk.svg';
import Back from './Back.svg';
import classNames from 'classnames';
import TimeElapsed from '../TimeElapsed';
import { computeInterval } from 'helpers/formatters/';
import Budget from '../Budget';
import dayjs from 'dayjs';
import { color } from 'styles/constants';
import './style.scss';

const data = [{ name: 'Project Status', value: 100 }];
const FOR_APPROVAL = color.BLUE;
const ON_SCHEDULE = color.GREEN;
const BEHIND = color.RED;
const OVERDUE = color.RED;
const AT_RISK = color.YELLOW;

type Props = {
  status:
    | 'on schedule'
    | 'behind'
    | 'for approval'
    | 'overdue'
    | 'at risk'
    | 'blank',
  start: string,
  end: string,
  displayBudget: boolean,
  budget: ProjectBudget,
};

const calculateEndAngle = (start: string, end: string) => {
  const total = computeInterval(start, end);
  const diff = computeInterval(dayjs(), end);
  const endAngle = ((total - diff) / total) * 360;
  return endAngle;
};

const computeFill = (status: string) => {
  switch (status) {
    case 'behind':
      return BEHIND;
    case 'for approval':
      return FOR_APPROVAL;
    case 'overdue':
      return OVERDUE;
    case 'at risk':
      return AT_RISK;
    default:
      return ON_SCHEDULE;
  }
};

const computeLbl = (status: string) => {
  switch (status) {
    case 'behind':
      return <img className="Chart-status" src={behindLbl} alt="behind" />;
    case 'for approval':
      return (
        <img className="Chart-status" src={forApprovalLbl} alt="for-approval" />
      );
    case 'overdue':
      return <img className="Chart-status" src={overdueLbl} alt="overdue" />;
    case 'at risk':
      return <img className="Chart-status" src={atRiskLbl} alt="at-risk" />;
    case 'on schedule':
      return (
        <img className="Chart-status" src={onScheduleLbl} alt="on-schedule" />
      );
    default:
      return <img className="Chart-status" src={blankLbl} alt="blank" />;
  }
};

const Chart = (props: Props) => {
  const { status, start, end, budget, displayBudget } = props;
  return (
    <div className="Chart">
      <div
        className={classNames({
          'Chart-on-schedule': status === 'on schedule' || 'blank',
          'Chart-for-approval': status === 'for approval',
          'Chart-behind': status === 'behind',
          'Chart-overdue': status === 'overdue',
          'Chart-at-risk': status === 'at risk',
        })}
      >
        {computeLbl(status)}
        <PieChart width={124} height={124}>
          <Pie
            data={data}
            cx={62}
            cy={62}
            startAngle={0}
            endAngle={calculateEndAngle(start, end)}
            innerRadius={50}
            outerRadius={60}
            fill={computeFill(status)}
            paddingAngle={0}
          />
        </PieChart>
        <TimeElapsed value={computeInterval(dayjs(), end)} />
        <img className="Chart-back" src={Back} alt="back" />
      </div>
      {displayBudget && budget && <Budget {...budget} />}
    </div>
  );
};

export default Chart;
