// @flow
import styled, { css } from 'styled-components';
import { spacingDefaults, color } from 'styles/constants';

const borderBottom = props =>
  props.hasBorder
    ? css`
        border-bottom: 1px solid ${color.BORDER_COLOR};
      `
    : css`
        border-bottom: none;
      `;

const bg = props => {
  switch (props.bg) {
    case 'footer':
      return css`
        background: ${color.PALE_GRAY};
      `;
    case 'error':
      return css`
        background: ${color.RED};
      `;
    default:
      return css`
        background: ${color.WHITE};
      `;
  }
};

export default styled.div`
  padding: ${spacingDefaults.normal};
  word-wrap: 'break-word';
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;

  ${borderBottom};
  ${bg};
`;
