// @flow
import React from 'react';
import type { JiraVersion } from '../../types';
import {
  percentage,
  computeInterval,
  formatDateDuration,
  pluralize,
} from 'helpers/formatters/';
import Meta from '../Meta';
import Progress from 'components/Progress';
import './style.scss';

export const formatVersionDetails = (
  start: string,
  end: string,
  sprintTotal: number,
  measurement: string
) => {
  const duration = computeInterval(start, end, 'weeks');
  const durationText = formatDateDuration(duration);
  const measurementText = pluralize(sprintTotal, measurement);

  return `${duration} ${durationText} | ${sprintTotal} ${measurementText}`;
};

type Props = {
  measurement: string,
  version: Array<JiraVersion>,
};

const Version = (props: Props) => {
  const { measurement, version } = props;
  return (
    <div className="Version">
      {version.map((ver, idx) => (
        <div key={idx}>
          <div className="Version-overview">
            <div className="Version-num">{`Version - ${ver.name}`}</div>
            <hr />
          </div>
          <Meta start={ver.start} end={ver.end} />
          <div className="Version-info">
            <div className="Version-details">
              {formatVersionDetails(ver.start, ver.end, ver.total, measurement)}
            </div>
            <Progress percentage={percentage(ver.total, ver.completed)} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Version;
