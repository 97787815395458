// @flow
import { createSelector } from 'reselect';

import type { State } from './reducer';
import { STATE_KEY as GLOBAL_SETTINGS_KEY } from 'store/globalSettings';

const getGlobalSettingsState = state => state[GLOBAL_SETTINGS_KEY];

/* Selectors */
const isLoading = (state: State) => state.isLoading;
const data = (state: State) => state.data;

export const getGlobalSettingsLoading = createSelector(
  getGlobalSettingsState,
  isLoading
);

export const getGlobalSettings = createSelector(getGlobalSettingsState, data);
