import { useEffect, useState } from 'react';

import { getReportByProjectId } from 'services/10kReports';

export const use10kReports = tenkId => {
  const [state, setState] = useState({ data: null, loading: true });

  useEffect(() => {
    setState({ data: null, loading: true });
    try {
      getReportByProjectId(tenkId).then(data =>
        setState({ data, loading: false })
      );
    } catch {
      setState({ data: false, loading: false });
    }
  }, [tenkId]);

  return state;
};
