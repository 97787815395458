// @flow
import React from 'react';
import Dialog from 'components/Dialog';
import Text from 'components/Text';
import { Block } from 'components/Containers';
import { IconButton } from 'components/Icon';
import Select from 'components/Select';
import { titleCase } from 'helpers/formatters';
import { useUsers } from 'hooks/useUsers';
import type { User } from 'store/user/types';

type Props = {
  open: boolean,
  user: User,
  onConfirm: () => void,
  onClose: () => void,
};

export function UserRoleDialog(props: Props) {
  const { open, onConfirm, onClose, user } = props;

  if (!open) {
    return null;
  }

  const initialValues = { role: user.role };
  const { users, updateUserRole } = useUsers();
  const { roles } = users.data;

  function handleValidation({ role }) {
    const errors = {};

    if (!roles.find(r => r.name === role)) {
      errors.type = true;
    }

    return errors;
  }

  function handleSubmit({ role }) {
    const newRole = roles.find(r => r.name === role);
    updateUserRole(user.id, newRole);
    onConfirm();
  }

  return (
    <Dialog
      open={open}
      initialValues={initialValues}
      isInitialValid={false}
      validate={handleValidation}
      onClose={onClose}
      onSubmit={handleSubmit}
      confirmText="Save"
      rightHeader={
        <Block flex="none">
          <IconButton name="exit" onClick={onClose} />
        </Block>
      }
      title={`Update ${user.firstName}'s Role`}
      maxWidth="sm"
    >
      {(values, handleChange) => (
        <>
          <Block margin="0 0 1">
            <Select
              name="role"
              label="Role*"
              value={values.role}
              onChange={handleChange}
            >
              {roles.map((role, i) => (
                <Select.Item key={i} value={role.name}>
                  {titleCase(role.name)}
                </Select.Item>
              ))}
            </Select>
          </Block>
          <Block>
            <Text size="small" color="typography-medium-gray" weight={600}>
              Required input fields*
            </Text>
          </Block>
        </>
      )}
    </Dialog>
  );
}
