// @flow
import React, { useMemo, useEffect } from 'react';
import { parse, format, addDays, startOfWeek, endOfWeek } from 'date-fns';
import { useQueryParam } from 'use-query-params';

import type { Project } from 'store/project/types';
import { useProjectTeam } from 'hooks/useProjectTeam';
import Card from 'components/Card';

import ProjectTeamAssignedHeader from './ProjectTeamAssignedHeader';
import ProjectTeamAssignedContent from './ProjectTeamAssignedContent';

type Props = {
  project: Project,
};

const getWeekDays = (weekStart: Date) => {
  const days = [weekStart];
  for (let i = 1; i < 7; i += 1) {
    days.push(addDays(weekStart, i));
  }
  return days;
};

const getWeekRange = (date: Date) => {
  return {
    from: startOfWeek(date, { weekStartsOn: 1 }),
    to: endOfWeek(date, { weekStartsOn: 1 }),
  };
};

const getWeekStart = (date: Date) => startOfWeek(date, { weekStartsOn: 1 });

const today = getWeekStart(new Date());

const DateParam = {
  encode(date: Date) {
    return format(date, 'MM-DD-YYYY');
  },

  decode(str: string) {
    return parse(str);
  },
};

export default function ProjectTeamAssigned(props: Props) {
  const { project } = props;
  const [selectedDay = today, setSelectedDay] = useQueryParam(
    'selectedDay',
    DateParam
  );
  const { assigned, fetchAssigned } = useProjectTeam();

  const day = useMemo(() => getWeekStart(selectedDay), [selectedDay]);
  const week = useMemo(() => getWeekDays(getWeekRange(day).from), [day]);

  useEffect(() => {
    fetchAssigned(project.tenKId, day);
  }, [day]);

  return (
    <Card>
      <ProjectTeamAssignedHeader
        project={project}
        selectedDay={day}
        selectedWeek={week}
        onChange={e => setSelectedDay(getWeekStart(e.target.value))}
      />
      <ProjectTeamAssignedContent assigned={assigned} />
    </Card>
  );
}
