// @flow
import React from 'react';
import { Block, Flex } from 'components/Containers';
import Text from 'components/Text';
import { color } from 'styles/constants';
type Props = {
  title: React$Node | string,
  subHeader?: React$Node | string,
  rightHeader?: React$Node | string,
  fullWidth?: string,
};

export default function CardHeader(props: Props) {
  const { title, subHeader, rightHeader } = props;

  function renderTitle() {
    return typeof title === 'string' ? (
      <Text tag="h3" weight={600}>
        {title}
      </Text>
    ) : (
      title
    );
  }

  function renderSubHeader() {
    return typeof subHeader === 'string' ? (
      <Text tag="h6">{subHeader}</Text>
    ) : (
      subHeader
    );
  }

  function renderRightHeader() {
    return typeof rightHeader === 'string' ? (
      <Text tag="h3" weight={600}>
        {rightHeader}
      </Text>
    ) : (
      rightHeader
    );
  }

  return (
    <Flex
      justify="space-between"
      align="center"
      padding="4 3"
      border={{ bottom: `1px solid ${color.BORDER_COLOR}` }}
    >
      <Block fullWidth={props.fullWidth}>
        {renderTitle()}
        {renderSubHeader()}
      </Block>
      <Block flex="none">{renderRightHeader()}</Block>
    </Flex>
  );
}
