// @flow
import type { Dispatch } from 'react';
import { getUsers, updateUser } from 'services/users';
import { getRoles } from 'services/auth';
import type { UserRole, UsersState } from '../types';
import * as U from './constants';

export const fetchUsers = (dispatch: Dispatch) => async (settings?: Object) => {
  dispatch({ type: U.FETCH_USERS_START });

  try {
    const [users, roles] = await Promise.all([getUsers(settings), getRoles()]);
    const usersMap = {};

    users.data.map(user => {
      const role = roles.data.find(r => r.id === user.roleId);
      const { id } = user;
      usersMap[id] = { ...user, role: role.name };
    });
    dispatch({
      type: U.FETCH_USERS_FULFILLED,
      payload: { map: usersMap, roles: roles.data },
    });
  } catch (err) {
    dispatch({ type: U.FETCH_USERS_ERROR, payload: err });
  }
};

export const archiveUser = (dispatch: Dispatch, state: UsersState) => async (
  userId: string
) => {
  try {
    await updateUser(userId, { archived: true });
    const { users } = state;
    const { map: usersMap } = users.data;
    const updatedUsersMap = {
      ...usersMap,
      [userId]: {
        ...usersMap[userId],
        archived: true,
      },
    };
    dispatch({ type: U.UPDATE_USERS_FULFILLED, payload: updatedUsersMap });
  } catch (err) {
    dispatch({ type: U.UPDATE_USERS_ERROR, payload: err });
  }
};

export const unarchiveUser = (dispatch: Dispatch, state: UsersState) => async (
  userId: string
) => {
  try {
    await updateUser(userId, { archived: false });
    const { users } = state;
    const { map: usersMap } = users.data;
    const updatedUsersMap = {
      ...usersMap,
      [userId]: {
        ...usersMap[userId],
        archived: false,
      },
    };
    dispatch({ type: U.UPDATE_USERS_FULFILLED, payload: updatedUsersMap });
  } catch (err) {
    dispatch({ type: U.UPDATE_USERS_ERROR, payload: err });
  }
};

export const updateUserRole = (dispatch: Dispatch, state: UsersState) => async (
  userId: string,
  role: UserRole
) => {
  try {
    await updateUser(userId, { roleId: role.id });
    const { users } = state;
    const { map: usersMap } = users.data;
    const updatedUsersMap = {
      ...usersMap,
      [userId]: {
        ...usersMap[userId],
        roleId: role.id,
        role: role.name,
      },
    };
    dispatch({ type: U.UPDATE_USERS_FULFILLED, payload: updatedUsersMap });
  } catch (err) {
    dispatch({ type: U.UPDATE_USERS_ERROR, payload: err });
  }
};
