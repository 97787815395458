import 'whatwg-fetch';
import { getData, checkStatus, getOne } from './normalizer';
import { URL } from './base';
import headers from './headers';

export function getSources() {
  return fetch(`${URL}/sources`, {
    method: 'GET',
    headers: headers(),
  })
    .then(checkStatus)
    .then(getData);
}

export function createSource(payload) {
  return fetch(`${URL}/sources`, {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify(payload),
  })
    .then(checkStatus)
    .then(getOne);
}

export function updateSource(id, payload) {
  return fetch(`${URL}/sources/${id}`, {
    method: 'PUT',
    headers: headers(),
    body: JSON.stringify(payload),
  })
    .then(checkStatus)
    .then(getOne);
}

export function getSourceProjects(sourceId) {
  return fetch(`${URL}/sources/${sourceId}/projects`, {
    method: 'GET',
    headers: headers(),
  })
    .then(checkStatus)
    .then(getData);
}

export function getSourceById(id) {
  return fetch(`${URL}/sources/${id}`, {
    method: 'GET',
    headers: headers(),
  })
    .then(checkStatus)
    .then(getOne);
}

export function deleteSourceById(id) {
  return fetch(`${URL}/sources/${id}`, {
    method: 'DELETE',
    headers: headers(),
  }).then(checkStatus);
}
