export default {
  avatar: [
    'M16 11a4 4 0 1 1 0 8 4 4 0 0 1 0-8zm-3.554 10h7.107a3 3 0 0 1 2.914 2.283l1.476 6A3 3 0 0 1 21.03 33H10.97a3 3 0 0 1-2.913-3.717l1.476-6A3 3 0 0 1 12.446 21z',
  ],
  jira: [
    'M4.5868,15.9324a2.0788,2.0788,0,0,0,.0009-2.94l-.0009-.0009h0l-2.7093-2.7L.6453,11.5236a.3305.3305,0,0,0,0,.4673l3.9415,3.9415Zm3.9415-4.4088L4.5868,7.5821h0l-.0121.0121h0a2.0787,2.0787,0,0,0,.0088,2.9308l2.7114,2.6993,1.2334-1.2334A.3307.3307,0,0,0,8.5283,11.5236ZM4.5868,10.5228A2.0787,2.0787,0,0,1,4.578,7.592L1.7662,10.4026l1.47,1.47Zm1.348,1.1209-1.348,1.348a2.0788,2.0788,0,0,1,.0009,2.94l-.0009.0009h0L7.4051,13.114Zm6.6584-3.07h.873v4.4309c0,1.1739-.5137,1.984-1.7173,1.984a3.1013,3.1013,0,0,1-1.0471-.1653v-.8487a2.4471,2.4471,0,0,0,.9016.1653c.7374,0,.992-.4409.992-1.0769Zm2.4635-.3405a.5444.5444,0,0,1,.5819.5042.53.53,0,0,1,0,.0777.582.582,0,1,1-1.1639.0009V8.8154a.5444.5444,0,0,1,.5042-.5819A.5317.5317,0,0,1,15.0567,8.2335ZM14.63,10.0962h.8344v4.85H14.63Zm2.0942-.0011h.8145v.8531a1.6044,1.6044,0,0,1,1.7173-.9214v.8145c-1.067-.11-1.7173.2138-1.7173,1.2411v2.8657h-.8145Zm6.44,3.9768a1.7088,1.7088,0,0,1-1.639.97c-1.29,0-1.94-1.0956-1.94-2.5218,0-1.3679.679-2.5219,2.0369-2.5219a1.5916,1.5916,0,0,1,1.5431.95v-.8531H24v4.85h-.8344Zm-1.4163.194a1.383,1.383,0,0,0,1.4163-1.552v-.3923c0-1.0769-.6106-1.552-1.3226-1.552-.9413,0-1.4263.6206-1.4263,1.7459.0044,1.1684.47,1.75,1.3337,1.75Z',
  ],
  list: [
    'M4 14h4v-4H4v4zm0 5h4v-4H4v4zM4 9h4V5H4v4zm5 5h12v-4H9v4zm0 5h12v-4H9v4zM9 5v4h12V5H9z',
  ],
  grid: [
    'M4 11h5V5H4v6zm0 7h5v-6H4v6zm6 0h5v-6h-5v6zm6 0h5v-6h-5v6zm-6-7h5V5h-5v6zm6-6v6h5V5h-5z',
  ],
  warning: ['M.5 15h17L9 0 .5 15zm9.5-2H8v-2h2v2zm0-3H8V6h2v4z'],
  more: [
    'M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2',
  ],
  add: ['M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z'],
  minus: ['M19 13H5v-2h14v2z'],
  exit: [
    'M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z',
  ],
  arrowRight: [
    'M9.29 15.88L13.17 12 9.29 8.12c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0l4.59 4.59c.39.39.39 1.02 0 1.41L10.7 17.3c-.39.39-1.02.39-1.41 0-.38-.39-.39-1.03 0-1.42z',
  ],
  arrowLeft: [
    'M14.71 15.88L10.83 12l3.88-3.88c.39-.39.39-1.02 0-1.41-.39-.39-1.02-.39-1.41 0L8.71 11.3c-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0 .38-.39.39-1.03 0-1.42z',
  ],
  check: [
    'M12 1a.999.999 0 0 0-1.707-.707L4 6.585 1.707 4.293A.999.999 0 1 0 .293 5.707l3 3.001a1 1 0 0 0 1.415 0c0-.002 0-.003.002-.004l6.997-6.997A.997.997 0 0 0 12 1',
  ],
  check_round: [
    'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3.88-11.71L10 14.17l-1.88-1.88c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7c.39-.39.39-1.02 0-1.41-.39-.39-1.03-.39-1.42 0z',
  ],
  error_round: [
    'M12 7c.55 0 1 .45 1 1v4c0 .55-.45 1-1 1s-1-.45-1-1V8c0-.55.45-1 1-1zm-.01-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm1-3h-2v-2h2v2z',
  ],
  exit_round: [
    'M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3.59-13L12 10.59 8.41 7 7 8.41 10.59 12 7 15.59 8.41 17 12 13.41 15.59 17 17 15.59 13.41 12 17 8.41z',
  ],
  expand_more: [
    'M15.88 9.29L12 13.17 8.12 9.29c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41-.39-.38-1.03-.39-1.42 0z',
  ],
  empty_circle: [
    'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z',
  ],
  arrow: [
    'M15.923 6.382a.991.991 0 0 0-.217-1.09l-4.999-5a1 1 0 0 0-1.414 1.415L12.586 5H1a1 1 0 1 0 0 2h11.586l-3.293 3.293a.997.997 0 0 0 0 1.414A.993.993 0 0 0 10 12a.993.993 0 0 0 .707-.293l4.999-5a.99.99 0 0 0 .217-.325',
  ],
  hamburger: ['M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z'],
  engage: [
    'M6 0h28a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1zM1 12h28a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1zm5 11h28a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1z',
  ],
  projects: [
    'M8.263 1.737a1.237 1.237 0 0 1-1.131.737H4.184c-.505 0-.94-.303-1.131-.737H1V14h9.316V1.737H8.263zm0-1h2.553a.5.5 0 0 1 .5.5V14.5a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5V1.237a.5.5 0 0 1 .5-.5h2.553A1.237 1.237 0 0 1 4.184 0h2.948c.505 0 .94.303 1.131.737zm-1.131.737a.237.237 0 1 0 0-.474H4.184a.237.237 0 1 0 0 .474h2.948z',
  ],
  book: [
    'M9.333 9.333V1H2.5C1.69 1 1 1.805 1 2.833v7.065a2.3 2.3 0 0 1 1.5-.565h6.833zm0 1H2.5c-.81 0-1.5.806-1.5 1.834S1.69 14 2.5 14h6.833v-3.667zM9.833 0a.5.5 0 0 1 .5.5v14a.5.5 0 0 1-.5.5H2.5C1.1 15 0 13.716 0 12.167V2.833C0 1.284 1.1 0 2.5 0h7.333z',
  ],
  tv: [
    'M5.338 3L3.15.857a.5.5 0 0 1 .7-.714L6.5 2.738 9.15.143a.5.5 0 1 1 .7.714L7.662 3H12.5a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-9A.5.5 0 0 1 .5 3h4.838zM12 4H1v8h11V4z',
  ],
  link: [
    'M2.621 6.015a.5.5 0 0 1 .707.707L2.322 7.728l-.125.126a3.5 3.5 0 1 0 4.95 4.95l.125-.126 1.006-1.006a.5.5 0 0 1 .707.707l-1.006 1.006-.125.125A4.5 4.5 0 0 1 1.49 7.146l.125-.125 1.006-1.006zm2.97 4.101a.5.5 0 0 1-.707-.707l4.525-4.525a.5.5 0 0 1 .707.707l-4.525 4.525zm6.788-1.131a.5.5 0 0 1-.707-.707l1.006-1.006.125-.126a3.5 3.5 0 1 0-4.95-4.95l-.125.126-1.006 1.006a.5.5 0 0 1-.707-.707l1.006-1.006.125-.125a4.5 4.5 0 0 1 6.364 6.364l-.125.125-1.006 1.006z',
  ],
  logout: [
    'M2.424 8.758H.5a.5.5 0 0 1 0-1h1.924V.5a.5.5 0 0 1 .5-.5h10.667a.5.5 0 0 1 .5.5v15.515a.5.5 0 0 1-.5.5H2.924a.5.5 0 0 1-.5-.5V8.758zm1 0v6.757h9.667V1H3.424v6.758h5.081L6.449 5.702a.5.5 0 0 1 .708-.707l2.909 2.909a.5.5 0 0 1 0 .707l-2.91 2.91a.5.5 0 0 1-.707-.708l2.056-2.055h-5.08z',
  ],
  people: [
    'M1 9c0-1.5 1.91-1.5 2.864-2.5.477-.5-.955-.5-.955-3 0-1.667.636-2.5 1.91-2.5 1.272 0 1.908.833 1.908 2.5 0 2.5-1.432 2.5-.954 3 .954 1 2.863 1 2.863 2.5m-.132-1.516c.313-.118.57-.228.77-.33.35-.176.689-.377.953-.654.478-.5-.954-.5-.954-3 0-1.667.636-2.5 1.909-2.5 1.273 0 1.909.833 1.909 2.5 0 2.5-1.432 2.5-.955 3C13.091 7.5 15 7.5 15 9',
  ],
  chevron_left: [
    'M14.286 17.143a.858.858 0 0 0 .606-1.465L11.218 12l3.674-3.678a.858.858 0 0 0-1.212-1.213l-4.25 4.255c-.012.01-.026.013-.036.023a.856.856 0 0 0-.251.607v.012c0 .22.084.439.251.607.01.01.024.013.035.023l4.25 4.255a.854.854 0 0 0 .607.252',
  ],
  chevron_right: [
    'M10 6.857a.858.858 0 0 0-.606 1.465L13.068 12l-3.674 3.678a.858.858 0 0 0 1.212 1.213l4.25-4.255c.012-.01.025-.013.036-.023a.856.856 0 0 0 .25-.607V12v-.006a.858.858 0 0 0-.25-.607c-.01-.01-.024-.013-.035-.023l-4.25-4.255A.854.854 0 0 0 10 6.857',
  ],
  settings: [
    'M15.95 10.78c.03-.25.05-.51.05-.78s-.02-.53-.06-.78l1.69-1.32c.15-.12.19-.34.1-.51l-1.6-2.77c-.1-.18-.31-.24-.49-.18l-1.99.8c-.42-.32-.86-.58-1.35-.78L12 2.34c-.03-.2-.2-.34-.4-.34H8.4c-.2 0-.36.14-.39.34l-.3 2.12c-.49.2-.94.47-1.35.78l-1.99-.8c-.18-.07-.39 0-.49.18l-1.6 2.77c-.1.18-.06.39.1.51l1.69 1.32c-.04.25-.07.52-.07.78s.02.53.06.78L2.37 12.1c-.15.12-.19.34-.1.51l1.6 2.77c.1.18.31.24.49.18l1.99-.8c.42.32.86.58 1.35.78l.3 2.12c.04.2.2.34.4.34h3.2c.2 0 .37-.14.39-.34l.3-2.12c.49-.2.94-.47 1.35-.78l1.99.8c.18.07.39 0 .49-.18l1.6-2.77c.1-.18.06-.39-.1-.51l-1.67-1.32zM10 13c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z',
  ],
};
