import 'whatwg-fetch';
import qs from 'querystring';
import headers from './headers';
import { getData, getOne, checkStatus } from './normalizer';
import { URL } from './base';

export const serializeProject = data => {
  const payload = Object.assign({}, data);

  delete payload.milestones;
  delete payload.reports;

  return payload;
};

export function getProjects(filters = {}) {
  let url = `${URL}/projects`;

  url += `?${qs.stringify(filters)}`;

  return fetch(url, {
    method: 'GET',
    headers: headers(),
  })
    .then(checkStatus)
    .then(getData);
}

export function getJiraProjects(sourceId) {
  return fetch(`${URL}/sources/${sourceId}/external_projects`, {
    method: 'GET',
    headers: headers(),
  })
    .then(checkStatus)
    .then(getData);
}

export function getJiraProjectByKey(sourceId, key) {
  return fetch(`${URL}/sources/${sourceId}/external_projects/${key}`, {
    method: 'GET',
    headers: headers(),
  })
    .then(checkStatus)
    .then(getOne);
}

export function createProject(payload) {
  return fetch(`${URL}/projects`, {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify(payload),
  })
    .then(checkStatus)
    .then(getOne);
}

export function updateProject(id, data) {
  const payload = serializeProject(data);
  return fetch(`${URL}/projects/${id}`, {
    method: 'PUT',
    headers: headers(),
    body: JSON.stringify(payload),
  })
    .then(checkStatus)
    .then(getOne);
}

export function getProjectById(id) {
  return fetch(`${URL}/projects/${id}`, {
    method: 'GET',
    headers: headers(),
  })
    .then(checkStatus)
    .then(getOne);
}

export function syncProjects() {
  return fetch(`${URL}/projects/ten-thousand-feet/sync`, {
    method: 'GET',
    headers: headers(),
  }).then(checkStatus);
}

export function generateReports() {
  return fetch(`${URL}/projects/reports/generate`, {
    method: 'GET',
    headers: headers(),
  }).then(checkStatus);
}
