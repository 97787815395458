// @flow
import { startOfDay } from 'date-fns';
import type { Update } from 'store/project/types';

export function sortReportByTypeAndDate(
  updates: Array<Update>,
  reportDate: string
) {
  return (
    updates &&
    updates.sort((a, b) => {
      const updateDate1 = new Date(startOfDay(a.updatedAt || reportDate));
      const updateDate2 = new Date(startOfDay(b.updatedAt || reportDate));

      let type1;
      let type2;

      if (a.type.toLowerCase() === 'accomplishment') type1 = 3;
      if (a.type.toLowerCase() === 'risk') type1 = 2;
      if (a.type.toLowerCase() === 'issue') type1 = 1;

      if (b.type.toLowerCase() === 'accomplishment') type2 = 3;
      if (b.type.toLowerCase() === 'risk') type2 = 2;
      if (b.type.toLowerCase() === 'issue') type2 = 1;

      if (type1 < type2) return 1;
      if (type1 > type2) return -1;

      if (updateDate1 > updateDate2) return 1;
      if (updateDate1 < updateDate2) return -1;

      return 0;
    })
  );
}
