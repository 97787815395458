// @flow
import React from 'react';
import StyledText from '../styled/StyledText';
import { type AppearanceTypes } from 'styles/constants';

type Props = {
  tag?:
    | 'p'
    | 'label'
    | 'span'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'small',
  color?: AppearanceTypes | string,
  align?: string,
  family?: 'primary' | 'secondary',
  weight?: number,
  size?:
    | 'xsmall'
    | 'small'
    | 'normal'
    | 'medium'
    | 'large'
    | 'header'
    | 'xlarge',
  italics?: boolean,
  children: React$Node,
  overflow?: 'clip' | 'ellipsis',
  transform?: 'lowercase' | 'uppercase' | 'capitalize' | 'none' | 'inherit',
  wordbreak?: 'break-all' | 'break-word',
};

function Text(props: Props) {
  const { children, ...other } = props;
  return <StyledText {...other}>{children}</StyledText>;
}

Text.defaultProps = {
  color: 'black',
  family: 'primary',
  tag: 'p',
  transform: 'none',
};

export default Text;
