// @flow
import React from 'react';

import type { ProjectReport } from 'store/project/types';
import { startOfWeek, lastDayOfWeek, isSameMonth } from 'date-fns';

import Button from 'components/Button';
import { Flex, Block } from 'components/Containers';
import Text from 'components/Text';
import { formatDate, getUTCDate } from 'helpers/formatters/';
import MilestoneAddDialog from 'dialogs/ProjectDialogs/MilestoneAddDialog';

type Props = {
  weekNo: number,
  editable: boolean,
  selectedReport: ProjectReport,
};

function renderStartEnd(selectedReport: ProjectReport) {
  if (!selectedReport) return '';

  const reportDateUtc = getUTCDate(selectedReport.reportDate);
  const start = startOfWeek(reportDateUtc, { weekStartsOn: 1 });
  const end = lastDayOfWeek(reportDateUtc, { weekStartsOn: 1 });
  const formattedStart = formatDate(start, 'MMM DD');
  const formattedEnd = isSameMonth(start, end)
    ? formatDate(end, 'DD')
    : formatDate(end, 'MMM DD');

  return <span>{` ${formattedStart} - ${formattedEnd} `}</span>;
}

export default function TimelineHeader(props: Props) {
  const [showAddMilestoneDialog, setShowAddMilestoneDialog] = React.useState(
    false
  );

  const { selectedReport, weekNo, editable } = props;

  return (
    <>
      <Flex align="center" justify="space-between">
        <Flex gutter={2} align="center">
          <Block width="250px">
            <Text tag="h3" weight={600}>
              Week {weekNo || '-'} / {renderStartEnd(selectedReport)}
            </Text>
          </Block>
          {editable && (
            <Block flex="none">
              <Button
                data-testid="add-milestone-button"
                onClick={() => setShowAddMilestoneDialog(true)}
              >
                Add Milestone
              </Button>
            </Block>
          )}
        </Flex>
      </Flex>
      <MilestoneAddDialog
        open={showAddMilestoneDialog}
        onConfirm={() => setShowAddMilestoneDialog(false)}
        onClose={() => setShowAddMilestoneDialog(false)}
      />
    </>
  );
}
