import { connect } from 'react-redux';

import ProjectMilestoneListHeader from './presenter';
import { getProjectData, getMilestones } from 'store/selectors';

function mapStateToProps(state, props) {
  return {
    project: getProjectData(state),
    milestones: getMilestones(state, props),
  };
}
export default connect(mapStateToProps, null)(ProjectMilestoneListHeader);
