import styled from 'styled-components';

import { slideInRight, slideOutRight } from 'styles/animations';
import { fontSize, color, spacing } from 'styles/constants';

export const AlertContainer = styled.div`
  position: fixed;
  text-align: left;
  top: 78px;
  right: 20px;
  border-radius: 4px;
  width: 255px;
  padding: ${spacing('xsmall', 'small')};
  z-index: 99999;
  color: ${color.BLACK};
  background-color: ${color.WHITE};
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: .5s ease;
  animation: ${slideInRight} .10s ease;
  animation-fill-mode: both;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  font-size: ${fontSize.normal};

  & button {
    padding: 0;
  }

  ${({ isSuccess }) =>
    isSuccess &&
    `
    border-left: 3px solid ${color.GREEN};
  `}

  ${({ isError }) =>
    isError &&
    `
    border-left: 3px solid ${color.RED};
  `}

  ${({ isWarning }) =>
    isWarning &&
    `
    border-left: 3px solid ${color.YELLOW};
  `}

  &:empty {
    animation: ${slideOutRight} .10s ease;
    animation-fill-mode: forwards;
    right: 0;
  }
`;
