import { default as MaterialAvatar } from '@material-ui/core/Avatar';
import styled from 'styled-components';
import { color, fontSize } from 'styles/constants';

export default styled(MaterialAvatar)`
  && {
    background-color: ${props => props.color};
    color: ${color.MEDIUM_GRAY};
    font-size: ${fontSize.small};
    font-weight: bold;
    text-align: center;
  }
`;
