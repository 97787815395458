import styled, { css } from 'styled-components';
import { color } from 'styles/constants';

const visible = props => {
  if (props.visible) {
    return css`
      font-size: 14px;
      font-weight: 600;
      color: ${color.TYPOGRAPHY_MEDIUM_GRAY};
      margin-bottom: 4px;
    `;
  }
  return css`
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  `;
};

export const InputLabel = styled.label`
  ${visible}
`;

export const HaloWrapper = styled.div`
  position: absolute;
  z-index: 2;
  right: 20px;
  bottom: 20px;
  border-radius: 50%;
`;

export const InputWrapper = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  min-width: 184px;

  ${props =>
    props.fullWidth &&
    css`
      width: 100%;
    `};
`;
