// @flow
import React from 'react';
import { isEqual, startOfDay } from 'date-fns';
import type {
  Project,
  Milestone,
  MilestoneUpdatePayload,
  MilestoneUpdateType,
} from 'store/project/types';
import type { User } from 'store/user/types';
import { Block } from 'components/Containers';
import Dialog from 'components/Dialog';
import DateSelect from 'components/DateSelect';
import Input from 'components/Input';

type Props = {
  open: boolean,
  project: Project,
  milestone: Milestone,
  updateType: MilestoneUpdateType,
  currentUser: User,
  updateMilestone: MilestoneUpdatePayload => void,
  onClose: () => void,
  onError: () => void,
  onSuccess: () => void,
};

export default function MilestoneCompleteDialog(props: Props) {
  const {
    currentUser,
    milestone,
    open,
    onClose,
    onError,
    onSuccess,
    project,
    updateMilestone,
    updateType,
  } = props;

  const forecast = startOfDay(milestone.forecast);

  const getPayload = (values: Object) => ({
    projectId: project.id,
    milestoneId: milestone.id,
    userId: currentUser.id,
    type: updateType,
    milestone: {
      ...milestone,
      actual: values.actual,
    },
    why: values.why,
  });

  const handleValidation = validationValues => {
    const errors = {};
    const selectedDate = startOfDay(validationValues.actual);

    if (!isEqual(selectedDate, forecast) && !validationValues.why.trim()) {
      errors.why = true;
    }

    return errors;
  };

  const handleSubmit = async (values: Object) => {
    try {
      await updateMilestone(getPayload(values));
      if (onSuccess) onSuccess();
      onClose();
    } catch (err) {
      if (onError) onError();
      onClose();
    }
  };

  const initialValues = { why: '', actual: new Date() };

  if (!milestone) return '';

  return (
    <Dialog
      open={open}
      title="When was this completed?"
      onClose={onClose}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={handleValidation}
    >
      {(values, handleChange) => (
        <>
          <Block margin="0 0 3">
            <DateSelect
              id="completion-date-select-menu"
              label="Completion Date*"
              name="actual"
              value={values.actual}
              onChange={handleChange}
              dayPickerProps={{
                disabledDays: {
                  before: new Date(project.start),
                  after: new Date(project.end),
                },
              }}
            />
          </Block>
          {!isEqual(startOfDay(values.actual), forecast) && (
            <Block>
              <Input
                name="why"
                label="Why*"
                multiline
                placeholder="Enter text here"
                fullWidth
                value={values.why}
                onChange={handleChange}
                maxLength={255}
              />
            </Block>
          )}
        </>
      )}
    </Dialog>
  );
}
