// @flow
import React from 'react';

import {
  MilestoneStatusTypes,
  type MilestoneStatusType,
} from 'store/project/types';
import Icon, { IconButton } from 'components/Icon';

const PLACEHOLDER = 'placeholder';
type Props = {
  status: MilestoneStatusType,
  isComplete: boolean,
  isArchived: boolean,
  onClick?: () => void,
  disabled?: boolean,
  readOnly: boolean,
  size: number,
};

const { GOOD, CLIENT, DIALEXA } = MilestoneStatusTypes;

function getIconColor(status: MilestoneStatusType) {
  switch (status) {
    case CLIENT:
      return 'warning';
    case GOOD:
      return 'green';
    case DIALEXA:
      return 'red';
    case PLACEHOLDER:
      return 'medium-gray';
    default:
      return 'green';
  }
}

function getIconName(
  status: MilestoneStatusType,
  isComplete: boolean,
  isArchived: boolean
) {
  if (isArchived) return 'exit_round';
  if (isComplete) return 'check_round';
  if (status === GOOD || status === PLACEHOLDER) return 'empty_circle';
  return 'error_round';
}

function getIconAlt(
  status: MilestoneStatusType,
  isComplete: boolean,
  isArchived: boolean
) {
  let type = 'incomplete';
  let color = 'no issues';

  if (status === CLIENT) {
    type = 'change';
    color = 'client issue';
  }

  if (status === DIALEXA) {
    type = 'change';
    color = 'dialexa issue';
  }

  if (isComplete) type = 'complete';
  if (isArchived) type = 'archived';

  return `${type}: ${color}`;
}

function MilestonStatus(props: Props) {
  const {
    isArchived,
    isComplete,
    status,
    onClick,
    disabled,
    readOnly,
    size,
  } = props;

  const iconAlt = getIconAlt(status, isComplete, isArchived);
  const iconName = getIconName(status, isComplete, isArchived);
  const iconColor = getIconColor(status);

  const IconComponent = readOnly ? Icon : IconButton;

  return (
    <IconComponent
      alt={iconAlt}
      size={size}
      color={iconColor}
      noPadding
      name={iconName}
      onClick={onClick}
      disabled={disabled}
    />
  );
}

MilestonStatus.defaultProps = {
  status: GOOD,
  isComplete: false,
  isArchived: false,
  size: 26,
};

export default MilestonStatus;
