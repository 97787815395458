import get from 'lodash.get';
import isEmpty from 'lodash/isEmpty';
import { createSelector } from 'reselect';

import { getProjectData } from './project';
import type { Project, ProjectReport } from './types';

import { getGlobalSettings } from '../../globalSettings/selectors';
import type { GlobalSettings } from '../../globalSettings/types';

const selectedReport = (project: Project) =>
  project && project.reports && project.reports.find(r => r.isSelected);
const allReports = (project: Project) =>
  project &&
  project.reports &&
  project.reports.sort((a, b) => a.reportDate.localeCompare(b.reportDate));
const reportDate = (report: ProjectReport) => report && report.reportDate;
const isCurrentWeek = (report: ProjectReport) => report && report.isCurrentWeek;
const isEditable = (
  project: Project,
  isCurrentWk: boolean,
  globalSettings: GlobalSettings
) =>
  (isCurrentWk ||
    get(
      globalSettings,
      'allowUsersToEditPastReportsForHolidaysOrIfTheyForgot'
    )) &&
  !project.archive;

const weekNo = (report: ProjectReport) => report && report.weekNo;
const reportComments = (report: ProjectReport) => report && report.comments;
const reportUpdates = (report: ProjectReport) => report && report.updates;
const reportStatus = (report: ProjectReport) => {
  if (report && report.statuses && !isEmpty(report.statuses)) {
    return report.statuses;
  }
  return get(report, 'content.statuses', {});
};

export const getAllReports = createSelector(getProjectData, allReports);

export const getProjectReport = createSelector(getProjectData, selectedReport);

export const getProjectReportDate = createSelector(
  getProjectReport,
  reportDate
);

export const getProjectReportCurrentWeek = createSelector(
  getProjectReport,
  isCurrentWeek
);

export const getProjectEditable = createSelector(
  getProjectData,
  getProjectReportCurrentWeek,
  getGlobalSettings,
  isEditable
);

export const getProjectReportWeekNo = createSelector(getProjectReport, weekNo);

export const getProjectReportComments = createSelector(
  getProjectReport,
  reportComments
);

export const getProjectReportUpdates = createSelector(
  getProjectReport,
  reportUpdates
);

export const getProjectReportStatus = createSelector(
  getProjectReport,
  reportStatus
);
