// @flow
import React from 'react';

import reducer, { initialState } from './reducer';
import {
  archiveUser,
  fetchUsers,
  updateUserRole,
  unarchiveUser,
} from './actions';
import type { UsersState, UsersAction } from '../types';

type Props = {
  children?: React$Node,
};

export type ContextProps = {
  ...UsersState,
  ...UsersAction,
};

export const UsersContext = React.createContext<ContextProps>({
  initialState,
  fetchUsers: () => {},
  archiveUser: () => {},
  unarchiveUser: () => {},
  updateUserRole: () => {},
});

export default function ProjectsProvider(props: Props) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const actions = {
    fetchUsers: fetchUsers(dispatch, state),
    archiveUser: archiveUser(dispatch, state),
    unarchiveUser: unarchiveUser(dispatch, state),
    updateUserRole: updateUserRole(dispatch, state),
  };

  return (
    <UsersContext.Provider value={{ ...state, ...actions }}>
      {props.children}
    </UsersContext.Provider>
  );
}

export const UsersConsumer = UsersContext.Consumer;
