import 'whatwg-fetch';
import headers from './headers';
import { getData, checkStatus } from './normalizer';
import { URL } from './base';

export function getActivities(sourceId) {
  return fetch(`${URL}/sources/${sourceId}/activities`, {
    method: 'GET',
    headers: headers(),
  })
    .then(checkStatus)
    .then(getData);
}
