import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';

import Authentication from 'routes/Authentication';
import LoginView from 'views/LoginView';

const Routes = () => (
  <Switch>
    <Route exact path="/login" component={LoginView} />
    <Route path="/" component={Authentication} />
    <Redirect from="*" to="/404" />
  </Switch>
);

export default Routes;
