// @flow
import React from 'react';
import { Flex } from 'components/Containers';
import { IconButton } from 'components/Icon';
import Text from 'components/Text';
import { color } from 'styles/constants';
import AlertWrapper from '../styled/AlertWrapper';

type Props = {
  onClose(): void,
  message: string,
  type?: 'success' | 'error' | 'warning' | 'information',
};

export default function Alert(props: Props) {
  const { onClose, message, type } = props;

  function alertType() {
    switch (type) {
      case 'success':
        return color.GREEN;
      case 'error':
        return color.RED;
      case 'warning':
        return color.YELLOW;
      case 'information':
        return color.BLUE;
      default:
        return color.BLUE;
    }
  }

  return (
    <AlertWrapper>
      <Flex
        border={{ left: `3px solid ${alertType()}` }}
        justify="space-between"
        align="flex-start"
        width="255px"
        padding="1 2"
        raised
        bg="white"
      >
        <Flex align="center">
          <Text size="normal" color="black">
            {message}
          </Text>
        </Flex>
        <Flex align="center" margin="0 0 0 1" flex="none" width="auto">
          <IconButton name="exit" color={color.MEDIUM_GRAY} onClick={onClose} />
        </Flex>
      </Flex>
    </AlertWrapper>
  );
}
