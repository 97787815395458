// @flow
import React from 'react';
import DocumentTitle from 'react-document-title';

import type { Project } from 'store/project/types';
import Text from 'components/Text';
import { Screen, Block, Flex } from 'components/Containers';

import ProjectUnusedBudget from './components/ProjectUnusedBudget';
import ProjectTimeline from './components/ProjectTimeline';
import ProjectStatus from './components/ProjectStatus';
import ProjectTeam from './components/ProjectTeam';
import ProjectUpdates from './components/ProjectUpdates';
import ProjectComments from './components/ProjectComments';

type Props = {
  project: Project,
};

export default function ProjectTimelineView({ project }: Props) {
  function renderView() {
    if (!project) {
      return (
        <Flex justify="center" align="center" width="100%" padding="5 5">
          <Text tag="h3" color="typography-medium-gray">
            There was an error fetching project.
          </Text>
        </Flex>
      );
    }

    return (
      <Block margin="5 0 7 0">
        <Flex margin="0 0 4">
          <ProjectUnusedBudget />
        </Flex>
        <Flex margin="0 0 4">
          <ProjectTimeline />
        </Flex>
        <Flex gutter={1} justify="center" width="auto">
          <Block width="260px">
            <Block margin="0 0 4">
              <ProjectStatus />
            </Block>
            <Block>
              <ProjectTeam />
            </Block>
          </Block>
          <Block>
            <Block margin="0 0 4">
              <ProjectUpdates />
            </Block>
            <Block>
              <ProjectComments />
            </Block>
          </Block>
        </Flex>
      </Block>
    );
  }
  return (
    <DocumentTitle title="Project Timeline">
      <Screen>{renderView()}</Screen>
    </DocumentTitle>
  );
}
