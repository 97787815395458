// @flow
import React from 'react';
import Alert from 'components/Alert';
import { Block } from 'components/Containers';
import { AlertConsumer } from './AlertProvider';
import AlertPosition from '../styled';
import Slide from '@material-ui/core/Slide';

export default function AlertContainer() {
  return (
    <AlertPosition>
      <AlertConsumer>
        {({ alerts, closeAlert }) =>
          alerts.map(alert => (
            <Slide
              key={alert.id}
              direction="left"
              timeout={200}
              mountOnEnter
              unmountOnExit
              in
            >
              <Block flex="none" margin="0 0 1 0">
                <Alert onClose={() => closeAlert(alert.id)} {...alert} />
              </Block>
            </Slide>
          ))
        }
      </AlertConsumer>
    </AlertPosition>
  );
}
