// @flow
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Block } from 'components/Containers';
import Dialog from 'components/Dialog';
import MentionsTextArea from 'components/MentionsTextArea';
import type { Action, ProjectReport, Comment } from 'store/project/types';
import type { User } from 'store/user/types';
import { success, error } from 'utils/alerts';
import { useAlert } from 'hooks/useAlert';
import { useSlack } from 'hooks/useSlack';
import { parseCommentMarkup } from 'helpers/formatters';

type Props = {
  open: boolean,
  currentUser: User,
  selectedReport: ProjectReport,
  addCommentItem: (item: { summary: string }, report: ProjectReport) => void,
  updateCommentItem: (
    item: { id: string, summary: string },
    report: ProjectReport
  ) => void,
  onConfirm: () => void,
  onClose: () => void,
  action: Action,
  method: 'add' | 'edit',
  comment: Comment,
};

const today = new Date();

export function CommentDialog(props: Props) {
  const {
    addCommentItem,
    updateCommentItem,
    currentUser,
    open,
    onClose,
    onConfirm,
    selectedReport,
    action,
    method,
  } = props;

  const [employeesMentioned, setEmployeesMentioned] = useState([]);
  const [isOpen, setIsOpen] = useState();
  const { createAlert } = useAlert();
  const { employees, sendMessage } = useSlack();

  useEffect(() => {
    setIsOpen(open);
  });

  useEffect(() => {
    if (open) {
      const textField = document.querySelector('.mentions__input');
      // this check is for the tests
      if (textField) {
        textField.focus();
      }
    }
    return () => {
      setIsOpen(false);
    };
  }, [isOpen]);

  const initialValues = () => {
    const { comment = {} } = props;
    return {
      summary: comment.summary || '',
      updatedAt: comment.today || '',
      userId: comment.userId || '',
    };
  };

  const addComment = async (
    comment,
    employeesToAlert,
    message,
    attachments
  ) => {
    try {
      await addCommentItem(comment, selectedReport);
      await Promise.all([
        employeesToAlert.map(e => sendMessage(e, message, attachments)),
      ]);
      createAlert({
        message: success('comment').add,
        type: 'success',
      });
      onConfirm();
    } catch {
      createAlert({
        message: error('comment').add,
        type: 'error',
      });
      onClose();
    }
  };

  const editComment = async (
    comment,
    employeesToAlert,
    message,
    attachments
  ) => {
    try {
      await updateCommentItem(comment, selectedReport);
      await Promise.all([
        employeesToAlert.map(e => sendMessage(e, message, attachments)),
      ]);
      createAlert({
        message: success('comment').edit,
        type: 'success',
      });
      onConfirm();
    } catch (e) {
      createAlert({
        message: error('comment').edit,
        type: 'error',
      });
      onClose();
    }
  };

  function handleValidation(validationValues) {
    const errors = {};

    if (!validationValues.summary.trim()) {
      errors.summary = true;
    }

    return errors;
  }

  function handleSubmit(submitValues) {
    const { id } = props.comment || {};
    const comment = {
      id,
      summary: submitValues.summary,
    };

    const date = format(today, 'MM/DD/YYYY');
    const name = `${currentUser.firstName} ${currentUser.lastName}`;
    const employeesToAlert = employees.filter(employee =>
      employeesMentioned.includes(employee.real_name)
    );
    const message = `*${name} has tagged you in a comment (${date}):*\n${parseCommentMarkup(
      comment.summary
    )}`;
    const attachments = [
      {
        title: 'Go to Project',
        title_link: `${window.location.href}`,
      },
    ];
    if (method === 'add')
      addComment(comment, employeesToAlert, message, attachments);
    else if (method === 'edit')
      editComment(comment, employeesToAlert, message, attachments);
  }

  const handleMentionsChange = handleChange => (event, mentions) => {
    handleChange(event);
    setEmployeesMentioned(mentions);
  };
  return (
    <Dialog
      isLoading={action.isLoading}
      open={open}
      title={method === 'add' ? 'Add Comment' : 'Edit Comment'}
      initialValues={initialValues()}
      isInitialValid={false}
      validate={handleValidation}
      onClose={onClose}
      onSubmit={handleSubmit}
      confirmText="Save"
    >
      {(values, handleChange) => (
        <Block>
          <MentionsTextArea
            className="mentions"
            data={employees.map(employee => ({
              id: employee.id,
              display: employee.real_name,
            }))}
            fullWidth
            initialValue={values.summary}
            label="Summary"
            maxLength={255}
            name="summary"
            onChange={handleMentionsChange(handleChange)}
            placeholder="Begin typing here"
            value={values.summary}
          />
        </Block>
      )}
    </Dialog>
  );
}
