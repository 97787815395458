// @flow
import React from 'react';
import { isPast, isToday } from 'date-fns';

import Card from 'components/Card';
import Chip from 'components/Chip';
import List from 'components/List';
import Text from 'components/Text';
import { Flex } from 'components/Containers';
import type {
  Unassigned,
  UnassignedMember,
} from 'providers/ProjectTeamProvider/types';

type Props = {
  unassigned: Unassigned,
};

const isPastDay = (endDate: string) => isPast(endDate) && !isToday(endDate);
const formatAllocation = (data: UnassignedMember) => {
  const mode = data.allocationMode;
  if (mode === 'percent') return `${data.percent}%`;
  if (mode === 'fixed') return `${data.fixed} hrs`;
  if (mode === 'hours_per_day') return `${data.hoursPerDay} hrs`;
  return 'Vacation';
};

export default function ProjectTeamUnassignedContent(props: Props) {
  const { error, data } = props.unassigned;

  if (error) {
    return (
      <Card.Content>
        <Flex fullHeight padding="5 0" align="center" justify="center">
          <Text align="center" color="typography-medium-gray">
            There was an error processing the unused budget data.
          </Text>
        </Flex>
      </Card.Content>
    );
  }

  return (
    <Card.Content>
      <List
        items={data.map((t, idx) => (
          <Flex align="center" key={idx}>
            <Flex item xs={5}>
              <Text size="normal">{t.name}</Text>
            </Flex>
            <Flex item xs={2}>
              <Text size="normal">{t.days}</Text>
            </Flex>
            <Flex item xs={2}>
              <Text size="normal">{formatAllocation(t)}</Text>
            </Flex>
            <Flex item xs={3}>
              {isPastDay(t.end) ? (
                <Chip appearance="error" label="Missed" />
              ) : (
                <Text size="normal">Unassigned</Text>
              )}
            </Flex>
          </Flex>
        ))}
      />
    </Card.Content>
  );
}
