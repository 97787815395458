import styled from 'styled-components';
import { color, spacing, fontSize } from 'styles/constants';

export const Pill = styled.button`
  text-transform: uppercase;
  margin: ${spacing('normal', 0, 'large')};
  padding: ${spacing('small', 'normal')};
  border-radius: 100px;
  font-size: ${fontSize.xsmall};
  background-color: ${props => (props.light ? color.GREEN : color.GRAY)};
  color: ${color.WHITE};
  letter-spacing: 0.15rem;
`;

export const OpenPill = styled(Pill)`
  background-color: ${color.YELLOW};
`;

export const InProgressPill = styled(Pill)`
  background-color: ${color.BLUE};
`;
