// @flow
import React from 'react';
import Icon, { type Props as IconProps } from './Icon';
import { IconButtonWrapper } from '../styled';
import { color } from 'styles/constants';

export type Props = {
  ...IconProps,
  buttonRef: React$Node,
  disabled?: boolean,
  onClick: () => void,
  selected?: boolean,
  noPadding?: boolean,
  alt: string,
};

function IconButton(props: Props) {
  const { onClick, disabled, selected, noPadding, alt, ...iconProps } = props;

  function handleClick(e: SyntheticMouseEvent<any>) {
    if (disabled) return;
    if (onClick) onClick(e);
  }

  return (
    <IconButtonWrapper
      disabled={disabled}
      onClick={handleClick}
      color={selected ? color.BLUE : iconProps.color}
      noPadding={noPadding}
      alt={alt}
      ref={props.buttonRef}
    >
      <Icon {...iconProps} />
    </IconButtonWrapper>
  );
}

IconButton.defaultProps = {
  color: color.MEDIUM_GRAY,
};

export default IconButton;
