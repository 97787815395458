// @flow
import React from 'react';

import { Block, Flex } from 'components/Containers';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { color } from 'styles/constants';
import { HamburgerButton } from '../styled';

type Props = {
  onClose: () => void,
};

export default function DrawerHeader(props: Props) {
  return (
    <Flex align="center" justify="space-between" margin="0 0 6">
      <Flex gutter={1} align="center">
        <Block flex="none">
          <Icon
            name="engage"
            alt="engage logo"
            color={color.ENGAGE_BLUE}
            size={35}
            viewBox="0 0 35 31"
          />
        </Block>
        <Block>
          <Text size="medium" weight={600} color={color.WHITE}>
            ENGAGE
          </Text>
        </Block>
      </Flex>
      <Block flex="none">
        <HamburgerButton onClick={props.onClose}>
          <Icon name="chevron_left" size={20} color={color.WHITE} />
          <Icon name="hamburger" size={35} color={color.WHITE} />
        </HamburgerButton>
      </Block>
    </Flex>
  );
}
