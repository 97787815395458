// @flow
import React, { Component } from 'react';
import type { JiraProject } from '../../types';
import Meta from '../Meta';
import Chart from '../Chart';
import Title from '../Title';
import Card from 'components/DashboardCard';
import Sprint from '../Sprint';
import Version from '../Version';
import UpcomingSprints from '../UpcomingSprints';
import Bugs from '../Bugs';
import RemainingBugs from '../RemainingBugs';
import Concerns from '../Concerns';

const BASE_STICKY_STYLE = {
  position: 'fixed',
  top: '98px',
};

type Props = {
  ...JiraProject,
};

type State = {
  titleIsSticky: boolean,
  left: number,
  width: number,
};

class Project extends Component<Props, State> {
  cardNode: HTMLDivElement | null;

  constructor(props: Props) {
    super(props);
    this.cardNode = null;

    this.state = {
      titleIsSticky: false,
      left: 0,
      width: 0,
    };
  }

  shouldComponentUpdate() {
    const nextState = this.computeTitleIsSticky(this.cardNode);
    if (!nextState) {
      return false;
    }

    this.setState(nextState);
    return true;
  }

  computeTitleIsSticky = (el?: HTMLDivElement | null) => {
    if (!el) return false;

    const HEADER_HEIGHT = 100;
    const scrolledPastTop = el.getBoundingClientRect().top - HEADER_HEIGHT <= 0;
    const outOfView =
      el.getBoundingClientRect().bottom - HEADER_HEIGHT * 2 - 20 <= 0;
    const elLeft = el.getBoundingClientRect().left;
    const elWidth = el.getBoundingClientRect().width;

    if (scrolledPastTop && !outOfView && !this.state.titleIsSticky) {
      return {
        titleIsSticky: true,
        left: elLeft,
        width: elWidth,
      };
    } else if (scrolledPastTop && outOfView && this.state.titleIsSticky) {
      return {
        titleIsSticky: false,
      };
    } else if (this.state.titleIsSticky && !scrolledPastTop) {
      return {
        titleIsSticky: false,
      };
    }

    return false;
  };

  computeStickyStyle() {
    const { left, titleIsSticky, width } = this.state;
    if (titleIsSticky) {
      return {
        ...BASE_STICKY_STYLE,
        left,
        width,
      };
    }

    return {};
  }

  isDisplayed = (value: string) => {
    if (this.props.sectionsDisplayed) {
      return this.props.sectionsDisplayed[value];
    }
    return true;
  };

  render() {
    return (
      <div
        ref={c => {
          this.cardNode = c;
        }}
      >
        <Card>
          <Title
            text={this.props.name}
            label={this.props.label}
            quality={this.props.qualityKpi}
            style={this.computeStickyStyle()}
          />
          <Chart
            status={this.props.status}
            start={this.props.start}
            end={this.props.end}
            displayBudget={this.isDisplayed('budget')}
            budget={this.props.budget}
          />
          <Meta
            start={this.props.start}
            end={this.props.end}
            lead={this.props.lead}
          />
          {this.props.concerns && <Concerns {...this.props.concerns} />}
          {this.props.sprint && (
            <Sprint
              sprint={this.props.sprint}
              measurement={this.props.measurement}
            />
          )}
          {this.props.version && (
            <Version
              version={this.props.version}
              measurement={this.props.measurement}
            />
          )}
          {this.props.upcomingSprints && (
            <UpcomingSprints
              sprints={this.props.upcomingSprints}
              backlog={this.props.backlog}
              measurement={this.props.measurement}
            />
          )}
          {this.props.bugs && (
            <div>
              <Bugs {...this.props.bugs} />
              <RemainingBugs
                open={this.props.bugs.open}
                bugs={this.props.bugs.categories}
                total={this.props.bugs.total}
                remaining={this.props.bugs.remaining}
              />
            </div>
          )}
        </Card>
      </div>
    );
  }
}

export default Project;
