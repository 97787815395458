import { createSelector } from 'reselect';
import { PROJECT_STATE } from '../constants';
import type { State } from './reducer';
import type { Project } from './types';

const inProject = (state: State) => state.project;
const isLoading = (project: Project) => project.loading;
const isError = (project: Project) => project.error;
const data = (project: Project) => project.data;
const totalWeeks = (project: Project) => project && project.totalWeeks;

export const getProject = createSelector(PROJECT_STATE, inProject);

export const projectIsLoading = createSelector(getProject, isLoading);

export const projectIsError = createSelector(getProject, isError);

export const getProjectData = createSelector(getProject, data);
export const getProjectTotalWeeks = createSelector(getProjectData, totalWeeks);
