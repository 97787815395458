// @flow
import React from 'react';
import { DialogConsumer } from './DialogProvider';
import Dialog from 'components/Dialog';

export default function DialogContainer() {
  return (
    <DialogConsumer>
      {({ dialog }) => {
        const { content, ...rest } = dialog;
        return <Dialog {...rest}>{content}</Dialog>;
      }}
    </DialogConsumer>
  );
}
