// @flow
import React from 'react';
import { withRouter, type ContextRouter } from 'react-router-dom';
import DocumentTitle from 'react-document-title';

import type { Project, ProjectReport } from 'store/project/types';
import type { User } from 'store/user/types';

import { TOKEN_KEY } from 'services/keys';
import { URL } from 'services/base';

import ProjectDetailsCard from './components/ProjectDetailsCard';

import LoaderView from 'views/LoaderView';
import { Screen, Block } from 'components/Containers';

import { JiraLinkDialog } from 'dialogs/JiraLinkDialog';

type Props = {
  ...ContextRouter,
  project: Project,
  isLoading: boolean,
  reports: Array<ProjectReport>,
  user: User,
  fetchProject: (id: string) => void,
};

function ProjectDetailsView(props: Props) {
  const {
    fetchProject,
    match,
    // eslint-disable-next-line no-unused-vars
    reports,
    user,
    project,
    isLoading,
  } = props;

  function onProjectDetailsMount() {
    const { projectId } = match.params;

    if (projectId && (!project || project.id !== projectId)) {
      fetchProject(projectId);
    }
  }

  React.useEffect(() => {
    onProjectDetailsMount();
  }, []);

  function renderPage() {
    if (isLoading) return <LoaderView />;
    if (!user.authenticated) {
      return (
        <JiraLinkDialog
          open
          onConfirm={() =>
            window.location.assign(
              `${URL}/auth3p/jira/authenticate?token=${localStorage.getItem(
                TOKEN_KEY
              )}`
            )
          }
        />
      );
    }

    return (
      <Screen>
        <ProjectDetailsCard {...project} />
      </Screen>
    );
  }

  return (
    <DocumentTitle title="Project Details">
      <Block>{renderPage()}</Block>
    </DocumentTitle>
  );
}

export default withRouter(ProjectDetailsView);
