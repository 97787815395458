// @flow
import React, { useState, useEffect } from 'react';
import { getAuthStatus } from 'services/auth';
import { TOKEN_KEY } from 'services/keys';
import { Redirect, Route, Switch, type ContextRouter } from 'react-router-dom';
import type { User } from 'store/user';
import LoaderView from 'views/LoaderView';
import Admin from 'routes/Admin';
import Dashboard from 'views/Dashboard';
// import DashboardView from 'views/DashboardView';
import LinkView from 'views/LinkView';
import NotFoundView from 'views/NotFoundView';

type Props = {
  ...ContextRouter,
  user: User,
  getCurrentUser: () => void,
  getGlobalSettings: () => void,
};

export default function Authentication(props: Props) {
  const { user, getCurrentUser, getGlobalSettings } = props;
  const [authLoading, setAuthLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);

  function authenticate() {
    getAuthStatus()
      .then(res => {
        if (!res.authenticated) localStorage.removeItem(TOKEN_KEY);
        setAuthenticated(res.authenticated);
        setAuthLoading(false);
      })
      .then(() => getCurrentUser())
      .then(() => getGlobalSettings());
  }

  useEffect(() => {
    authenticate();
  }, [user && user.authenticated]);

  if (authLoading) return <LoaderView />;
  if (!authenticated) return <Redirect to="/login" />;
  if (!user) return <LoaderView />;

  return (
    <Switch>
      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/links" component={LinkView} />
      <Route path="/admin" component={Admin} />
      <Route exact path="/404" component={NotFoundView} />
      <Route exact path="/" render={() => <Redirect to="/admin/projects" />} />
      <Redirect from="*" to="/404" />
    </Switch>
  );
}
