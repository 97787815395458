// @flow
import React from 'react';

import Card from 'components/Card';
import Button from 'components/Button';
import Text from 'components/Text';
import { Block, Flex } from '/components/Containers';

type Props = {
  tenKId: number,
};

const tenThousandFeetRoute = (tenKId: number) =>
  `https://app.10000ft.com/projects/${tenKId}/plan`;

export default function ProjectTeamUnassignedHeader(props: Props) {
  const { tenKId } = props;

  return (
    <Card.Header
      title={
        <Flex align="center" height="40px">
          <Flex item xs={5}>
            <Flex gutter={2} align="center">
              <Block flex="none">
                <Text color="black" weight={600} tag="h3">
                  Unused Budget
                </Text>
              </Block>
              <Block flex="none">
                <Button
                  onClick={() =>
                    window.open(tenThousandFeetRoute(tenKId), '_blank')
                  }
                >
                  10000ft
                </Button>
              </Block>
            </Flex>
          </Flex>
          <Flex item xs={2}>
            <Text color="typography-medium-gray">Days</Text>
          </Flex>
          <Flex item xs={2}>
            <Text color="typography-medium-gray">Allocation</Text>
          </Flex>
          <Flex item xs={3} padding="0 2">
            <Text color="typography-medium-gray">Type</Text>
          </Flex>
        </Flex>
      }
    />
  );
}
