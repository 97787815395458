// @flow
import React, { Component } from 'react';
import type { Project as ProjectType } from 'store/project';
import type { JiraActivity, JiraProject } from './types';
import DocumentTitle from 'react-document-title';
import Project from './components/Project';
import Wrapper from 'components/Wrapper';
import { Masonry, MasonryItem } from 'components/Masonry';
import Alert from 'components/Alert/__depracated/Alert';
import Loading from 'components/Loading';
import Top from './components/Top';
import Bottom from './components/Bottom';
import { getSources } from 'services/source';
import { getProjects, getJiraProjectByKey } from 'services/project';
import { getActivities } from 'services/activity';
import dialexaLogo from 'assets/images/dialexa-logo-white.png';
import './style.scss';

const initialSource = {
  end: null,
  key: '',
  label: '',
  lead: {},
  measurement: '',
  name: '',
  projectKey: '',
  qualityKpi: '',
  sprint: null,
  start: null,
  status: 'blank',
  type: '',
};

type Props = {
  jira: {
    isAuthenticated: boolean,
  },
  checkAuth: () => Promise<any>,
};

type State = {
  sourceId: '',
  projects: Array<JiraProject>,
  activities: Array<JiraActivity>,
  message: string,
  isFetching: boolean,
  contentAnimation: {
    MozAnimation: Object,
    WebkitAnimation: Object,
  } | null,
};

class Dashboard extends Component<Props, State> {
  pollMs: number;
  poll: func;

  constructor(props: Props) {
    super(props);
    this.pollMs = 1000 * 60 * 60 * 1; // 1 hour

    this.state = {
      sourceId: '',
      projects: [],
      activities: [],
      message: '',
      isFetching: true,
      contentAnimation: null,
    };
  }

  componentDidMount() {
    // Need a better way to do this
    if (!this.props.jira.isAuthenticated) {
      this.props.checkAuth().then(() => {
        this.init();
        this.poll = setInterval(() => this.renderDashboard(), this.pollMs);
      });
    } else {
      this.init();
      this.poll = setInterval(() => this.renderDashboard(), this.pollMs);
    }
  }

  componentWillUnmount() {
    clearInterval(this.poll);
  }

  getHeight(element: HTMLBaseElement) {
    if (element && !this.state.contentAnimation) {
      const height = element.clientHeight;
      const vh = window.innerHeight;
      if (!(height <= vh - 300) && height <= vh * 1.75) {
        const animation = `contentBounce ${height / 40}s linear infinite`;
        this.setState({
          contentAnimation: {
            MozAnimation: animation,
            WebkitAnimation: animation,
            animation,
          },
        });
      } else if (!(height <= vh - 300)) {
        const animation = `contentScroll ${height / 25}s linear infinite`;
        this.setState({
          contentAnimation: {
            MozAnimation: animation,
            WebkitAnimation: animation,
            animation,
          },
        });
      }
    }
  }

  getJiraProject = async (project: ProjectType) => {
    const { sourceId } = this.state;
    let jiraProject;
    try {
      jiraProject = await getJiraProjectByKey(sourceId, project.key);
      return jiraProject;
    } catch (err) {
      jiraProject = {
        ...initialSource,
        ...project,
        budget: {
          total: project.totalBudget,
          spent: project.spentBudget,
        },
      };
      return jiraProject;
    }
  };

  getAllJiraProjectCalls(projects: Array<ProjectType>) {
    const { sourceId } = this.state;
    return projects
      .filter(p => p.sourceId === sourceId)
      .map(this.getJiraProject);
  }

  getAllActivites = async (sourceId: string) => {
    let activities = [];
    try {
      activities = await getActivities(sourceId);
      return activities;
    } catch (e) {
      this.displayAlert('Error fetching Jira activities', 5000);
      return activities;
    }
  };

  async init() {
    const sources = await getSources();
    const jiraSource = sources.find(s => s.name.toLowerCase().includes('jira'));
    this.setState({ sourceId: jiraSource.id });
    this.renderDashboard();
  }

  displayAlert(message: string, timeout?: number) {
    this.setState({ message });

    if (timeout) {
      setTimeout(this.closeAlert, timeout);
    }
  }

  closeAlert = () => {
    this.setState({ message: '' });
  };

  renderDashboard = async () => {
    const filters = { visible: true, archive: false };
    const { sourceId } = this.state;

    const projects = await getProjects(filters);
    const allJiraProjectCalls = this.getAllJiraProjectCalls(projects);
    const jiraProjects = await Promise.all(allJiraProjectCalls);
    const activities = await this.getAllActivites(sourceId);

    this.setState({
      activities,
      isFetching: false,
      projects: jiraProjects,
    });
  };

  render() {
    const hasContent = this.state.projects.length;
    const shouldShowLoading = this.state.isFetching && !this.state.message;

    return (
      <DocumentTitle title="Dashboard">
        <Wrapper default column>
          <Top logo={dialexaLogo} title="dialexa" />
          {shouldShowLoading && <Loading />}

          {hasContent && !shouldShowLoading && (
            <div>
              <div
                ref={this.getHeight.bind(this)}
                style={this.state.contentAnimation}
                className="Dashboard-content"
              >
                <Masonry>
                  {this.state.projects.map((project, i) => (
                    <MasonryItem key={i}>
                      <Project {...project} />
                    </MasonryItem>
                  ))}
                </Masonry>
              </div>

              <section className="Dashboard-activity">
                <Bottom activities={this.state.activities} />
              </section>
            </div>
          )}

          {this.state.message && (
            <Alert
              isError
              message={this.state.message}
              close={this.closeAlert}
            />
          )}
        </Wrapper>
      </DocumentTitle>
    );
  }
}

export default Dashboard;
