// @flow
import React, { createContext, useState, useEffect } from 'react';
import { getSlackEmployees, sendSlackMessage } from 'services/slack';
import { Action, Attachment, Employee } from '../types';

type Props = {
  children?: React$Node,
};

export type ContextProps = {
  employees: Employee[],
  action: Action,

  sendMessage: (
    employee: Employee,
    text: string,
    attachments: Attachment[]
  ) => Promise<void>,
};

const initialAction = {
  loading: false,
  error: null,
  completed: false,
};

export const SlackContext = createContext<ContextProps>({
  employees: [],
  action: initialAction,
  sendMessage: async () => {},
});

export default function SlackProvider(props: Props) {
  const [employees, setEmployees] = useState([]);
  const [action, setAction] = useState(initialAction);

  const actions = {
    sendMessage: async (
      employee: Employee,
      text: string,
      attachments: Attachment[]
    ) => {
      try {
        setAction({ loading: true, error: null, completed: false });
        await sendSlackMessage(employee, text, attachments);
        setAction({ loading: false, error: null, completed: true });
      } catch (error) {
        setAction({ loading: false, error, completed: false });
      }
    },
  };

  async function fetchEmployees() {
    try {
      const result = await getSlackEmployees();
      setEmployees(result);
    } catch (error) {
      setEmployees([]);
    }
  }

  useEffect(() => {
    fetchEmployees();
  }, []);

  return (
    <SlackContext.Provider value={{ employees, action, ...actions }}>
      {props.children}
    </SlackContext.Provider>
  );
}

export const SlackConsumer = SlackContext.Consumer;
