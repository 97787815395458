import React from 'react';
import PropTypes from 'prop-types';
import Counter from 'components/Counter';
import { pluralize } from 'helpers/formatters/';
import './style.scss';

const RemainingBugs = props => {
  return (
    <div className="RemainingBugs">
      <div className="RemainingBugs-title">
        <div className="RemainingBugs-num">
          {`Remaining Bugs (${props.open}):`}
        </div>
      </div>
      <div className="RemainingBugs-details">
        {props.bugs.map(
          (bug, idx) =>
            bug && (
              <div key={idx}>
                <div className="RemainingBugs-group-title" key={idx}>
                  {bug.name}
                </div>
                <Counter
                  index={idx}
                  type="error"
                  count={bug.count}
                  desc={pluralize(bug.count, 'issues')}
                />
              </div>
            )
        )}
        <div>
          <div className="RemainingBugs-group-title">Other</div>
          <Counter
            type="default"
            desc={pluralize(props.open, 'issues')}
            count={props.remaining}
          />
        </div>
      </div>
    </div>
  );
};

RemainingBugs.propTypes = {
  bugs: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.number,
  remaining: PropTypes.number,
};

export default RemainingBugs;
