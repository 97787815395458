import styled, { css } from 'styled-components';
import { spacing } from 'styles/constants';
import { darken } from 'polished';
import { appearanceToColor } from 'styles/constants';

const intensity = 0.1;

const actionStyles = props => {
  if (props.disabled) {
    return css`
      opacity: 0.5;
      cursor: default;
    `;
  }

  if (props.color) {
    return css`
      & svg path {
        fill: ${appearanceToColor(props.color)};
        transition: fill 200ms ease-in-out;
      }

      &:hover svg path {
        fill: ${darken(intensity, appearanceToColor(props.color))};
      }
    `;
  }

  return null;
};

export const IconButtonWrapper = styled.button.attrs(props => ({
  'aria-label': props.alt,
}))`
  position: relative;
  background: transparent;
  border: none;
  outline: 0;
  display: inline-flex;
  padding: ${spacing('small', 'small')};
  cursor: pointer;

  ${actionStyles};
  ${props =>
    props.noPadding &&
    css`
      padding: 0;
    `};
`;

export const Svg = styled.svg`
  display: inline-flex;
  vertical-align: middle;
  fill: ${props => appearanceToColor(props.color)};
`;
