import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getCurrentUser, getProjectReport, getAction } from 'store/selectors';

import { addCommentItem, updateCommentItem } from 'store/project/actions';

import { CommentDialog } from './presenter';

function mapStateToProps(state) {
  return {
    action: getAction(state),
    currentUser: getCurrentUser(state),
    selectedReport: getProjectReport(state),
  };
}

function mapActionsToProps(dispatch) {
  return bindActionCreators(
    {
      addCommentItem,
      updateCommentItem,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapActionsToProps)(CommentDialog);
