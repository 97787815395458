// @flow
import type { ProjectTeamState, Actions } from '../types';
import * as PT from './constants';

export const initialState = {
  assigned: PT.ASSIGNED,
  unassigned: PT.UNASSIGNED,
};

export default (state: ProjectTeamState, action: Actions) => {
  switch (action.type) {
    case PT.FETCH_ASSIGNED_START:
      return {
        ...state,
        assigned: {
          data: [],
          error: null,
          loading: true,
        },
      };
    case PT.FETCH_ASSIGNED_ERROR:
      return {
        ...state,
        assigned: {
          data: [],
          error: action.payload,
          loading: false,
        },
      };
    case PT.FETCH_ASSIGNED_FULFILLED:
      return {
        ...state,
        assigned: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    case PT.FETCH_UNASSIGNED_START:
      return {
        ...state,
        unassigned: {
          data: [],
          error: null,
          loading: true,
        },
      };
    case PT.FETCH_UNASSIGNED_ERROR:
      return {
        ...state,
        unassigned: {
          data: [],
          error: action.payload,
          loading: false,
        },
      };
    case PT.FETCH_UNASSIGNED_FULFILLED:
      return {
        ...state,
        unassigned: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    case PT.RESET_PROJECT_TEAM:
      return initialState;
    default:
      return initialState;
  }
};
