// @flow
import React from 'react';
import DocumentTitle from 'react-document-title';
import { StringParam, useQueryParams } from 'use-query-params';
import Card from 'components/Card';
import { Screen, Block } from 'components/Containers';
import ProjectMilestoneListHeader from './components/ProjectMilestoneListHeader/';
import ProjectMilestoneListContent from './components/ProjectMilestoneListContent/';

export default function ProjectMilestoneListView() {
  const [
    { dateSort = 'descending', statusFilter = 'active' },
    setQuery,
  ] = useQueryParams({
    dateSort: StringParam,
    statusFilter: StringParam,
  });

  return (
    <DocumentTitle title="Project Milestone List">
      <Screen>
        <Block margin="5 0">
          <Card>
            <ProjectMilestoneListHeader
              milestonesFilter={statusFilter}
              dateSort={dateSort}
              onFilterChange={e => setQuery({ statusFilter: e.target.value })}
              onSortChange={e => setQuery({ dateSort: e.target.value })}
            />
            <ProjectMilestoneListContent
              milestonesFilter={statusFilter}
              milestonesDateSort={dateSort}
            />
          </Card>
        </Block>
      </Screen>
    </DocumentTitle>
  );
}
