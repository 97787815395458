// @flow
import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import type { ReportStatus } from 'store/project/types';
import Card from 'components/Card';
import { Block, Flex } from 'components/Containers';
import Text from 'components/Text';
import { SingleStatus } from 'components/Status/index';
import Menu from 'components/Menu';
import StatusEditDialog from 'dialogs/ProjectDialogs/StatusEditDialog/index';

type Props = {
  reportStatus: ReportStatus,
  editable: boolean,
  weekNo: number,
};

export default function ProjectStatus(props: Props) {
  const { editable, weekNo, reportStatus } = props;

  const [showEditDialog, setShowEditDialog] = useState(false);

  function renderContent() {
    const statusTypes = ['project', 'client', 'growth']; // Order for displaying statuses

    if (isEmpty(reportStatus)) {
      return (
        <Text align="center" color="typography-medium-gray" weight={600}>
          No Statuses to display
        </Text>
      );
    }
    return (
      <Flex gutter={2} direction="column" align="center">
        {statusTypes.map((status, index) => (
          <Flex
            key={index}
            align="center"
            gutter={1}
            hasBorder
            raised
            padding="3"
            direction="column"
          >
            <Flex align="center">
              <Block flex="0 1 35px">
                <SingleStatus
                  type={status}
                  status={reportStatus[status] && reportStatus[status].status}
                  size={24}
                />
              </Block>
              <Block flex="0 1 auto">
                <Text weight={600} size="medium" transform="capitalize">
                  {status}
                </Text>
              </Block>
            </Flex>
            {reportStatus[status] && reportStatus[status].comment && (
              <Flex>
                <Text size="normal" color="grey" wordbreak="break-word">
                  {reportStatus[status].comment}
                </Text>
              </Flex>
            )}
          </Flex>
        ))}
      </Flex>
    );
  }
  return (
    <>
      <Card>
        <Card.Header
          title={
            <Flex height="40px" align="center">
              <Text tag="h3" weight={600}>
                Week {weekNo} Status
              </Text>
            </Flex>
          }
          rightHeader={
            editable && (
              <Menu
                data-testid="status-menu"
                iconButtonProps={{ noPadding: true, alt: 'menu' }}
                items={[
                  {
                    name: 'Edit',
                    color: 'primary',
                    action: () => setShowEditDialog(true),
                  },
                ]}
              />
            )
          }
        />
        <Card.Content>{renderContent()}</Card.Content>
      </Card>
      <StatusEditDialog
        open={showEditDialog}
        onConfirm={() => setShowEditDialog(false)}
        onClose={() => setShowEditDialog(false)}
      />
    </>
  );
}
