// @flow
import React, { forwardRef } from 'react';
import Grid from '@material-ui/core/Grid';
import StyledFlex from '../styled/Flex';

type Props = {
  children?: React$Node,
  bg?: 'blue' | 'white' | 'faux-white' | 'off-black',
  border?: string | object,
  circle?: boolean,
  align?: 'flex-start' | 'flex-end' | 'center' | 'stretch',
  justify?: 'space-between' | 'center' | 'space-around',
  direction?: 'row' | 'column' | 'column-reverse' | 'row-reverse',
  container?: boolean,
  item?: boolean,
  lg?: false | 'auto' | true | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
  md?: false | 'auto' | true | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
  sm?: false | 'auto' | true | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
  xs?: false | 'auto' | true | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
  xl?: false | 'auto' | true | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
  zeroMinWidth?: boolean,
  gutter?: '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7',
  padding?: 'auto' | '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7',
  margin?: 'auto' | '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7',
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse',
  raised?: boolean,
  flex?: string,
  width?: string,
  height?: string,
  fullWidth?: boolean,
  fullHeight?: boolean,
  textAlign?: 'left' | 'center' | 'right',
  hasBorderRadius: boolean,
  position?: 'relative' | 'absolute',
};

const Flex = forwardRef((props: Props, ref) => {
  const {
    align,
    circle,
    justify,
    direction,
    container,
    item,
    gutter,
    lg,
    md,
    sm,
    xs,
    xl,
    bg,
    wrap,
    padding,
    margin,
    zeroMinWidth,
    border,
    children,
    raised,
    flex,
    width,
    height,
    fullWidth,
    fullHeight,
    textAlign,
    hasBorderRadius,
    position,
    ...rest
  } = props;

  function renderFlex(gridProps) {
    return (
      <StyledFlex
        {...gridProps}
        direction={direction}
        gutter={gutter}
        padding={padding}
        border={border}
        circle={circle}
        margin={margin}
        raised={raised}
        flex={flex}
        width={width}
        height={height}
        fullWidth={fullWidth}
        fullHeight={fullHeight}
        textAlign={textAlign}
        hasBorderRadius={hasBorderRadius}
        position={position}
        ref={ref}
      />
    );
  }

  return (
    <Grid
      alignItems={align}
      justify={justify}
      direction={direction}
      container={container}
      item={item}
      lg={lg}
      md={md}
      sm={sm}
      xs={xs}
      xl={xl}
      bg={bg}
      wrap={wrap}
      zeroMinWidth={zeroMinWidth}
      component={renderFlex}
      {...rest}
    >
      {children}
    </Grid>
  );
});

Flex.defaultProps = {
  direction: 'row',
  container: true,
  wrap: 'nowrap',
};

export default Flex;
