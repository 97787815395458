// @flow
import React from 'react';
import Icon from 'components/Icon';
import { Block } from 'components/Containers';
import { color } from 'styles/constants';
import ItemWrapper from '../styled/ItemWrapper';

type Props = {
  selected?: boolean,
  disabled?: boolean,
  children: React$Node,
  onClick?: (e: SyntheticMouseEvent<HTMLElement>) => void,
};

export default class Item extends React.Component<Props> {
  handleClick = e => {
    if (this.props.selected || this.props.disabled) return;
    if (!this.props.onClick) return;
    this.props.onClick(e);
  };

  render() {
    const {
      selected,
      disabled,
      children,
      // eslint-disable-next-line no-unused-vars
      onClick,
      ...rest
    } = this.props;

    return (
      <ItemWrapper
        disableGutters
        disabled={disabled}
        selected={selected}
        onClick={this.handleClick}
        {...rest}
      >
        {selected ? (
          <Block flex="none">
            <Icon
              color={color.BLUE}
              name="check"
              size={22}
              viewBox="-4 -6 24 24"
            />
          </Block>
        ) : (
          <Block flex="none" width="22px" />
        )}
        {children}
      </ItemWrapper>
    );
  }
}
