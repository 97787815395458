import styled from 'styled-components';
import { color } from 'styles/constants';

const Wrapper = styled.section`
  display: flex;
  flex-direction: ${props => (props.column ? 'column' : 'row')};
  height: 100%;
  width: 100%;
  min-height: 100vh;
  background-color: ${props =>
    props.default ? color.DARK_GRAY : color.LIGHT_GRAY};
`;

export default Wrapper;
