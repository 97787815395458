import { color, spacing } from 'styles/constants';
import Input from '@material-ui/core/Input';
import styled, { css } from 'styled-components';

const errorStyles = css`
  box-shadow: ${color.RED} 0 0 0 2px;
`;

const disabledStyles = css`
  cursor: not-allowed;
  &:focus-within {
    box-shadow: none;
  }
`;

const readOnlyStyles = css`
  cursor: default;
  &:focus-within {
    box-shadow: none;
  }
`;

const inputHeight = props =>
  props.multiline
    ? css`
        align-items: flex-start;
        height: 130px;
      `
    : css`
        align-items: center;
        height: 40px;
      `;

const multiline = props =>
  props.multiline &&
  css`
    overflow-y: auto;
  `;

export default styled(Input)`
  && {
    box-sizing: border-box;
    display: inline-flex;
    cursor: text;
    color: ${color.BLACK};
    border: 1px solid ${color.DISABLED};
    background: ${color.WHITE};
    border-radius: 4px;
    margin: ${spacing(0, 0, 'normal')};
    padding: 8px;
    font-size: 16px;
    transition: all 200ms ease-in-out;

    ${multiline};

    &:focus-within {
      box-shadow: ${color.LIGHT_BLUE} 0 0 0 2px;
    }

    & input,
    textarea {
      display: block;
      border: 0;
      background: transparent;
      cursor: inherit;
      padding: 0;
      margin: 0;
      width: 100%;
      height: 100%;
      outline: none;

      &:focus {
        box-shadow: none;
      }
    }

    ${inputHeight};

    ${props => props.error && errorStyles};
    ${props => props.disabled && disabledStyles};
    ${props => props.readOnly && readOnlyStyles};
  }
`;
