// @flow
import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import { lighten } from 'polished';
import { appearanceToColor } from 'styles/constants';
type PieProps = {
  value: number,
  color: string,
};

type Props = {
  data: Array<PieProps>,
  size: number,
  total?: number,
  label?: string,
};

const base = 0.06;

export default function Halo(props: Props) {
  const { data, size, total, label } = props;
  const radius = size / 2;

  let pieData = data;

  if (total) {
    let count = 0;
    pieData = data
      .map(d => {
        count += d.value;
        return d;
      })
      .concat({ value: total - count, color: 'transparent' });
  }

  return (
    <PieChart width={size} height={size}>
      <Pie
        dataKey="value"
        data={[{ value: 1 }]}
        cx="50%"
        cy="50%"
        innerRadius={radius - size * base}
        outerRadius={radius - size * (base * 0.75)}
        isAnimationActive={false}
        blendStroke
        fill={lighten(0.075, appearanceToColor('disabled'))}
      />
      <Pie
        dataKey="value"
        data={pieData}
        cx="50%"
        cy="50%"
        cornerRadius="50%"
        paddingAngle={size * base}
        innerRadius={radius - size * (base * 2)}
        outerRadius={radius}
      >
        {pieData.map((entry, idx) => (
          <Cell key={`cell-${idx}`} fill={appearanceToColor(entry.color)} />
        ))}
      </Pie>
      {label && (
        <text
          x={radius}
          y={radius}
          fontSize={14}
          fontWeight="bold"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {label}
        </text>
      )}
    </PieChart>
  );
}

Halo.defaultProps = {
  size: 50,
};
