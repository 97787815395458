// @flow
import React from 'react';
import {
  UsersContext,
  type UsersProvider as Props,
  type UserFilters as Filters,
} from 'providers/UsersProvider';

export type UsersProvider = Props;
export type UserFilters = Filters;

export function useUsers() {
  return React.useContext(UsersContext);
}
