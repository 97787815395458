// @flow
import React from 'react';
import type { Comment, ProjectReport } from 'store/project/types';
import { formatDate, parseCommentMarkup } from 'helpers/formatters';
import Menu from 'components/Menu';
import Text from 'components/Text';
import Avatar from 'components/Avatar';
import { Flex } from '/components/Containers';
import { color } from 'styles/constants';
import CommentDialog from 'dialogs/ProjectDialogs/CommentDialog';
import { success, error } from 'utils/alerts';
import { useAlert } from 'hooks/useAlert';
import { useUsers, type UsersProvider } from 'hooks/useUsers';

type Props = {
  comment: Comment,
  currentUser: User,
  deleteCommentItem: (item: Comment, report: ProjectReport) => void,
  selectedReport: ProjectReport,
  editable: boolean,
  idx: number,
};

export default function ProjectComment(props: Props) {
  const {
    comment,
    currentUser,
    deleteCommentItem,
    editable,
    selectedReport,
    idx,
  } = props;

  const { createAlert } = useAlert();

  const { users: usersState }: UsersProvider = useUsers();
  const { map: users } = usersState.data;

  const user = users[comment.userId];
  const userCanEdit = editable && currentUser.id === comment.userId;
  const name = user ? `${user.firstName} ${user.lastName}` : '——';
  const thumbnail = user ? user.thumbnail : undefined;

  const [showEditCommentDialog, setShowEditCommentDialog] = React.useState(
    false
  );

  async function removeComment() {
    try {
      await deleteCommentItem(comment, selectedReport);
      createAlert({
        message: success('comment').delete,
        type: 'success',
      });
    } catch {
      createAlert({
        message: error('comment').delete,
        type: 'error',
      });
    }
  }

  const editComment = () => setShowEditCommentDialog(true);

  const menuItems = [
    { name: 'Edit', color: 'primary', action: editComment },
    { name: 'Delete', color: 'error', action: removeComment },
  ];

  return (
    <>
      <Flex align="center" gutter={0}>
        <Flex item xs={8} sm={8} padding="0 5 0 0">
          {parseCommentMarkup(comment.summary)}
        </Flex>
        <Flex item sm={2}>
          <Text>{formatDate(comment.updatedAt)}</Text>
        </Flex>
        <Flex item sm={1}>
          <Avatar color={color.LIGHT_GRAY} name={name} src={thumbnail} />
        </Flex>
        <Flex item sm={1} style={{ textAlign: 'center' }}>
          {userCanEdit && (
            <Menu iconButtonProps={{ alt: 'menu' }} items={menuItems} />
          )}
        </Flex>
      </Flex>

      <CommentDialog
        method="edit"
        open={showEditCommentDialog}
        idx={idx}
        comment={comment}
        onConfirm={() => setShowEditCommentDialog(false)}
        onClose={() => setShowEditCommentDialog(false)}
      />
    </>
  );
}
