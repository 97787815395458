// @flow
import React from 'react';
import { Link } from 'react-router-dom';

import { Block, Flex } from 'components/Containers';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { color } from 'styles/constants';

type Props = {
  icon: string,
  title: string,
  to: string,
  selected: boolean,
};

export default function DrawerItem({ icon, title, to, selected }: Props) {
  const itemColor = selected ? color.ENGAGE_BLUE : color.WHITE;
  return (
    <Flex align="center" gutter={1} height="20px">
      <Block flex="none">
        <Icon name={icon} color={itemColor} size={15} viewBox="0 0 15 15" />
      </Block>
      <Block flex="none">
        <Link to={to}>
          <Text size="normal" weight={600} color={itemColor}>
            {title}
          </Text>
        </Link>
      </Block>
    </Flex>
  );
}
