import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getAction, getCurrentUser, getProjectReport } from 'store/selectors';

import { addUpdateItem } from 'store/project/actions';

import { UpdateAddDialog } from './presenter';

function mapStateToProps(state) {
  return {
    action: getAction(state),
    currentUser: getCurrentUser(state),
    selectedReport: getProjectReport(state),
  };
}

function mapActionsToProps(dispatch) {
  return bindActionCreators(
    {
      addUpdateItem,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapActionsToProps)(UpdateAddDialog);
