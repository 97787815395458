import styled from 'styled-components';
import { Flex } from 'components/Containers';

export const ShadeFlex = styled(Flex)`
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    pointer-events: none;
    bottom: 0;
    left: 0;
    right: 0;
    height: 75px;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
`;
