// @flow
import headers from './headers';
import { checkStatus, getData, getOne } from './normalizer';
import { URL } from './base';
import type { Milestone, MilestoneUpdatePayload } from 'store/project/types';

// Serializing Functions
const getNonEmptyMilestones = (milestones: Array<Milestone>) => {
  if (!milestones) return [];
  return milestones.filter(m => m.description !== '' && m.notes !== '');
};

const serializeMilestones = (milestones: Array<Milestone>) => {
  if (!milestones) return [];
  const payload = getNonEmptyMilestones(milestones).map(milestone => {
    delete milestone.createdAt;
    delete milestone.updatedAt;
    return milestone;
  });

  return payload;
};

const serializeMilestone = (milestone: Milestone | null) => {
  if (!milestone) return undefined;

  const data = { ...milestone };
  delete data.createdAt;
  delete data.updatedAt;
  delete data.weekNo;
  return data;
};

// API Functions
export function getAllMilestonesForProject(id: string) {
  return fetch(`${URL}/projects/${id}/milestones`, {
    method: 'GET',
    headers: headers(),
  })
    .then(checkStatus)
    .then(getData);
}

export function updateMilestones(
  projectId: string,
  milestones: Array<Milestone>
) {
  const payload = serializeMilestones(milestones);
  return fetch(`${URL}/projects/${projectId}/milestones`, {
    method: 'PUT',
    headers: headers(),
    body: JSON.stringify(payload),
  })
    .then(checkStatus)
    .then(getData);
}

export function addProjectMilestone(milestone: Milestone, userId: string) {
  const payload = {
    userId,
    milestone,
  };
  return fetch(`${URL}/projects/${milestone.projectId}/milestones`, {
    method: `POST`,
    headers: headers(),
    body: JSON.stringify(payload),
  })
    .then(checkStatus)
    .then(getOne);
}

export function getMilestoneHistoryById(
  projectId: string,
  milestoneId: string
) {
  return fetch(
    `${URL}/projects/${projectId}/milestones-history/${milestoneId}`,
    {
      method: 'GET',
      headers: headers(),
    }
  )
    .then(checkStatus)
    .then(getData);
}

export function getMilestoneById(projectId: string, milestoneId: string) {
  return fetch(`${URL}/projects/${projectId}/milestones/${milestoneId}`, {
    method: 'GET',
    headers: headers(),
  })
    .then(checkStatus)
    .then(getOne);
}

export function updateMilestoneById(updates: MilestoneUpdatePayload) {
  const {
    type,
    userId,
    projectId,
    milestoneId,
    why = null,
    milestone = null,
  } = updates;
  const payload = {
    milestone: serializeMilestone(milestone),
    type,
    userId,
    why,
  };
  return fetch(`${URL}/projects/${projectId}/milestones/${milestoneId}`, {
    method: 'PUT',
    headers: headers(),
    body: JSON.stringify(payload),
  })
    .then(checkStatus)
    .then(getOne);
}
