// @flow
import React, { useState } from 'react';

import { Screen, Block, Flex } from 'components/Containers';
import { IconButton } from 'components/Icon';
import Text from 'components/Text';
import { color } from 'styles/constants';

import Drawer from './Drawer';

type Props = {
  children: React$Node,
  admin?: boolean,
  raised: boolean,
};

export default function Header(props: Props) {
  const { children, admin, raised } = props;
  const [showDrawer, setShowDrawer] = useState(false);

  const borderStyles = raised
    ? {
        raised: true,
      }
    : {
        border: { bottom: `1px solid ${color.BORDER_COLOR}` },
      };

  return (
    <>
      <Block height="70px" style={{ zIndex: -1 }} />
      <Block
        bg="white"
        noFlex
        fullWidth
        style={{ position: 'fixed', zIndex: 500, left: 0, top: 0 }}
        {...borderStyles}
      >
        <Screen>
          <Flex height="70px" align="center">
            <Flex
              width="70px"
              fullHeight
              align="center"
              justify="center"
              border={{ right: `1px solid ${color.BORDER_COLOR}` }}
            >
              <IconButton
                alt="main menu"
                color={color.MEDIUM_GRAY}
                name="hamburger"
                size={35}
                onClick={() => setShowDrawer(true)}
              />
            </Flex>
            <Flex
              padding="0 3"
              fullHeight
              justify="space-between"
              align="center"
            >
              {children}
            </Flex>
          </Flex>
        </Screen>
      </Block>

      <Drawer
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        admin={admin}
      />
    </>
  );
}

Header.Title = ({ children }: React$Node | string) => (
  <Text tag="h2" overflow="ellipsis" weight={500}>
    {children}
  </Text>
);

Header.defaultProps = {
  raised: true,
};
