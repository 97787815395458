import React, { type Node } from 'react';
import StyledInlineListItem from '../styled/InlineListItem';

type Props = {
  clickable: boolean,
  className: string,
  children?: Node,
  selected: boolean,
  onClick: () => void,
};

function InlineListItem(props: Props) {
  const { className, clickable, children, selected, onClick } = props;
  return (
    <StyledInlineListItem
      className={className}
      clickable={clickable}
      selected={selected}
      onClick={onClick}
    >
      {children}
    </StyledInlineListItem>
  );
}

InlineListItem.defaultProps = {
  clickable: false,
  selected: false,
};

export default InlineListItem;
