import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getCurrentUser,
  projectIsLoading,
  getProjectData,
} from 'store/selectors';

import { fetchProject, projectReset } from 'store/project/actions';
import Project from './presenter';

function mapStateToProps(state) {
  return {
    currentUser: getCurrentUser(state),
    project: getProjectData(state),
    isLoading: projectIsLoading(state),
  };
}

function mapActionsToProps(dispatch) {
  return bindActionCreators(
    {
      fetchProject,
      projectReset,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapActionsToProps)(Project);
