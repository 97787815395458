import 'whatwg-fetch';
import { checkStatus, getOne } from './normalizer';
import { URL } from './base';
import headers from './headers';

export function getGlobalSettings() {
  return fetch(`${URL}/global_settings`, {
    method: 'GET',
    headers: headers(),
  })
    .then(checkStatus)
    .then(getOne);
}

export function updateGlobalSettings(payload) {
  return fetch(`${URL}/global_settings`, {
    method: 'PUT',
    headers: headers(),
    body: JSON.stringify(payload),
  })
    .then(checkStatus)
    .then(getOne);
}
