// @flow
export function getUpdateColor(description: string) {
  switch (description && description.toLowerCase()) {
    case 'accomplishments':
    case 'accomplishment':
      return 'success';
    case 'issues':
    case 'issue':
      return 'error';
    case 'risks':
    case 'risk':
      return 'warning';
    default:
      return 'information';
  }
}

export function getUpdateType(type: string) {
  switch (type && type.toLowerCase()) {
    case 'accomplishments':
    case 'accomplishment':
      return 'Accomplishment';
    case 'issues':
    case 'issue':
      return 'Issue';
    case 'risks':
    case 'risk':
      return 'Risk';
    default:
      return 'Update';
  }
}
