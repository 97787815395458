// @flow
import React from 'react';
import Status from 'components/Status';
import { Block, Flex } from 'components/Containers';
import Card from 'components/Card';
import Chip from 'components/Chip';
import Text from 'components/Text';
import { isEowInPast, isPastDeadline } from 'helpers/formatters';
import { truncate } from 'utils/format';
import type { Project } from 'store/project/types';
import get from 'lodash/get';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';

import { color } from 'styles/constants';
import {
  defaultProjectStatus,
  latestValidReportDate,
} from 'views/ProjectsView';

type Props = {
  projects: Array<Project>,
  onProjectClick: (projectId: string) => void,
};

export default function ProjectCards(props: Props) {
  const { projects, onProjectClick } = props;

  return (
    <Flex wrap="wrap" spacing={16}>
      {projects.map((project, idx) => (
        <Flex
          key={idx}
          item
          xs={4}
          style={{ cursor: 'pointer', minHeight: 190, minWidth: 385 }}
          onClick={() => onProjectClick(project.id)}
        >
          <Card>
            <Card.Content>
              <Flex
                direction="column"
                justify="center"
                align="center"
                height="113px"
              >
                <Block flex="none" margin="0 0 3">
                  <Text weight={600} size="medium">
                    {truncate(`${project.clientName} - ${project.name}`)}
                  </Text>
                </Block>
                <Block flex="none" margin="0 0 1">
                  <Text
                    weight={300}
                    size="small"
                    color="typography-medium-gray"
                  >
                    STATUS
                  </Text>
                </Block>
                <Block flex="none">
                  {isEowInPast(project.end) || project.archive ? (
                    <Chip label="ENDED" />
                  ) : (
                    <Status
                      status={
                        !isEmpty(project.statuses)
                          ? project.statuses
                          : get(
                              project,
                              'reports[0].content.statuses',
                              defaultProjectStatus
                            )
                      }
                      size={26}
                      showLetter
                    />
                  )}
                </Block>
              </Flex>
            </Card.Content>
            <Card.Content bg={isPastDeadline(project) ? 'error' : 'footer'}>
              <Flex justify="space-between" align="center">
                <Flex align="center">
                  <Text
                    alt="alert"
                    size="xsmall"
                    weight={600}
                    color={isPastDeadline(project) ? color.WHITE : color.GRAY}
                  >
                    Report last updated at:
                  </Text>
                </Flex>
                <Block width="100">
                  {project.reports.length === 1 ? (
                    <Text
                      size="xsmall"
                      color={isPastDeadline(project) ? color.WHITE : color.GRAY}
                    >
                      {latestValidReportDate(head(project.reports))}
                    </Text>
                  ) : (
                    ''
                  )}
                </Block>
              </Flex>
            </Card.Content>
          </Card>
        </Flex>
      ))}
    </Flex>
  );
}
