import styled, { css } from 'styled-components';
import Select from '@material-ui/core/Select';

const disabled = props => {
  if (props.disabled) {
    return css`
      cursor: not-allowed;

      & div,
      & input {
        cursor: not-allowed;
      }
    `;
  }
  return css`
    cursor: pointer;

    & div,
    & input {
      cursor: pointer;
    }
  `;
};

export default styled(Select)`
  && {
    line-height: 2;
    ${disabled};
  }
`;
