// @flow
import React from 'react';
import {
  ProjectsContext,
  type ProjectsProvider as Props,
} from 'providers/ProjectsProvider';

export type ProjectsProvider = Props;
export type ProjectFilters = 'all' | 'active' | 'archived';

export function useProjects() {
  return React.useContext(ProjectsContext);
}
