import headers from './headers';
import { getData, checkStatus } from './normalizer';
import { URL } from './base';

export function getReportByProjectId(tenKId: string) {
  return fetch(`${URL}/projects/${tenKId}/10kReports`, {
    method: 'GET',
    headers: headers(),
  })
    .then(checkStatus)
    .then(getData);
}
