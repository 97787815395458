import React from 'react';
import PropTypes from 'prop-types';
import Progress from 'components/Progress';
import { percentage } from 'helpers/formatters/';
import './style.scss';

const Bugs = ({ completed, total }) => (
  <div className="Bugs">
    <div className="Bugs-title">Bugs</div>
    <hr />
    <div className="Bugs-details">
      {completed}
      <span> Of </span>
      {total}
      <span> Closed </span>
    </div>
    <Progress percentage={percentage(total, completed)} />
  </div>
);

Bugs.propTypes = {
  completed: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export default Bugs;
