// @flow
import React from 'react';
import ButtonGroup, { ButtonGroupItem } from '../styled/ButtonGroup';

type Props = {
  children: React$ChildrenArray<React$Element<any> | null | void>,
  className: string,
};

export default (props: Props) => {
  const { className, children } = props;

  return (
    <ButtonGroup className={className}>
      {React.Children.map(children, (child, idx) => {
        if (!child) return null;
        return <ButtonGroupItem key={idx}>{child}</ButtonGroupItem>;
      })}
    </ButtonGroup>
  );
};
