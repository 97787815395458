// @flow
import React from 'react';

import reducer, { initialState } from './reducer';
import { fetchAssigned, fetchUnassigned, resetProjectTeam } from './actions';
import type { ProjectTeamState, ProjectTeamAction } from '../types';

type Props = {
  children?: React$Node,
};

export type ContextProps = {
  ...ProjectTeamState,
  ...ProjectTeamAction,
};

export const ProjectTeamContext = React.createContext<ContextProps>({
  initialState,
  fetchAssigned: () => {},
  fetchUnassigned: () => {},
  resetProjectTeam: () => {},
});

export default function ProjectTeamProvider(props: Props) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const actions = {
    fetchAssigned: fetchAssigned(dispatch, state),
    fetchUnassigned: fetchUnassigned(dispatch, state),
    resetProjectTeam: resetProjectTeam(dispatch, state),
  };

  return (
    <ProjectTeamContext.Provider value={{ ...state, ...actions }}>
      {props.children}
    </ProjectTeamContext.Provider>
  );
}

export const ProjectTeamConsumer = ProjectTeamContext.Consumer;
