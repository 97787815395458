export const titleCase = str => {
  return str
    .split(' ')
    .map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};

export const computeInitials = fullname => {
  const sections = fullname.split(' ');
  let initials = sections[0].charAt(0).toUpperCase();

  if (sections.length > 1) {
    initials += sections[1].charAt(0).toUpperCase();
  } else {
    initials += sections[0].charAt(1).toUpperCase();
  }

  return initials;
};

export const pluralize = (total, text) =>
  total === 1 ? text.slice(0, -1) : text;

export const orDash = (value, dashes = 2) => {
  if (!!value) return value;
  let i = 0;
  let str = '';
  while (i < dashes) {
    i++;
    str = `${str}-`;
  }
  return str;
};

export const parseCommentMarkup = (commentMarkup: string = '') => {
  const mentions = [...commentMarkup.matchAll(/@\[([\w\s]+)\]\([\S]+\)/g)];
  return mentions.reduce((comment, mention) => {
    const [mentionMarkup, mentionDisplay] = mention;
    return comment.replace(mentionMarkup, mentionDisplay);
  }, commentMarkup);
};
