// @flow
import React from 'react';
import DocumentTitle from 'react-document-title';
import { Screen, Block, Flex } from 'components/Containers';
import { IconButton } from 'components/Icon';
import { ButtonGroup } from 'components/Button';
import Card from 'components/Card';
import { color } from 'styles/constants';
import { URL } from 'services/base';
import { TOKEN_KEY } from 'services/keys';
import Header from 'components/Header';
import type { User } from 'store/User/types';
import { isAdmin } from 'utils/auth';
import { success, error } from 'utils/alerts';
import { useAlert } from 'hooks/useAlert';
import { JiraUnlinkDialog } from 'dialogs/JiraUnlinkDialog';
type Props = {
  currentUser: User,
  removeUserAuthentication: () => void,
};

export default function LinkView(props: Props) {
  const { currentUser, removeUserAuthentication } = props;
  const { createAlert } = useAlert();
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const handleClick = () => {
    window.location.assign(
      `${URL}/auth3p/jira/authenticate?token=${localStorage.getItem(TOKEN_KEY)}`
    );
  };

  async function unlinkJira() {
    try {
      await removeUserAuthentication();
      createAlert({
        message: success('jira authentication').remove,
        type: 'success',
      });
    } catch (err) {
      createAlert({
        message: error('jira authentication').remove,
        type: 'error',
      });
    }
  }
  return (
    <DocumentTitle title="Jira Authentication">
      <Block>
        <Header admin={isAdmin(currentUser)}>
          <Header.Title>Links</Header.Title>
        </Header>

        <Screen>
          <Flex margin="3 0" direction="column" align="center" justify="center">
            <Block flex="none" width="500px">
              <Card raised>
                <Card.Header title="Click to Link Account" />
                <Card.Content>
                  <Flex justify="center" align="center">
                    <ButtonGroup className="Links-group">
                      <IconButton
                        alt="link jira"
                        name="jira"
                        size={70}
                        color={color.BLUE}
                        disabled={currentUser.authenticated}
                        onClick={handleClick}
                      />
                      {currentUser.authenticated && (
                        <IconButton
                          alt="unlink jira"
                          name="exit"
                          size={30}
                          color={color.RED}
                          onClick={() => setShowConfirmation(true)}
                        />
                      )}
                    </ButtonGroup>
                  </Flex>
                </Card.Content>
              </Card>
            </Block>
          </Flex>
        </Screen>
        <JiraUnlinkDialog
          open={showConfirmation}
          onConfirm={() => {
            unlinkJira();
            setShowConfirmation(false);
          }}
        />
      </Block>
    </DocumentTitle>
  );
}
