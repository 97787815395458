// @flow
import React from 'react';

import AlertProvider from './AlertProvider';
import DialogProvider from './DialogProvider';
import ProjectsProvider from './ProjectsProvider';
import ProjectTeamProvider from './ProjectTeamProvider';
import SlackProvider from './SlackProvider';
import UsersProvider from './UsersProvider';

type Props = {
  children?: React$Node,
};

export default function AppProvider({ children }: Props) {
  return (
    <AlertProvider>
      <UsersProvider>
        <SlackProvider>
          <ProjectsProvider>
            <ProjectTeamProvider>
              <DialogProvider>{children}</DialogProvider>
            </ProjectTeamProvider>
          </ProjectsProvider>
        </SlackProvider>
      </UsersProvider>
    </AlertProvider>
  );
}
