import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import placeholderAvatar from './placeholder-avatar.svg';
import './style.scss';

const getFirstName = name => {
  return name.split(' ')[0];
};

export const propTypes = {
  className: PropTypes.string,
  risk: PropTypes.shape({
    assignee: PropTypes.shape({
      avatarURL: PropTypes.string,
      name: PropTypes.string,
    }),
    reporter: PropTypes.shape({
      avatarURL: PropTypes.string,
      name: PropTypes.string,
    }),
    summary: PropTypes.string,
    status: PropTypes.oneOf(['high', 'medium', 'low']),
  }),
};

function Risk(props) {
  return (
    <div className={`Risk ${props.className}`}>
      <div
        className={classNames({
          'Risk-severity': true,
          [`Risk-severity--${props.risk.status || 'high'}`]: true,
        })}
      />
      <div className="Risk-avatar">
        <img
          src={props.risk.assignee.avatarURL || placeholderAvatar}
          alt="risk-assignee"
        />
      </div>
      <div className="Risk-info">
        <p className="Risk-authors">
          {`${getFirstName(props.risk.reporter.name)} to ${getFirstName(
            props.risk.assignee.name
          )}`}
        </p>
        <p className="Risk-summary">{props.risk.summary}</p>
      </div>
    </div>
  );
}

Risk.propTypes = propTypes;
export default Risk;
