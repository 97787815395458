// @flow
import type { Dispatch } from 'redux';
import { getMilestoneWeekNo } from 'helpers/formatters/';
import { addProjectMilestone, updateMilestoneById } from 'services/milestones';
import type {
  Milestone,
  MilestoneUpdatePayload,
  ProjectState,
} from 'store/project/types';
import {
  PROJECT_UPDATE_MILESTONE,
  PROJECT_ADD_MILESTONE,
  ACTION_START,
  ACTION_ERROR,
  ACTION_END,
} from '../constants';

export const updateMilestone = (payload: MilestoneUpdatePayload) => async (
  dispatch: Dispatch,
  getState: ProjectState
) => {
  dispatch({ type: ACTION_START });

  const { project } = getState().project;

  const data = payload;
  delete data.milestone.displayDate;

  try {
    const updatedMilestone = await updateMilestoneById(data);

    const milestone = updatedMilestone;
    const milestoneIdx = project.data.milestones.findIndex(
      m => m.id === milestone.id
    );
    const { totalWeeks } = project.data;
    const weekNo = getMilestoneWeekNo(
      milestone,
      project.data.reports,
      totalWeeks
    );
    const displayDate = milestone.actual || milestone.forecast;
    const milestones = [
      ...project.data.milestones.slice(0, milestoneIdx),
      {
        ...milestone,
        displayDate,
        weekNo,
      },
      ...project.data.milestones.slice(milestoneIdx + 1),
    ];

    dispatch({
      type: PROJECT_UPDATE_MILESTONE,
      payload: { ...project.data, milestones },
    });
    dispatch({ type: ACTION_END });
  } catch (err) {
    dispatch({ type: ACTION_ERROR, payload: err });
    throw new Error(`Error updating project milestone - ${err.message}`);
  }
};

export const addMilestone = (milestone: Milestone, userId: string) => async (
  dispatch: Dispatch,
  getState: ProjectState
) => {
  dispatch({ type: ACTION_START });

  const { project } = getState().project;
  try {
    const addedMilestone = await addProjectMilestone(milestone, userId);

    const { totalWeeks } = project.data;
    const weekNo = getMilestoneWeekNo(
      addedMilestone,
      project.data.reports,
      totalWeeks
    );
    const displayDate = addedMilestone.actual || addedMilestone.forecast;
    const milestones = project.data.milestones.concat({
      ...addedMilestone,
      displayDate,
      weekNo,
    });

    dispatch({
      type: PROJECT_ADD_MILESTONE,
      payload: { ...project.data, milestones },
    });
    dispatch({ type: ACTION_END });
  } catch (err) {
    dispatch({ type: ACTION_ERROR, payload: err });
    throw err;
  }
};
