// @flow
import React from 'react';
import { StyledChip } from '../styled/StyledChip';

type Props = {
  label: Node | string,
  appearance: 'primary' | 'information' | 'success' | 'warning' | 'error',
};

function Chip(props: Props) {
  const { appearance, label } = props;
  return <StyledChip appearance={appearance} label={label} />;
}

Chip.defaultProps = {
  appearance: 'information',
  label: '',
};

export default Chip;
