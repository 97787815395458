export const success = (title: string) => ({
  edit: `Successfully edited ${title}`,
  add: `Successfully added ${title}`,
  delete: `Successfully deleted ${title}`,
  archive: `Successfully archived ${title}`,
  unArchive: `Successfully unarchived ${title}`,
  create: `Successfully created ${title}`,
  update: `Successfully updated ${title}`,
  complete: `Successfully completed ${title}`,
  remove: `Successfully removed ${title}`,
});

export const error = (title: string) => ({
  edit: `Error editing ${title}`,
  add: `Error adding ${title}`,
  delete: `Error deleting ${title}`,
  archive: `Error archiving ${title}`,
  unArchive: `Error unarchiving ${title}`,
  create: `Error creating ${title}`,
  update: `Error updating ${title}`,
  complete: `Error completing ${title}`,
  remove: `Error removing ${title}`,
});
