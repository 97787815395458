import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateMilestone } from 'store/project/actions';
import { getCurrentUser, getProjectData } from 'store/selectors';
import MilestoneCompleteDialog from './presenter';

function mapStateToProps(state) {
  return {
    currentUser: getCurrentUser(state),
    project: getProjectData(state),
  };
}

function mapActionsToProps(dispatch) {
  return bindActionCreators(
    {
      updateMilestone,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapActionsToProps
)(MilestoneCompleteDialog);
