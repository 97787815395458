// @flow
import React from 'react';
import { isBefore, differenceInDays, isEqual } from 'date-fns';

import Avatar from 'components/Avatar';
import { Block, Flex } from 'components/Containers';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { formatDate } from 'helpers/formatters';
import { color } from 'styles/constants';
import type {
  MilestoneHistoryEntry,
  MilestoneEdits,
  Milestone,
} from 'store/project/types';
import { EDITED, ARCHIVED, MARKED_COMPLETE } from '../../../constants';
import { useUsers } from 'hooks/useUsers';

type Props = {
  entry: MilestoneHistoryEntry,
  milestone: Milestone,
  showDivider?: boolean,
};

function renderChange({ margin, weight, beforeDate, afterDate }: Object) {
  return (
    <>
      <Block flex="none" margin={margin}>
        <Text weight={weight}>{beforeDate || 'None'}</Text>
      </Block>
      <Block flex="none" margin={margin}>
        <Icon name="arrow" viewBox="0 0 16 16" size={16} />
      </Block>
      <Block flex="none" margin={margin}>
        <Text weight={weight}>{afterDate}</Text>
      </Block>
    </>
  );
}

function renderEdit(edit: MilestoneEdits) {
  const { key, before, after } = edit;
  const weight = key === 'notes' ? '400' : '500';
  const margin = key === 'notes' ? '1 0 0 0' : '0 1 0 0';
  const beforeDate = key === 'forecast' ? formatDate(before) : before;
  const afterDate = key === 'forecast' ? formatDate(after) : after;

  return key === 'notes' ? (
    <Flex direction="column" justify="center">
      {renderChange({ weight, margin, beforeDate, afterDate })}
    </Flex>
  ) : (
    <Flex align="center">
      {renderChange({ weight, margin, beforeDate, afterDate })}
    </Flex>
  );
}

function renderWhy(why: string) {
  return (
    <Flex margin="1 0 0 0">
      <Text weight={400} color={color.LIGHT_LIGHT_GRAY}>
        {why}
      </Text>
    </Flex>
  );
}

export default function MilestoneHistoryRow(props: Props) {
  const { entry, showDivider, milestone } = props;

  const { users: usersState } = useUsers();
  const { map: users } = usersState.data;
  const user = users[entry.userId];
  const actual = milestone.actual && formatDate(milestone.actual);
  const forecast = formatDate(milestone.forecast);

  const border = showDivider
    ? { bottom: `1px solid ${color.BORDER_COLOR}` }
    : {};
  const thumbnail = user ? user.thumbnail : undefined;
  const userName = user ? `${user.firstName} ${user.lastName}` : '';
  const entryType = user
    ? entry.type
    : entry.type.charAt(0).toUpperCase() + entry.type.slice(1);
  const date = formatDate(entry.createdAt);
  const edits = entry.edits
    ? Object.keys(entry.edits).map(e => ({ ...entry.edits[e], key: e }))
    : [];

  function editedDescription() {
    return (
      <>
        {entry.why && renderWhy(entry.why)}
        {edits.map((edit, idx) => (
          <Flex key={idx} margin="3 0 0 0">
            {renderEdit(edit)}
          </Flex>
        ))}
      </>
    );
  }

  function archivedDescription() {
    return entry.why && renderWhy(entry.why);
  }

  function markedCompleteDescription() {
    const numberOfDays = Math.abs(differenceInDays(actual, forecast));
    const completedText = isBefore(actual, forecast) ? 'early' : 'late';

    return (
      !isEqual(forecast, actual) && (
        <>
          {entry.why && renderWhy(entry.why)}
          <Flex align="center" margin="3 0 0 0">
            {renderChange({
              weight: 500,
              margin: '0 1 0 0',
              beforeDate: forecast,
              afterDate: (
                <>
                  {actual}{' '}
                  <Text tag="span" weight={400} color={color.LIGHT_LIGHT_GRAY}>
                    (completed {numberOfDays} days {completedText})
                  </Text>
                </>
              ),
            })}
          </Flex>
        </>
      )
    );
  }

  function getDescription() {
    switch (entryType) {
      case EDITED:
        return editedDescription();
      case ARCHIVED:
        return archivedDescription();
      case MARKED_COMPLETE:
        return markedCompleteDescription();
      default:
        return null;
    }
  }

  return (
    <Flex margin="0 0 3 0" padding="0 0 3 0" border={border}>
      <Flex direction="column" item sm={1}>
        <Avatar color={color.LIGHT_GRAY} name={userName} src={thumbnail} />
      </Flex>
      <Flex direction="column" item xs={10}>
        <Flex margin="1 0 0 0">
          <Text>
            <b>{userName}</b> {entryType} on {date}
          </Text>
        </Flex>
        {getDescription()}
      </Flex>
    </Flex>
  );
}
