import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateMilestone } from 'store/project/actions';
import MilestoneViewDialog from './presenter';

function mapActionsToProps(dispatch) {
  return bindActionCreators(
    {
      updateMilestone,
    },
    dispatch
  );
}

export default connect(null, mapActionsToProps)(MilestoneViewDialog);
