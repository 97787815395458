import styled from 'styled-components';

import { color, spacing } from 'styles/constants';

// padding-top here is a hack for absolutely positioned .Title element
const Card = styled.section`
  align-self: stretch;
  background-color: ${color.DARK_GRAY};
  border-radius: 5px;
  color: ${color.WHITE};
  height: auto;
  margin: ${spacing('xlarge')};
  overflow: hidden;
  padding-top: 122px;
  position: relative;
  width: 400px;
`;

export default Card;
