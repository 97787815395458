// @flow
import React from 'react';
import ToggleWrapper from '../styled/ToggleWrapper';
import ToggleSwitch from '../styled/ToggleSwitch';

type Props = {
  value?: string,
  on?: string,
  off?: string,
  checked?: boolean,
  defaultChecked?: boolean,
  disabled?: boolean,
  onChange?: (checked: any) => void,
};

class Toggle extends React.Component<Props> {
  static defaultProps = {
    on: 'ON',
    off: 'OFF',
  };
  constructor(props: Props) {
    super();
    this.state = {};
    // eslint-disable-next-line eqeqeq
    this.isControlled = props.checked != null;

    if (!this.isControlled) {
      this.state.checked =
        props.defaultChecked !== undefined ? props.defaultChecked : false;
    }
  }

  handleChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const { onChange, disabled } = this.props;
    const checked = event.currentTarget.checked;

    if (disabled) return;
    if (!this.isControlled) this.setState({ checked });
    if (onChange) onChange(checked);
  };

  render() {
    const { on, off, value, disabled, checked: checkedProp } = this.props;
    const checked = this.isControlled ? checkedProp : this.state.checked;
    const displaySwitchTexts = !!on || !!off;

    return (
      <>
        <ToggleSwitch
          id="toggle-switch"
          value={value}
          checked={checked}
          type="checkbox"
          onChange={this.handleChange}
          disabled={disabled}
        />
        <ToggleWrapper
          htmlFor="toggle-switch"
          displaySwitchTexts={displaySwitchTexts}
        >
          <span>{off}</span>
          <span>{on}</span>
        </ToggleWrapper>
      </>
    );
  }
}

export default Toggle;
