// @flow
import React from 'react';
import { default as MaterialCard } from '@material-ui/core/Card';
import StyledCard from '../styled/StyledCard';
import CardHeader from './CardHeader';
import CardContent from './CardContent';
type Props = {
  raised?: boolean,
  children: React$Node,
};

function Card(props: Props) {
  const { raised = true, children } = props;

  function renderCard(cardProps) {
    return <StyledCard raised={raised} {...cardProps} />;
  }
  return (
    <MaterialCard component={renderCard} style={{ overflow: 'visible' }}>
      {children}
    </MaterialCard>
  );
}

Card.Header = CardHeader;
Card.Content = CardContent;

export default Card;
