import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import './style.scss';

const Counter = ({ type, count, desc = '', index, className }) => (
  <div
    className={classNames({
      Counter: true,
      [`Counter--${type}`]: true,
      [`Counter--${type}-index${index}`]: !!index,
      [className]: !!className,
    })}
  >
    <div className="Counter-count">{count}</div>
    <div className="Counter-desc">{desc}</div>
  </div>
);

Counter.propTypes = {
  type: PropTypes.oneOf(['default', 'noData', 'success', 'error']).isRequired,
  count: PropTypes.number,
  desc: PropTypes.string,
  index: PropTypes.number,
  className: PropTypes.string,
};

export default Counter;
