import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getAction, getCurrentUser, getProjectData } from 'store/selectors';

import { addMilestone } from 'store/project/actions';

import { MilestoneAddDialog } from './presenter';

function mapStateToProps(state) {
  return {
    action: getAction(state),
    currentUser: getCurrentUser(state),
    project: getProjectData(state),
  };
}

function mapActionsToProps(dispatch) {
  return bindActionCreators(
    {
      addMilestone,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapActionsToProps)(MilestoneAddDialog);
