// @flow
import React, { useMemo, useEffect } from 'react';
import { type ContextRouter, withRouter } from 'react-router-dom';

import type { Project } from 'store/project/types';
import type { Projects } from 'providers/ProjectsProvider/types';
import { IconButton } from 'components/Icon';
import Button from 'components/Button';
import Header from 'components/Header';
import { Block, Flex } from 'components/Containers';
import { spacingDefaults } from 'styles/constants';
import { truncate } from 'utils/format';
import ProjectItems from './ProjectItems';
import ProjectItem from './ProjectItem';

type Props = {
  ...ContextRouter,
  admin: boolean,
  project: Project,
  projects: Projects,
};

const baseFlexProps = { flex: 'none', width: 'auto' };

const url = (id: string, location: ContextRouter) => {
  const index = 4;
  const routeArray = location.pathname.split('/');
  routeArray.splice(index, 1, id);

  return routeArray.join('/');
};

const getProjectsIds = (p: Projects) => p.map(project => project.id);

function ProjectHeader(props: Props) {
  const { history, location, match, admin, project, projects } = props;
  const filter = match.params.statusFilter;

  const ids = useMemo(() => getProjectsIds(projects.data), [projects.data]);

  function onPreviousProjectClick() {
    let index = ids.indexOf(project.id);
    if (index === -1) return;
    index = index === 0 ? ids.length - 1 : --index;

    history.push(url(ids[index], location));
  }

  function onNextProjectClick() {
    let index = ids.indexOf(project.id);
    if (index === -1) return;
    index = index === ids.length - 1 ? 0 : ++index;

    history.push(url(ids[index], location));
  }

  function carouselKeyHandler({ key, metaKey, ctrlKey }) {
    if ((ctrlKey || metaKey) && key === 'j') {
      onPreviousProjectClick();
    } else if ((ctrlKey || metaKey) && key === 'k') {
      onNextProjectClick();
    }
    return;
  }

  useEffect(() => {
    window.addEventListener('keydown', carouselKeyHandler);
    return () => {
      window.removeEventListener('keydown', carouselKeyHandler);
    };
  }, [ids]);

  return (
    <>
      <Header admin={admin} raised={false}>
        <Block
          position="absolute"
          style={{ zIndex: 2, left: spacingDefaults.normal }}
        >
          <Button
            appearance="text"
            onClick={() =>
              history.push({
                pathname: '/admin/projects',
                search: `?statusFilter=${filter}`,
              })
            }
          >
            <IconButton name="chevron_left" noPadding />
            Projects
          </Button>
        </Block>
        {project && (
          <Flex justify="center">
            <Flex align="center" {...baseFlexProps}>
              <Flex circle bg="faux-white" {...baseFlexProps}>
                <IconButton
                  noPadding
                  alt="previous project"
                  name="chevron_left"
                  onClick={onPreviousProjectClick}
                  disabled={projects.loading || projects.error}
                />
              </Flex>
              <Flex
                direction="column"
                align="center"
                width="450px"
                margin="0 2"
              >
                <Header.Title>
                  {truncate(`${project.clientName} - ${project.name}`)}
                </Header.Title>
              </Flex>
              <Flex circle bg="faux-white" {...baseFlexProps}>
                <IconButton
                  noPadding
                  alt="next project"
                  name="chevron_right"
                  onClick={onNextProjectClick}
                  disabled={projects.loading || projects.error}
                />
              </Flex>
            </Flex>
          </Flex>
        )}
      </Header>
      <Block height="60px" style={{ zIndex: -1 }} />
      <Flex
        bg="white"
        height="60px"
        raised
        align="center"
        justify="center"
        style={{ position: 'fixed', zIndex: 500, left: 0, top: 71 }}
      >
        <Block width="70px" />
        <ProjectItems>
          <ProjectItem
            title="TIMELINE"
            to={`/admin/projects/${filter}/${project.id}/timeline`}
          />
          <ProjectItem
            title="MILESTONE LIST"
            to={`/admin/projects/${filter}/${project.id}/milestones`}
          />
          <ProjectItem
            title="TEAM"
            to={`/admin/projects/${filter}/${project.id}/team`}
          />
          <ProjectItem
            title="PROJECT DETAILS"
            to={`/admin/projects/${filter}/${project.id}/details`}
          />
          <ProjectItem
            title="FINANCE"
            to={`/admin/projects/${filter}/${project.id}/finance`}
          />
        </ProjectItems>
      </Flex>
    </>
  );
}

export default withRouter(ProjectHeader);
