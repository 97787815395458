import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

const persistConfig = {
  key: 'root',
  storage,
};

export default function configureStore(reducers) {
  const persistedReducer = persistReducer(persistConfig, reducers);
  const composeEnhancers = composeWithDevTools({});
  const store = createStore(
    persistedReducer,
    undefined,
    composeEnhancers(applyMiddleware(thunk))
  );
  const persistor = persistStore(store);
  return { store, persistor };
}
