import styled, { css } from 'styled-components';
import { color, spacing, fontSize, spacingDefaults } from 'styles/constants';
import { hexToRgb } from 'styles/helpers';

export const Table = styled.table`
  table-layout: fixed;
  width: 100%;
  border-spacing: 0 ${spacingDefaults.normal};
`;

export const Theader = styled.th`
  text-transform: uppercase;
  font-weight: 600;
  font-size: ${fontSize.small};
  text-align: ${props => (props.center ? 'center' : 'left')};
  padding: ${spacing('small', 'normal')};
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `};
`;

export const Col = styled.td`
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  text-transform: ${props => (props.lowercase ? 'lowercase' : 'capitalize')};
  color: ${props =>
    props.date || props.issues ? `${color.MEDIUM_GRAY}` : 'inherit'};
  padding: ${spacing('large', 'normal')};
  overflow: hidden;
  background: ${color.WHITE};
  border-top: 1px solid ${color.DISABLED};
  border-bottom: 1px solid ${color.DISABLED};

  &:first-child {
    border-radius: 4px 0 0 4px;
    border-left: 1px solid ${color.DISABLED};
  }

  &:last-child {
    border-radius: 0 4px 4px 0;
    border-right: 1px solid ${color.DISABLED};
  }
`;

export const NoCapitalizeCol = styled(Col)`
  text-transform: none;
`;

export const Row = styled.tr`
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(${hexToRgb(color.BLACK)}, 0.1);
  ${props =>
    props.onClick &&
    css`
      &:hover {
        background-color: ${color.LIGHT_BLUE};
        cursor: pointer;
      }
    `};
`;

export const NoResults = styled.div`
  padding: ${spacing('large', 0, 'xxlarge', '0')};
  font-weight: 600;
  letter-spacing: 0.03rem;
  opacity: 0.5;
`;
