export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR';
export const FETCH_USERS_FULFILLED = 'FETCH_USERS_FULFILLED';

export const UPDATE_USERS_ERROR = 'UPDATE_USERS_ERROR';
export const UPDATE_USERS_FULFILLED = 'UPDATE_USERS_FULFILLED';

export const USERS = {
  data: {
    map: {},
    roles: [],
  },
  loading: false,
  error: null,
  resolved: false,
};
