import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import AppProvider from './providers';
import { AlertContainer } from './providers/AlertProvider';
import { DialogContainer } from './providers/DialogProvider';
import Routes from './routes';
import history from './history';
import { createRootReducer } from './store';
import configureStore from './store/configureStore';
import { install as installMaterialStyles } from '@material-ui/styles';
import { PersistGate } from 'redux-persist/integration/react';
import './fonts/fonts.scss';
import './index.scss';

installMaterialStyles();

const rootReducer = createRootReducer();
const { store, persistor } = configureStore(rootReducer);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AppProvider>
        <AlertContainer />
        <DialogContainer />
        <Router history={history}>
          <QueryParamProvider ReactRouterRoute={Route}>
            <Routes />
          </QueryParamProvider>
        </Router>
      </AppProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
