// @flow
import styled, { css } from 'styled-components';
import { appearanceToColor, fontFamily, fontSize } from 'styles/constants';
import React from 'react';

const el = React.createElement;

const color = props =>
  props.color &&
  `
  color: ${appearanceToColor(props.color)};
`;

const align = props =>
  props.align &&
  css`
    text-align: ${props.align};
  `;

const family = props =>
  props.family &&
  css`
    font-family: ${fontFamily[props.family]};
  `;

const weight = props =>
  props.weight &&
  css`
    font-weight: ${props.weight};
  `;

const size = props =>
  props.size &&
  css`
    font-size: ${fontSize[props.size]};
  `;

const italics = props =>
  props.italics &&
  css`
    font-style: italic;
  `;

const verticalSpacing = props =>
  !props.hasVerticalSpacing &&
  css`
    margin: 0;
  `;

const overflow = props =>
  props.overflow &&
  css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ${props.overflow};
  `;

const transform = props =>
  props.transform &&
  css`
    text-transform: ${props.transform};
  `;

const wordbreak = props =>
  props.wordbreak &&
  css`
    word-break: ${props.wordbreak};
  `;

export default styled(({ tag = 'p', children, ...props }) =>
  el(tag, props, children)
)`
  ${color};
  ${family};
  ${align};
  ${weight};
  ${size};
  ${italics};
  ${verticalSpacing};
  ${overflow};
  ${transform};
  ${wordbreak};
`;
