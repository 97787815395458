// @flow
import React from 'react';
import Card from 'components/Card';
import { Flex } from 'components/Containers';
import List from 'components/List';
import Text from 'components/Text';
import type {
  Project,
  Milestone,
  MilestonesFilters,
  Action,
  User,
  MilestoneUpdatePayload,
} from 'store/project/types';
import ProjectMilestone from './ProjectMilestone';

type Props = {
  project: Project,
  milestones: Array<Milestone>,
  milestonesFilter: MilestonesFilters,
  action: Action,
  user: User,
  updateMilestone: MilestoneUpdatePayload => void,
};

const formatEmptyList = (filter: milestonesFilters) => {
  return filter === 'all' ? '' : filter;
};

export default function ProjectMilestoneListContent(props: Props) {
  const { milestones, milestonesFilter, ...rest } = props;

  if (!milestones.length) {
    return (
      <Card.Content>
        <Flex fullHeight padding="5 0" align="center" justify="center">
          <Text align="center" color="typography-medium-gray">
            There are no {formatEmptyList(milestonesFilter)} milestones.
          </Text>
        </Flex>
      </Card.Content>
    );
  }

  return (
    <Card.Content>
      <List
        items={milestones.map(m => (
          <ProjectMilestone key={m.id} milestone={m} {...rest} />
        ))}
      />
    </Card.Content>
  );
}
