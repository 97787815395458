// @flow
import React from 'react';
import StyledCardContent from '../styled/StyledCardContent';

type Props = {
  hasBorder?: boolean,
  bg?: string,
  children: React$Node,
};

export default function CardContent(props: Props) {
  const { hasBorder, bg, children } = props;

  return (
    <StyledCardContent hasBorder={hasBorder} bg={bg}>
      {children}
    </StyledCardContent>
  );
}
