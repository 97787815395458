// @flow
import React from 'react';
import StyledButton from '../styled/StyledButton';
import Icon from '../../Icon';
import iconSize from '../helpers/iconSize';
import { default as MaterialButton } from '@material-ui/core/Button';
import { type AppearanceTypes } from 'styles/constants';

type Props = {
  icon?: string,
  appearance?: 'primary' | 'secondary' | 'text',
  size: 'small' | 'medium' | 'large',
  color?: AppearanceTypes,
  fullWidth?: boolean,
  disabled?: boolean,
  icon: string,
  onClick(e: SyntheticMouseEvent<HTMLElement>): void,
  children?: React$Node,
  className?: string,
};

function Button(props: Props) {
  const {
    size,
    fullWidth,
    color,
    disabled,
    icon,
    appearance,
    onClick,
    className,
    children,
    ...rest
  } = props;

  function handleClick(e: SyntheticMouseEvent<HTMLButtonElement>) {
    if (disabled) return;
    if (!onClick) return;
    onClick(e);
  }

  function renderButton(buttonProps) {
    const { children: buttonChildren, ...otherButtonProps } = buttonProps;
    return (
      <StyledButton
        {...rest}
        {...otherButtonProps}
        className={className}
        fullWidth={fullWidth}
        disabled={disabled}
        appearance={appearance}
        onClick={handleClick}
        size={size}
        color={color}
      >
        {!!icon && <Icon name={icon} size={iconSize(size)} />}
        {buttonChildren}
      </StyledButton>
    );
  }

  return <MaterialButton component={renderButton}>{children}</MaterialButton>;
}

Button.defaultProps = {
  size: 'medium',
  color: 'primary',
  appearance: 'primary',
};

export default Button;
