import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import './style.scss';

const Progress = ({ percentage, className }) => (
  <div
    className={classNames({
      Progress: true,
      'Progress-over': percentage > 100,
      [className]: !!className,
    })}
  >
    <progress value={percentage} max="100" />
  </div>
);

Progress.propTypes = {
  percentage: PropTypes.number.isRequired,
  className: PropTypes.string,
};

export default Progress;
