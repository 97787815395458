import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/Text';
import { IconButton } from 'components/Icon';
import { color } from 'styles/constants';
import { AlertContainer } from './styled';

class Alert extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      timeoutId: '',
    };
  }

  componentDidMount() {
    if (this.props.message) {
      this.initTimeout();
    }
  }

  componentDidUpdate(prevProps) {
    const { message } = this.props;
    if (!prevProps.message && message && message.length > 0) {
      this.initTimeout();
    } else if (
      prevProps.message &&
      prevProps.message.length > 0 &&
      message === ''
    ) {
      clearTimeout(this.state.timeoutId);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.state.timeoutId);
  }

  initTimeout() {
    this.setState({
      timeoutId: setTimeout(this.props.close, this.props.time || 5000),
    });
  }

  render() {
    return (
      <AlertContainer
        isSuccess={this.props.isSuccess}
        isError={this.props.isError}
        isWarning={this.props.isWarning}
      >
        <Text size="normal" color="black">
          {this.props.message}
        </Text>
        <IconButton
          name="exit"
          color={color.MEDIUM_GRAY}
          onClick={this.props.close}
        />
      </AlertContainer>
    );
  }
}

Alert.propTypes = {
  message: PropTypes.string.isRequired,
  isError: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isWarning: PropTypes.bool,
  close: PropTypes.func,
  time: PropTypes.number,
};

export default Alert;
