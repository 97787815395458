import { createSelector } from 'reselect';
import { getProjectData } from './project';
import type { State } from './reducer';
import type { Project, MilestoneProps } from './types';

const milestonesByProps = (project: Project, props: MilestoneProps) => {
  let filter;
  let sort;

  if (props) {
    filter = props.milestonesFilter;
    sort = props.milestonesDateSort;
  }

  if (project.milestones) {
    let milestones = project.milestones.sort((a, b) =>
      a.planned.localeCompare(b.planned)
    );

    // filter milestones by milestonesFilter props
    if (filter) {
      switch (filter) {
        case 'all':
          milestones = milestones;
          break;
        case 'active':
          milestones = milestones.filter(milestone => !milestone.archived);
          break;
        case 'archived':
          milestones = milestones.filter(milestone => milestone.archived);
          break;
        default:
          return null;
      }
    } else {
      milestones = milestones.filter(milestone => !milestone.archived);
    }

    // sort milestones by milestonesSort props
    if (sort === 'descending') {
      milestones = milestones.sort((a, b) =>
        b.displayDate.localeCompare(a.displayDate)
      );
    }
    if (sort === 'ascending') {
      milestones = milestones.sort((a, b) =>
        a.displayDate.localeCompare(b.displayDate)
      );
    }

    // return the resulting milestones
    return milestones;
  }

  // no milestones then return null
  return null;
};

export const getMilestones = createSelector(
  [getProjectData, (state: State, props: Object) => props],
  milestonesByProps
);
