// // @flow
import React, { useState, useEffect } from 'react';
import Halo from 'components/Halo';
import StyledTextField from '../styled/StyledTextField';
import InputWrapper from '../styled/InputWrapper';
import InputLabel from '../styled/InputLabel';
import HaloWrapper from '../styled/HaloWrapper';

export type Props = {
  id?: string,
  inputRef?: React$Node,
  label?: string,
  name: string,
  placeholder?: string,
  value?: string | number,
  defaultValue?: string | number,
  autoComplete?: boolean,
  autoFocus?: boolean,
  required?: boolean,
  multiline?: boolean,
  maxLength?: number,
  rows?: number | string,
  select?: boolean,
  readOnly?: boolean,
  disabled?: boolean,
  error?: boolean,
  fullWidth?: boolean,
  visuallyHidden?: boolean,
  onBlur?: (event: SyntheticFocusEvent<HTMLInputElement>) => void,
  onChange?: (event: SyntheticInputEvent<HTMLInputElement>) => void,
  onFocus?: (event: SyntheticFocusEvent<HTMLInputElement>) => void,
};

export default function Input(props: Props) {
  const {
    id,
    label,
    name,
    placeholder,
    value,
    defaultValue,
    autoComplete,
    autoFocus,
    required,
    multiline,
    maxLength,
    rows,
    select,
    readOnly,
    disabled,
    error,
    fullWidth,
    onChange,
    inputRef,
    visuallyHidden,
    ...rest
  } = props;

  const [charCount, setCharCount] = useState(0);
  const textFieldId = id ? `text-field-${id}` : `text-field-${label}`;

  const inTextField = () => {
    const textFieldEl = document.getElementById(textFieldId);
    if (textFieldEl) {
      setCharCount(textFieldEl.value.length);
    }
  };

  useEffect(() => {
    inTextField();
  }, []);

  const handleChange = (
    event: SyntheticInputEvent<HTMLInputElement>,
    child: React$Node
  ) => {
    if (maxLength && multiline) setCharCount(event.target.value.length);
    if (onChange) onChange(event, child);
  };
  return (
    <InputWrapper fullWidth={fullWidth}>
      <InputLabel htmlFor={textFieldId || name} visible={!visuallyHidden}>
        {label}
      </InputLabel>
      <StyledTextField
        inputRef={inputRef}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        defaultValue={defaultValue}
        disabled={disabled}
        error={error}
        fullWidth={fullWidth}
        value={value}
        name={name}
        multiline={multiline}
        rows={rows}
        placeholder={placeholder}
        required={required}
        select={select}
        disableUnderline
        readOnly={readOnly}
        maxLength={maxLength}
        onChange={handleChange}
        inputProps={{
          id: textFieldId,
          maxLength,
        }}
        {...rest}
      />
      {maxLength && multiline && (
        <HaloWrapper>
          <Halo
            size={25}
            data={[
              {
                value: charCount,
                color: charCount < maxLength ? 'blue' : 'red',
              },
            ]}
            total={maxLength}
          />
        </HaloWrapper>
      )}
    </InputWrapper>
  );
}
