// @flow
import React from 'react';
import { Link } from 'react-router-dom';

import Text from 'components/Text';
import { ProjectItemContainer } from '../styled';

type Props = {
  title: string,
  to: string,
  selected: boolean,
};

export default function ProjectItem({ title, to, selected }: Props) {
  return (
    <Link to={to}>
      <ProjectItemContainer selected={selected}>
        <Text size="xsmall" weight={600}>
          {title}
        </Text>
      </ProjectItemContainer>
    </Link>
  );
}
